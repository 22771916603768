import React, { useEffect } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import { getUserId } from '../../Config'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { OutputAreaByLatLongQuery as OaQueryResponseType } from '../../graphql/queries/__generated__/OutputAreaByLatLongQuery.graphql'
import { ProjectsByUserIdQuery } from '../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { OutputAreaByLatLong } from '../../graphql/queries/OutputAreaByLatLong'
import { ProjectsByUserId } from '../../graphql/queries/ProjectsByUserId'
import { Page } from '../../layout/page/Page'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { getStoredCa } from '../../utils/localStorage'
import { Tabbar } from '../lad/Tabbar'
import { CareHomes } from './tabs/careHomes/CareHomes'
import { Dementia } from './tabs/dementia/Dementia'
import { Economic } from './tabs/economic/Economic'
import { Elderly } from './tabs/elderly/Elderly'
import { ElderlyCareMarket } from './tabs/elderlyCareMarket/ElderlyCareMarket'
import { Explorer } from './tabs/explorer/Explorer'
import { Housing } from './tabs/housing/Housing'
import { Population } from './tabs/population/Population'

interface CaParams {
	latitude: number
	longitude: number
	radius: RadiusValues
	tab?: string
	category?: string
}

const Ca: React.FC<CaParams> = ({ latitude, longitude, radius, category = 'elderly-care', tab = 'explorer' }) => {
	const navigate = useNavigate()

	const { outputAreaByLatLong } = useLazyLoadQuery<OaQueryResponseType>(OutputAreaByLatLong, {
		latitude,
		longitude,
	})

	const { oaCode, ladCode } = outputAreaByLatLong

	const onTabChange = (key: string) => {
		navigate(`/ca/${latitude},${longitude}/${radius}/${category}/${key}`)
	}

	const onCategoryChange = (category: string) => {
		navigate(`/ca/${latitude},${longitude}/${radius}/${category}`)
	}

	const handleGenerateReport = () => {
		alert('Coming soon')
		// navigate(`/report/ca/${lat},${long}/${radius}`)
	}

	const handleMapSelectionChange = (lat: number, long: number, radius: RadiusValues) => {
		navigate(`/ca/${lat},${long}/${radius}/${category}/${tab}`)
	}

	return (
		<Page title={`Catchment Area - ${latitude},${longitude}`}>
			<Container size="fluid">
				<div style={{ height: 40 }} />
				<Tabbar
					category={category}
					tab={tab}
					onCategoryChange={onCategoryChange}
					onTabChange={onTabChange}
					onGenerateReport={handleGenerateReport}
				/>
				<VerticalSpace size="lg" />
				{tab === 'explorer' && (
					<Explorer
						oaCode={oaCode}
						radius={radius}
						ladCode={ladCode}
						lat={latitude}
						long={longitude}
						onChange={handleMapSelectionChange}
					/>
				)}
				{tab === 'economic' && <Economic oaCode={oaCode} radius={radius} />}
				{tab === 'population' && <Population oaCode={oaCode} radius={radius} />}
				{tab === 'housing' && <Housing oaCode={oaCode} radius={radius} />}
				{tab === 'elderly' && <Elderly oaCode={oaCode} radius={radius} />}
				{tab === 'dementia' && <Dementia oaCode={oaCode} radius={radius} />}
				{tab === 'care-homes' && <CareHomes oaCode={oaCode} radius={radius} lat={latitude} long={longitude} />}
				{tab === 'elderly-care-market' && <ElderlyCareMarket oaCode={oaCode} radius={radius} ladCode={ladCode} />}
			</Container>
		</Page>
	)
}

type RouteParams = {
	latLong?: string
	radius?: RadiusValues
	tab?: string
	category?: string
}

const CatchmentArea: React.FC = () => {
	const navigate = useNavigate()
	const routeParams = useParams<Record<string, string | undefined>>() // Get lad from the route

	const latLong = routeParams.latLong || ''
	const latLongArray = latLong.split(',')

	// Effect to handle navigation logic
	useEffect(() => {
		if (latLongArray.length !== 2 || !routeParams.radius) {
			const ca = getStoredCa()
			navigate(`/ca/${ca.lat},${ca.long}/${ca.radius}`)
		}
	}, [latLongArray, routeParams.radius, navigate])

	// Prevent rendering if we are about to navigate
	if (latLongArray.length !== 2 || !routeParams.radius) {
		return null
	}

	const params = {
		latitude: parseFloat(latLongArray[0]),
		longitude: parseFloat(latLongArray[1]),
		radius: routeParams.radius as RadiusValues,
		tab: routeParams.tab,
		category: routeParams.category,
	}

	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Ca {...params} />
		</React.Suspense>
	)
}

type ProjectRouteParams = RouteParams & {
	reference: string
}

const ProjectCatchmentArea: React.FC = () => {
	const params = useParams<ProjectRouteParams>() // Get lad from the route
	const navigate = useNavigate()

	const data = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { id: getUserId() })
	const graphQlProjects = data.projectsByUserId?.projects || []
	const graphQlProject = graphQlProjects.find((p) => p.reference === params.reference)

	// Handle redirection inside useEffect
	useEffect(() => {
		if (!graphQlProject && graphQlProjects.length > 0) {
			navigate(`/project/${graphQlProjects[0].reference}`)
		}
	}, [graphQlProject, graphQlProjects, navigate])

	// Avoid rendering if we are about to navigate
	if (!graphQlProject) {
		return <div>Project not found</div>
	}

	const updateParams = {
		...params,
		latitude: graphQlProject.latitude,
		longitude: graphQlProject.longitude,
		radius: 'five' as RadiusValues,
	}

	return <Ca {...updateParams} />
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<ProjectCatchmentArea />
		</React.Suspense>
	)
}

export { CatchmentArea, Suspended as ProjectCatchmentArea }
