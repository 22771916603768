export interface OperatorGrowthScores {
	recencyScore: number
	relativeScore: number
	absoluteScore: number
}

export interface OperatorGrowthInput {
	registeredCareHomesCount: number
	careHomesNew3Years: number
	careHomesNew5Years: number
	careHomesNew7Years: number
	bedsCAGR3Years: number
	bedsCAGR5Years: number
}

function calculateOperatorGrowthScoreDynamic(params: OperatorGrowthInput): number {
	const {
		registeredCareHomesCount,
		careHomesNew3Years,
		careHomesNew5Years,
		careHomesNew7Years,
		bedsCAGR3Years,
		bedsCAGR5Years,
		// bedsCAGR7Years, // if needed, but our relative function uses only 3 & 5
	} = params

	// Compute component scores using your existing functions:
	const recencyScore = calculateRecencyGrowthScore(careHomesNew3Years, careHomesNew5Years, careHomesNew7Years)
	const relativeScore = calculateRelativeCAGRScore(bedsCAGR3Years, bedsCAGR5Years)
	const absoluteScore = calculateAbsoluteGrowthScore(careHomesNew3Years, careHomesNew5Years)

	// Dynamic weighting based on operator size:
	if (registeredCareHomesCount === 1) {
		// For a single care home operator, use a special recency rule:
		if (careHomesNew3Years > 0) return 3
		else if (careHomesNew5Years > 0) return 2
		else return 0
	} else if (registeredCareHomesCount === 2) {
		// For operators with 2 care homes, use only recency.
		return recencyScore
	} else if (registeredCareHomesCount >= 3 && registeredCareHomesCount <= 20) {
		// For operators with 3-20 care homes, average recency and relative scores.
		return (recencyScore + relativeScore) / 2
	} else {
		// More than 20
		// For large operators, use all three components.
		return (recencyScore + relativeScore + absoluteScore) / 3
	}
}

function calculateRecencyGrowthScore(
	careHomesNew3Years: number,
	careHomesNew5Years: number,
	careHomesNew7Years: number,
): number {
	if (careHomesNew3Years > 0) return 5
	if (careHomesNew5Years > 0) return 3
	if (careHomesNew7Years > 0) return 1
	return 0
}

function calculateRelativeCAGRScore(bedsCAGR3Years: number, bedsCAGR5Years: number): number {
	const weightedCAGR = isNaN(bedsCAGR5Years) ? bedsCAGR3Years : (2 / 3) * bedsCAGR3Years + (1 / 3) * bedsCAGR5Years
	if (weightedCAGR >= 0.2) return 5
	if (weightedCAGR >= 0.15) return 4
	if (weightedCAGR >= 0.1) return 3
	if (weightedCAGR >= 0.05) return 2
	if (weightedCAGR > 0) return 1
	return 0
}

function calculateAbsoluteGrowthScore(careHomesNew3Years: number, careHomesNew5Years: number): number {
	const weightedAbsolute = (2 / 3) * careHomesNew3Years + (1 / 3) * careHomesNew5Years
	if (weightedAbsolute >= 10) return 5
	if (weightedAbsolute >= 5) return 4
	if (weightedAbsolute >= 3) return 3
	if (weightedAbsolute > 1) return 2
	if (weightedAbsolute > 0) return 1
	return 0
}

export { calculateOperatorGrowthScoreDynamic }
