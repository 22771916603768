import polyline from '@mapbox/polyline'

import { Property } from '../models/property'

function isLatLng(coord: any): coord is [number, number] {
	return Array.isArray(coord) && coord.length === 2 && coord.every((num) => typeof num === 'number')
}

interface Options {
	apiKey: string
	lat: number
	long: number
	zoom?: number
	radius?: number
	pins?: { lat: number; long: number }[]
	mapType?: 'roadmap' | 'satellite' | 'terrain' | 'hybrid'
	pinColor?: string
	pinLabel?: string
	size?: string
	scale?: number
	geoJsonArray?: Property['geometry'][]
	encodePath?: boolean
	centerPin?: boolean
}

function createStaticMap({
	apiKey,
	lat,
	long,
	zoom = 13,
	radius,
	pins = [],
	mapType = 'roadmap',
	pinColor = '0x46CB7B',
	pinLabel = 'P',
	size = '600x600',
	scale = 2,
	geoJsonArray = [],
	encodePath = false,
	centerPin = true,
}: Options) {
	// Initialize the array for path parameters
	const pathParams: [string, string][] = []

	// Add markers
	if (centerPin) {
		pathParams.push(['markers', `color:${pinColor}|label:${pinLabel}|${lat},${long}`]) // Center pin
	}
	pins.forEach((p) => {
		pathParams.push(['markers', `color:red%7C${p.lat},${p.long}`]) // Additional red pins
	})

	// Radius polygon generation
	if (radius) {
		const radiusMeters = radius * 1609.34
		const numPoints = 50
		const polygonCoords = []

		for (let i = 0; i <= numPoints; i++) {
			const angle = (i / numPoints) * 2 * Math.PI
			const offsetLat = lat + (radiusMeters / 111320) * Math.cos(angle)
			const offsetLng = long + (radiusMeters / (111320 * Math.cos(lat * (Math.PI / 180)))) * Math.sin(angle)
			polygonCoords.push(`${offsetLat},${offsetLng}`)
		}

		polygonCoords.push(polygonCoords[0]) // Close the polygon
		pathParams.push(['path', `color:0x46CB7B80|fillcolor:0x46CB7B40|weight:2|${polygonCoords.join('|')}`])
	}

	// Process GeoJSON array to create path strings
	geoJsonArray.forEach((geoJson) => {
		try {
			if (!geoJson || !geoJson.type || !geoJson.coordinates) return

			let coordinates: [number, number][] = []

			// Handle 'Polygon' and 'Multipolygon' types
			if (geoJson.type === 'Polygon') {
				coordinates = (geoJson.coordinates[0] as [number, number][]).filter(isLatLng).map(([lng, lat]) => [lat, lng])
			} else if (geoJson.type === 'Multipolygon') {
				coordinates = geoJson.coordinates
					.map((polygon) => polygon[0]) // First ring of each polygon
					.flat()
					.filter(isLatLng)
					.map(([lng, lat]) => [lat, lng])
			}

			if (coordinates.length > 1) {
				if (encodePath) {
					const encodedPath = polyline.encode(coordinates)
					pathParams.push(['path', `color:0x46CB7B80|fillcolor:0x46CB7B40|weight:2|enc:${encodedPath}`])
				} else {
					const rawPath = coordinates.map(([lat, lng]) => `${lat},${lng}`).join('|')
					pathParams.push(['path', `color:0x46CB7B80|fillcolor:0x46CB7B40|weight:2|${rawPath}`])
				}
			}
		} catch (error) {
			console.error('Invalid GeoJSON:', error)
		}
	})

	// Build the URL
	const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${long}&zoom=${zoom}&size=${size}&maptype=${mapType}`
	const queryParams = pathParams.map(([key, value]) => `${key}=${value}`).join('&')

	// Return the complete URL
	return `${baseUrl}&${queryParams}&key=${apiKey}&scale=${scale}`
}

export { createStaticMap }
