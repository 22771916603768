import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { CareFundingType } from '../../Constants'
import { RadiusValues } from '../../graphql/queries/__generated__/CareFundingByCaQuery.graphql'
import { CareFundingByCaQuery } from '../../graphql/queries/__generated__/CareFundingByCaQuery.graphql'
import { CareFundingByCa } from '../../graphql/queries/CareFundingByCa'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface StateFundedQuickFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const StateFundedByCa: React.FC<StateFundedQuickFactProps> = ({ oaCode, radius, compareRegion }) => {
	const { careFundingByCa: ccs } = useLazyLoadQuery<CareFundingByCaQuery>(CareFundingByCa, {
		careType: CareFundingType.careHome,
		oaCode,
		radius,
	})

	if (ccs.StateFunded.length < 2) {
		return <QuickFactPlaceholder />
	}

	const ladValues = ccs.StateFunded[0].values
	const regionValues = ccs.StateFunded[ccs.StateFunded.length - 2].values
	const nationalValues = ccs.StateFunded[ccs.StateFunded.length - 1].values
	const comparisonValues = compareRegion ? regionValues : nationalValues
	const currentLadValue = ladValues[ladValues.length - 1]
	const comparisonValue = comparisonValues[comparisonValues.length - 1]

	return (
		<QuickFact
			heading="StateFunded"
			value={formatPercentage(currentLadValue)}
			comparison={formatPercentage(comparisonValue)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<StateFundedQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<StateFundedByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as StateFundedByCa }
