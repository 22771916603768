/**
 * @generated SignedSource<<3d20282e273d75b046c41c6f79094724>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteProjectMutation$variables = {
  id: any;
};
export type DeleteProjectMutation$data = {
  readonly deleteProject: {
    readonly id: string;
    readonly success: boolean;
  };
};
export type DeleteProjectMutation = {
  response: DeleteProjectMutation$data;
  variables: DeleteProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Success",
        "kind": "LinkedField",
        "name": "deleteProject",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Success",
        "kind": "LinkedField",
        "name": "deleteProject",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db64f0cc6feaff10dfd1ede996809c62",
    "id": null,
    "metadata": {},
    "name": "DeleteProjectMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteProjectMutation(\n  $id: UUID!\n) {\n  deleteProject(id: $id) {\n    id\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb57c417d48bf000f65b23a9ec65739f";

export default node;
