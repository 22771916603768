import React from 'react'

import { milesRadiusValueInInts } from '../../../../../Constants'
import { useMvuData } from '../../../../../providers/MvuDataProvider'
import { Col, Container, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { Loading } from '../../../../../shared/Loading'
import { Template } from '../../../shared/Template'
import { CareNeeds } from './CareNeeds'
import { CareSupply } from './CareSupply'
import { InfoPanel } from './InfoPanel'
import { MarketEquilibrium } from './MarketEquilibrium'
import { PopulationForecast } from './PopulationForecast'
import { SurplusBedDemand } from './SurplusBedDemand'

const MarketAnalysis: React.FC = () => {
	const { project, geographies, radius, population: pop, careStats: cs, demographics: dem } = useMvuData()
	const radiusInt = milesRadiusValueInInts[radius]

	return (
		<Template
			pageTitle="2. Market Analysis"
			pageSubtitle={`${radiusInt} Mile Radius`}
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row gutterSize={2}>
					<Col count={4}>
						<CareNeeds
							over75={pop.over75}
							regionName={geographies[1].name}
							dementiaStats={cs.dementiaStats}
							allowanceAwards={cs.aaAwardTypes}
							height="328px"
						/>
						<VerticalSpace size="xs" />
						<PopulationForecast project={project} geographies={geographies} radius={radius} height="328px" />
					</Col>
					<Col count={4}>
						<CareSupply
							radius={radius}
							regionName={geographies[1].name}
							elderlyCareStats={cs.elderly}
							dementiaCareStats={cs.dementia}
							height={494}
						/>
						<VerticalSpace size="xs" />
						<MarketEquilibrium
							radius={radius}
							regionName={geographies[1].name}
							elderlyCareStats={cs.elderly}
							allowanceAwards={cs.aaAwardTypes}
							height={162}
						/>
					</Col>
					<Col count={4}>
						<SurplusBedDemand
							over75In3={pop.over75In3}
							over75In6={pop.over75In6}
							elderlyCareStats={cs.elderly}
							allowanceAwards={cs.aaAwardTypes}
							height={428}
						/>
						<VerticalSpace size="xs" />
						<InfoPanel
							height={228}
							project={project}
							geographies={geographies}
							radius={radius}
							popIn3={pop.over75In3}
							popIn6={pop.over75In6}
							elderlyCareStats={cs.elderly}
							unemployment={dem.unemployment}
							gdhi={dem.gdhi}
							housePrices={dem.housePrices}
							hourlyWages={dem.hourlyWage}
							fees={cs.fees}
							funding={cs.funding}
							migration={dem.migration}
							allowanceAwards={cs.aaAwardTypes}
							dementiaStats={cs.dementiaStats}
						/>
					</Col>
				</Row>
			</Container>
		</Template>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="100%" />}>
			<MarketAnalysis />
		</React.Suspense>
	)
}

export { Suspended as MarketAnalysis }
