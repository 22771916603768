import React, { useState } from 'react'
import { useMutation } from 'react-relay'

import { ClearCacheMutation } from '../../graphql/queries/__generated__/ClearCacheMutation.graphql'
import { ClearCacheNode } from '../../graphql/queries/ClearCache'
import { Page } from '../../layout/page/Page'
import { Alert } from '../../shared/Alert'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Panel } from '../../shared/Panel'
import { Heading, PageTitle, Paragraph } from '../../shared/Text'

const Admin: React.FC = () => {
	const [inputValue, setInputValue] = useState('')
	const [deletedCount, setDeletedCount] = useState<number | null>(null)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	// Relay mutation hook
	const [commitMutation, isInFlight] = useMutation<ClearCacheMutation>(ClearCacheNode)

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()

		// Clear previous error and deleted count
		setErrorMessage(null)
		setDeletedCount(null)

		// Execute mutation
		commitMutation({
			variables: { key: inputValue },
			onCompleted: (response) => {
				if (response.clearCache) {
					const count = response.clearCache.deleted
					setDeletedCount(count) // Update deleted count
				}
			},
			onError: (err) => {
				console.error('Mutation error:', err)
				setErrorMessage(err.message) // Set error message for alert
			},
		})
	}

	return (
		<Page title="Admin">
			<Container>
				<VerticalSpace size="md" />
				<Row>
					<Col count={4}></Col>
					<Col count={4}>
						<PageTitle>Admin</PageTitle>
					</Col>
				</Row>
				<VerticalSpace size="md" />
				<Row>
					<Col count={4}></Col>
					<Col count={4}>
						<Panel>
							<form onSubmit={handleSubmit} className="mb-4">
								<label htmlFor="cacheKey" className="form-label">
									<Heading level={4}>Clear Cache</Heading>
									<Paragraph>
										You can delete by code or type. E.g. &apos;*E8000008*&apos; or &apos;*hourly*&apos;
									</Paragraph>
								</label>
								<VerticalSpace size="sm" />
								<div className="input-group">
									<input
										id="cacheKey"
										type="text"
										value={inputValue}
										onChange={(e) => setInputValue(e.target.value)}
										className="form-control"
										placeholder="Enter cache pattern"
										required
									/>
									<button type="submit" className="btn btn-danger" disabled={isInFlight || !inputValue}>
										{isInFlight ? 'Clearing...' : 'Clear Cache'}
									</button>
								</div>
							</form>

							{/* Display success alert for deleted items */}
							{deletedCount !== null && (
								<Alert type="success" closable={true}>
									<strong>Deleted Items:</strong> {deletedCount}
								</Alert>
							)}

							{/* Display error alert if there's an error */}
							{errorMessage && (
								<Alert type="danger" closable={true}>
									<strong>Error:</strong> {errorMessage}
								</Alert>
							)}
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

export { Admin }
