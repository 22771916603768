import React from 'react'

interface ButtonProps {
	onClick?: () => void
	disabled?: boolean
	children?: React.ReactNode
	icon?: React.ReactNode
	type?: 'button' | 'submit' | 'reset' // Added type prop here
	title?: string
	size?: 'sm' | 'md' | 'lg'
}

const PrimaryButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children, type, size }) => {
	const classNames = `btn-primary fw-bold ${size ? `btn-${size}` : ''}`
	return (
		<BaseButton icon={icon} onClick={onClick} disabled={disabled} type={type} className={classNames}>
			{children}
		</BaseButton>
	)
}
const PrimaryOutlineButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children, type, size }) => {
	const classNames = `btn-outline-primary fw-bold ${size ? `btn-${size}` : ''}`
	return (
		<BaseButton icon={icon} onClick={onClick} disabled={disabled} type={type} className={classNames}>
			{children}
		</BaseButton>
	)
}

const SecondaryButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children, type, size }) => {
	const classNames = `btn-secondary align-middle fw-bold ${size ? `btn-${size}` : ''}`
	return (
		<BaseButton icon={icon} onClick={onClick} disabled={disabled} type={type} className={classNames}>
			{children}
		</BaseButton>
	)
}

const SecondaryOutlineButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children, type, title }) => (
	<BaseButton
		icon={icon}
		onClick={onClick}
		disabled={disabled}
		type={type}
		title={title}
		className="btn-outline-secondary fw-bold"
	>
		{children}
	</BaseButton>
)

const DangerButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children, type }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} type={type} className="btn-danger fw-bold color">
		{children}
	</BaseButton>
)

const LinkButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children, type }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} type={type} className="btn-link fw-medium">
		{children}
	</BaseButton>
)

interface BaseButtonProps extends ButtonProps {
	className?: string
	style?: React.CSSProperties
	title?: string
}

const BaseButton: React.FC<BaseButtonProps> = ({
	icon,
	onClick,
	disabled,
	children,
	className = '',
	style,
	type = 'button',
	title = '',
}) => {
	const classes = `btn ${className}`
	const spanClassName = children ? '' : 'me-2'
	const iconStyle: React.CSSProperties = {
		position: 'relative',
		height: 20,
	}

	return (
		<button onClick={onClick} disabled={disabled} className={classes} style={style} type={type} title={title}>
			{icon && (
				<span style={iconStyle} className={spanClassName}>
					{icon}
				</span>
			)}
			{children}
		</button>
	)
}

const TextButton: React.FC<ButtonProps> = ({ onClick, disabled, children }) => (
	<button onClick={onClick} disabled={disabled} className="btn btn-link fw-bold fs-6">
		{children}
	</button>
)

export {
	BaseButton,
	DangerButton,
	LinkButton,
	PrimaryButton,
	PrimaryOutlineButton,
	SecondaryButton,
	SecondaryOutlineButton,
	TextButton,
}
