import graphql from 'babel-plugin-relay/macro'

export const PropertyById = graphql`
	query PropertyByIdQuery($id: ID!) {
		propertyById(id: $id) {
			id
			type
			geometry {
				type
				coordinates
			}
			properties {
				area
			}
		}
	}
`
