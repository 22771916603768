import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { dementiaSpecialismKey } from '../../Constants'
import {
	AADisablingConditionsByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/AADisablingConditionsByCaQuery.graphql'
import { AADisablingConditionsByCa } from '../../graphql/queries/AADisablingConditionsByCa'
import { Model } from '../../shared/charts/types'
import { ElderlyWithSevereDementiaChart } from './ElderlyWithSevereDementiaChart'

interface CaElderlyWithSevereDementiaChartProps {
	oaCode: string
	radius: RadiusValues
}

const CaElderlyWithSevereDementiaChart: React.FC<CaElderlyWithSevereDementiaChartProps> = ({ oaCode, radius }) => {
	const { aaDisablingConditionsByCa: dis } = useLazyLoadQuery<AADisablingConditionsByCaQuery>(
		AADisablingConditionsByCa,
		{
			oaCode,
			radius,
		},
	)

	const datasets = dis.datasets.map((dataset) => ({
		id: dementiaSpecialismKey,
		values: dataset.values.map((value) => value),
	}))

	const caDataset = [datasets[0]]

	const model: Model = {
		labels: dis.labels.map((year) => year.toString()),
		datasets: caDataset,
	}

	return <ElderlyWithSevereDementiaChart model={model} />
}

export { CaElderlyWithSevereDementiaChart }
