/**
 * @generated SignedSource<<724b5b808a696771f0d7ff51d682aa86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyBedShortagesQuickFactFragment$data = ReadonlyArray<{
  readonly numberOfBeds: number | null | undefined;
  readonly specialisms: ReadonlyArray<string>;
  readonly " $fragmentType": "ElderlyBedShortagesQuickFactFragment";
}>;
export type ElderlyBedShortagesQuickFactFragment$key = ReadonlyArray<{
  readonly " $data"?: ElderlyBedShortagesQuickFactFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedShortagesQuickFactFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ElderlyBedShortagesQuickFactFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "8aeab2f353b84094944b9ebf5269b920";

export default node;
