import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Page } from '../../layout/page/Page'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { getStoredLadCode } from '../../utils/localStorage'
import { Tabbar } from './Tabbar'
import { CareHomes } from './tabs/careHomes/CareHomes'
import { Dementia } from './tabs/dementia/Dementia'
import { Economic } from './tabs/economy/Economic'
import { Elderly } from './tabs/elderly/Elderly'
import { ElderlyCareMarket } from './tabs/elderlyCareMarket/ElderlyCareMarket'
import { Explorer } from './tabs/explorer/Explorer'
import { Housing } from './tabs/housing/Housing'
import { Population } from './tabs/population/Population'

interface LadProps {
	ladCode: string
	category: string
	tab: string
}

const Lad: React.FC<LadProps> = ({ ladCode, category, tab }) => {
	const navigate = useNavigate()

	const onTabChange = (key: string) => {
		navigate(`/lad/${ladCode}/${category}/${key}`)
	}

	const handleGenerateReport = () => {
		alert('Coming soon')
		// navigate(`/report/${ladCode}`)
	}

	const onCategoryChange = (category: string) => {
		navigate(`/lad/${ladCode}/${category}`)
	}

	return (
		<Page title={`Local Authority - ${ladCode}`}>
			<Container size="fluid">
				<div style={{ height: 40 }} />
				<Tabbar
					category={category}
					tab={tab}
					onCategoryChange={onCategoryChange}
					onTabChange={onTabChange}
					onGenerateReport={handleGenerateReport}
				/>
				<VerticalSpace size="lg" />
				{tab === 'explorer' && <Explorer ladCode={ladCode} />}
				{tab === 'economic' && <Economic ladCode={ladCode} />}
				{tab === 'population' && <Population ladCode={ladCode} />}
				{tab === 'housing' && <Housing ladCode={ladCode} />}
				{tab === 'elderly' && <Elderly ladCode={ladCode} />}
				{tab === 'dementia' && <Dementia ladCode={ladCode} />}
				{tab === 'care-homes' && <CareHomes ladCode={ladCode} />}
				{tab === 'elderly-care-market' && <ElderlyCareMarket ladCode={ladCode} />}
			</Container>
		</Page>
	)
}

type RouteParams = Record<string, string | undefined>

const LocalAuthority: React.FC = () => {
	const navigate = useNavigate()
	const { ladCode, category = 'elderly-care', tab = 'explorer' } = useParams<RouteParams>() // Get params from route

	useEffect(() => {
		if (!ladCode) {
			const code = getStoredLadCode()
			navigate(`/lad/${code}/elderly-care/explorer`, { replace: true })
		}
	}, [ladCode, navigate])

	// Don't render anything while navigating to prevent flickering
	if (!ladCode) return null

	const params = { ladCode, category, tab }

	return <Lad {...params} />
}

export { LocalAuthority as Lad }
