import React, { useMemo } from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { ProjectByReferenceQuery } from '../../graphql/queries/__generated__/ProjectByReferenceQuery.graphql'
import { ProjectByReference } from '../../graphql/queries/ProjectByReference'
import { createProjectModel } from '../../models/project'
import { OperatorGroupProvider } from '../../providers/OperatorGroupProvider'
import { Loading } from '../../shared/Loading'
import { OmaDataTable } from './OmaDataTable'

interface TableParams extends Record<string, string | undefined> {
	reference: string
}

const OmaData: React.FC = () => {
	const { reference = '' } = useParams<TableParams>()

	const { projectByReference } = useLazyLoadQuery<ProjectByReferenceQuery>(ProjectByReference, { id: reference })
	const project = useMemo(() => createProjectModel(projectByReference), [projectByReference])
	const miles = 30

	return (
		<OperatorGroupProvider oaCode={project.oaCode} miles={miles} region={project.regionCode}>
			<OmaDataTable
				project={project}
				projectUseCaseNursing={true}
				projectUseCaseResidential={true}
				projectUseCaseDementia={true}
			/>
		</OperatorGroupProvider>
	)
}

const Suspended: React.FC = (props) => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<OmaData {...props} />
		</React.Suspense>
	)
}

export { Suspended as OmaData }
