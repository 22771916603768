import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { Navbar } from '../layout/navbar/Navbar'

const MainLayout: React.FC = () => {
	const { ladCode } = useParams<{ ladCode: string }>()

	return (
		<>
			<Navbar ladCode={ladCode} />
			<div className="content-wrapper">
				<div className="main-content">
					<Outlet /> {/* This will render the matched child route */}
				</div>
			</div>
		</>
	)
}

export { MainLayout }
