import graphql from 'babel-plugin-relay/macro'

export const SiteById = graphql`
	query SiteByIdQuery($id: ID!) {
		siteById(id: $id) {
			id
			isCareHome
			overallRating
			organisationType
			emails
			employees
			linkedinId
			location
			ladCode
			locationTypes
			mainPhoneNumber
			name
			numberOfBeds
			latitude
			longitude
			phoneNumber
			postalAddressLine1
			postalAddressTownCity
			postalCode
			regionCode
			registrationDate
			hasActiveRegistration
			serviceTypes
			specialisms
			type
			uprn
			website
		}
	}
`
