function calculateCqcRatingScore(ratingOperatorMean: number) {
	if (ratingOperatorMean > 2 / 3) {
		return 5
	} else if (ratingOperatorMean === 2 / 3) {
		return 4
	} else if (ratingOperatorMean < 2 / 3 && ratingOperatorMean >= 0.5) {
		return 3
	} else if (ratingOperatorMean >= 1 / 3) {
		return 2
	} else {
		return 1
	}
}

export { calculateCqcRatingScore }
