import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface PopulationGrowthByAgeGroupChartProps {
	model: Model
	aspectRatio?: number
}

const PopulationGrowthByAgeGroupChart: React.FC<PopulationGrowthByAgeGroupChartProps> = ({ model, aspectRatio }) => {
	return (
		<>
			<Subtitle>Population Growth by Age Groups</Subtitle>
			<Paragraph>Interpolated population forecast for catchment area | Based on Census 2021</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart
				horizontal={false}
				stacked={false}
				model={model}
				showLabels={true}
				alpha={1}
				aspectRatio={aspectRatio}
			/>
		</>
	)
}

export { PopulationGrowthByAgeGroupChart }
