/**
 * @generated SignedSource<<eb40ad63691aaf6dbcb750e10f00d709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AddOperatorsToGroupMutation$variables = {
  id: string;
  operatorIds: ReadonlyArray<string>;
};
export type AddOperatorsToGroupMutation$data = {
  readonly addOperatorsToOperatorGroup: {
    readonly id: string;
    readonly name: string;
    readonly operators: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type AddOperatorsToGroupMutation = {
  response: AddOperatorsToGroupMutation$data;
  variables: AddOperatorsToGroupMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "operatorIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "operatorIds",
        "variableName": "operatorIds"
      }
    ],
    "concreteType": "OperatorGroup",
    "kind": "LinkedField",
    "name": "addOperatorsToOperatorGroup",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Operator",
        "kind": "LinkedField",
        "name": "operators",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddOperatorsToGroupMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddOperatorsToGroupMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5851185b40c0be20b3746c8eccda9c9c",
    "id": null,
    "metadata": {},
    "name": "AddOperatorsToGroupMutation",
    "operationKind": "mutation",
    "text": "mutation AddOperatorsToGroupMutation(\n  $id: ID!\n  $operatorIds: [ID!]!\n) {\n  addOperatorsToOperatorGroup(id: $id, operatorIds: $operatorIds) {\n    id\n    name\n    operators {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f20fb7083700db40109d6723d9d4ef5e";

export default node;
