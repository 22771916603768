import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks'

import { OperatorGroupsQuery } from '../graphql/queries/__generated__/OperatorGroupsQuery.graphql'
import { OperatorGroups } from '../graphql/queries/OperatorGroups'
import { Container, Row } from '../shared/layout/Grid'
import { VerticalSpace } from '../shared/layout/Space'
import { Loading } from '../shared/Loading'
import { debounce } from '../utils/debounce'

interface SearchOperatorGroupsProps {
	onSelectedOperatorGroup: (groupId: string) => void
}

const SearchOperatorGroups: React.FC<SearchOperatorGroupsProps> = ({ onSelectedOperatorGroup }) => {
	const [searchTerm, setSearchTerm] = useState('')
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
	const [queryRef, loadQuery] = useQueryLoader<OperatorGroupsQuery>(OperatorGroups)

	// Debounce the search term update
	const debouncedSetSearchTerm = useCallback(
		debounce((term: string) => {
			setDebouncedSearchTerm(term)
		}, 300),
		[],
	)

	useEffect(() => {
		debouncedSetSearchTerm(searchTerm)

		return () => {
			debouncedSetSearchTerm.cancel()
		}
	}, [searchTerm, debouncedSetSearchTerm])

	// Load the query whenever the debounced search term changes
	useEffect(() => {
		if (debouncedSearchTerm) {
			loadQuery({ limit: 10, offset: 0, filter: { search: debouncedSearchTerm } })
		}
	}, [debouncedSearchTerm, loadQuery])

	return (
		<Container>
			<Row>
				<input
					type="text"
					className="form-control"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					placeholder="Search Operator Groups"
				/>
				<VerticalSpace size="sm" />

				{queryRef && (
					<Suspense fallback={<Loading height={30} />}>
						<SearchResults queryRef={queryRef} onSelectedOperatorGroup={onSelectedOperatorGroup} />
					</Suspense>
				)}
			</Row>
		</Container>
	)
}

interface SearchResultsProps {
	queryRef: any
	onSelectedOperatorGroup: (groupId: string) => void
}

// Separate the `usePreloadedQuery` logic into its own component
const SearchResults: React.FC<SearchResultsProps> = ({ queryRef, onSelectedOperatorGroup }) => {
	const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null)
	const data = usePreloadedQuery<OperatorGroupsQuery>(OperatorGroups, queryRef)

	if (!data.operatorGroups || data.operatorGroups.operatorGroups.length === 0) {
		return <p>No results found.</p>
	}

	return (
		<div className="list-group p-0">
			{data.operatorGroups.operatorGroups.map((group) => (
				<div
					key={group.id}
					className={`list-group-item ${selectedGroupId === group.id ? 'active' : ''}`}
					onClick={() => {
						setSelectedGroupId(group.id)
						onSelectedOperatorGroup(group.id)
					}}
					style={{ cursor: 'pointer' }}
				>
					{group.name}
				</div>
			))}
		</div>
	)
}

export { SearchOperatorGroups }
