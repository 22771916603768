import graphql from 'babel-plugin-relay/macro'

export const OperatorGroups = graphql`
	query OperatorGroupsQuery($limit: Int!, $offset: Int!, $filter: OperatorGroupFilterOpts) {
		operatorGroups(limit: $limit, offset: $offset, filter: $filter) {
			limit
			offset
			hasNextPage
			operatorGroups {
				id
				name
				operators {
					id
					name
					sites {
						id
						name
						numberOfBeds
					}
				}
			}
		}
	}
`
