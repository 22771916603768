// Define Care Types
export enum CareType {
	Nursing = 'nursing',
	Residential = 'residential',
	ExtraCare = 'extra_care',
}

// Define Conditions
export enum Condition {
	General = 'general',
	Dementia = 'dementia',
}

function formatCareTypeResult(result: any): string {
	if (!result) return '-'

	const typeNames = result.types.map((type: string) =>
		type === CareType.Nursing
			? 'Nursing Care Home'
			: type === CareType.Residential
				? 'Residential Care Home'
				: type === CareType.ExtraCare
					? 'Extra Care Home'
					: '',
	)

	let formatted = typeNames.join(' & ')

	if (result.conditions.includes(Condition.Dementia)) {
		formatted = 'dementia ' + formatted
	}

	return formatted
}

export { formatCareTypeResult }
