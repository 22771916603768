import React from 'react'

interface FloatingWrapperProps {
	corner?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
	children: React.ReactNode
}

const FloatingWrapper: React.FC<FloatingWrapperProps> = ({ corner = 'bottomRight', children }) => {
	let classes = 'position-fixed m-3'
	if (corner === 'topLeft') {
		classes += ' top-0 start-0'
	} else if (corner === 'topRight') {
		classes += ' top-0 end-0'
	} else if (corner === 'bottomLeft') {
		classes += ' bottom-0 start-0'
	} else {
		classes += ' bottom-0 end-0'
	}

	return <div className={classes}>{children}</div>
}

export { FloatingWrapper }
