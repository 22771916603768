import graphql from 'babel-plugin-relay/macro'

export const ProjectsByUserId = graphql`
	query ProjectsByUserIdQuery($id: ID!) {
		projectsByUserId(id: $id) {
			projects {
				address
				area
				client
				date
				id
				ladCode
				latitude
				longitude
				mvu
				name
				oaCode
				postalCode
				properties {
					id
					type
					geometry {
						type
						coordinates
					}
					properties {
						area
						aonbIds
						brownfieldIds
						greenBeltIds
						floodRiskZoneIds
						listedBuildingIds
						specialProtectionAreaIds
						sitesOfSpecialScientificInterestIds
						treePreservationZoneIds
					}
				}
				reference
				regionCode
				settings
				status
				titleNumbers
				townCity
				userId
				userId
			}
		}
	}
`
