import graphql from 'babel-plugin-relay/macro'

export const PropertiesByLatLong = graphql`
	query PropertiesByLatLongQuery($latitude: Float!, $longitude: Float!, $metres: Int, $filter: PropertiesFilterOpts) {
		propertiesByLatLong(latitude: $latitude, longitude: $longitude, metres: $metres, filter: $filter) {
			features {
				id
				type
				geometry {
					type
					coordinates
				}
				properties {
					area
					aonbIds
					brownfieldIds
					greenBeltIds
					floodRiskZoneIds
					listedBuildingIds
					specialProtectionAreaIds
					sitesOfSpecialScientificInterestIds
					treePreservationZoneIds
				}
			}
		}
	}
`
