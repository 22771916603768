import graphql from 'babel-plugin-relay/macro'

export const OperatorGroupsByIds = graphql`
	query OperatorGroupsByIdsQuery($ids: [ID!]!) {
		operatorGroupsByIds(ids: $ids) {
			operatorGroups {
				id
				name
				operators {
					id
					name
					sites {
						id
						name
						isCareHome
						latitude
						longitude
						overallRating
						registrationDate
						hasActiveRegistration
						ladCode
						numberOfBeds
						serviceTypes
						specialisms
						regionCode
					}
				}
			}
		}
	}
`
