/**
 * @generated SignedSource<<01c79288898a87f5bd3990cc52305a55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RadiusValues = "eight" | "five" | "four" | "nine" | "one" | "seven" | "six" | "ten" | "three" | "two" | "%future added value";
export type SitesByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type SitesByCaQuery$data = {
  readonly sitesByCa: {
    readonly sites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"CareHomesSitesByCaFragment" | "CompetitionTableFragment" | "ElderlyBedShortagesForecastChartSiteFragment" | "ElderlyBedShortagesQuickFactFragment" | "ElderlyBedsTimeSeriesChartSiteFragment" | "ElderlySitesChartSiteFragment" | "SitesTableFragment">;
    }>;
  };
};
export type SitesByCaQuery = {
  response: SitesByCaQuery$data;
  variables: SitesByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "oaCode",
    "variableName": "oaCode"
  },
  {
    "kind": "Variable",
    "name": "radius",
    "variableName": "radius"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SitesByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sites",
        "kind": "LinkedField",
        "name": "sitesByCa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CareHomesSitesByCaFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SitesTableFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlySitesChartSiteFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlyBedShortagesForecastChartSiteFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlyBedsTimeSeriesChartSiteFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ElderlyBedShortagesQuickFactFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CompetitionTableFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SitesByCaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sites",
        "kind": "LinkedField",
        "name": "sitesByCa",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "sites",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialisms",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalAddressTownCity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "operatorName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBeds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overallRating",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceTypes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "operatorId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longitude",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f4483f1badae2db76eb82d1bd6ab5e5",
    "id": null,
    "metadata": {},
    "name": "SitesByCaQuery",
    "operationKind": "query",
    "text": "query SitesByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  sitesByCa(oaCode: $oaCode, radius: $radius) {\n    sites {\n      ...CareHomesSitesByCaFragment\n      ...SitesTableFragment\n      ...ElderlySitesChartSiteFragment\n      ...ElderlyBedShortagesForecastChartSiteFragment\n      ...ElderlyBedsTimeSeriesChartSiteFragment\n      ...ElderlyBedShortagesQuickFactFragment\n      ...CompetitionTableFragment\n      id\n    }\n  }\n}\n\nfragment CareHomesSitesByCaFragment on Site {\n  id\n  name\n  location\n  specialisms\n}\n\nfragment CompetitionTableFragment on Site {\n  id\n  location\n  name\n  numberOfBeds\n  operatorName\n  operatorId\n  serviceTypes\n  specialisms\n  registrationDate\n  overallRating\n  latitude\n  longitude\n}\n\nfragment ElderlyBedShortagesForecastChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n  specialisms\n}\n\nfragment ElderlyBedShortagesQuickFactFragment on Site {\n  numberOfBeds\n  specialisms\n}\n\nfragment ElderlyBedsTimeSeriesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n  specialisms\n}\n\nfragment ElderlySitesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment SitesTableFragment on Site {\n  id\n  name\n  postalAddressTownCity\n  operatorName\n  numberOfBeds\n  registrationDate\n  overallRating\n  specialisms\n  serviceTypes\n  website\n}\n"
  }
};
})();

(node as any).hash = "c28e0bc6c36856b8fdcfe811011cfb58";

export default node;
