import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { Specialism } from '../../Constants'
import { CareStatsByTypesQuery } from '../../graphql/queries/__generated__/CareStatsByTypesQuery.graphql'
import { LocalAuthoritiesQuery } from '../../graphql/queries/__generated__/LocalAuthoritiesQuery.graphql'
import { CareStatsByTypes } from '../../graphql/queries/CareStatsByTypes'
import { LocalAuthorities } from '../../graphql/queries/LocalAuthorities'
import { themeColors } from '../../Theme'
import { formatNumber, formatPercentage } from '../../utils/formatNumber'
import { DataTable, RowData } from '../DataTable'
import { CenterAligned } from '../layout/Alignment'
import { Col, Row } from '../layout/Grid'
import { Loading } from '../Loading'
import { ProgressBar } from '../ProgressBar'
import { Paragraph } from '../Text'
import { LaTableElderlyCareStatsFragment$key } from './__generated__/LaTableElderlyCareStatsFragment.graphql'
import { LaTableFragment$key } from './__generated__/LaTableFragment.graphql'
import { LaTableElderlyCareStatsFragment } from './LaTableElderlyCareStatsFragment'
import { LaTableFragment } from './LaTableFragment'

// Define BedSupply labels and colors based on quintile
const quintileToBedSupply = ['Best', 'Good', 'Mid', 'Low', 'Worst']

const LaTable: React.FC = () => {
	const navigate = useNavigate()
	const { localAuthorities } = useLazyLoadQuery<LocalAuthoritiesQuery>(LocalAuthorities, {})
	const { careStatsBySpecialism } = useLazyLoadQuery<CareStatsByTypesQuery>(CareStatsByTypes, {
		specialism: Specialism.elderly,
	})

	const lads = React.useMemo(() => {
		return localAuthorities || []
	}, [localAuthorities])

	const ecs = React.useMemo(() => {
		return careStatsBySpecialism || []
	}, [careStatsBySpecialism])

	let totalWithStats = 0

	const { stats } = useFragment<LaTableElderlyCareStatsFragment$key>(LaTableElderlyCareStatsFragment, ecs)

	type TableData = RowData & {
		bedSupply: number
	}

	const rows: TableData[] = lads.map((la: LaTableFragment$key) => {
		const laFragment = useFragment(LaTableFragment, la)
		const stat = stats.find((ec) => ec.code === laFragment.ladCode)

		const cell: any = {
			id: laFragment.ladCode,
			...laFragment,
		}

		cell.bedSupply = -1

		if (stat) {
			totalWithStats++
			const bedSupply = (stat.numberOfBeds / stat.populationOver65) * 100
			cell.numberOfBeds = formatNumber(stat.numberOfBeds)
			cell.populationOver65 = formatNumber(stat.populationOver65)
			cell.bedSupplyRate = formatPercentage(bedSupply, 0, 1)
			cell.bedSupply = bedSupply
		}

		return cell
	})

	// Sort rows by bedSupply in descending order
	rows.sort((a: any, b: any) => b.bedSupply - a.bedSupply)

	const quintileSize = Math.floor(totalWithStats / quintileToBedSupply.length)

	rows.forEach((cell: any, i: number) => {
		if (cell.bedSupply) {
			cell.comparativeSupplyScore = Math.floor(i / quintileSize)
		}
		return cell
	})

	rows.sort((a: any, b: any) => a.name.localeCompare(b.name))

	const handleRowClick = (id: string) => {
		navigate(`/lad/${id}`) // Navigate to the site detail page using the row's ID
	}

	const columns = React.useMemo<ColumnDef<TableData, any>[]>(
		() => [
			{
				header: 'Name',
				accessorKey: 'name',
			},
			{
				header: 'Region',
				accessorKey: 'regionName',
			},
			{
				header: 'Population 65+',
				accessorKey: 'populationOver65',
			},
			{
				header: 'Care beds',
				accessorKey: 'numberOfBeds',
			},
			{
				header: 'Bed Supply Rate',
				accessorKey: 'bedSupplyRate',
				// Use a custom rendering function
				cell: ({ row }) => {
					const bedSupply = row.original.bedSupply // Access the bedSupply directly from the row data
					return bedSupply == null ? (
						<Paragraph>-</Paragraph>
					) : (
						<Row>
							<Col count={3}>
								<Paragraph>{formatPercentage(bedSupply, 0, 1)}</Paragraph>
							</Col>
							<Col count={9}>
								<CenterAligned>
									<ProgressBar progress={bedSupply} />
								</CenterAligned>
							</Col>
						</Row>
					)
				},
			},
			{
				header: 'Supply Score',
				accessorKey: 'comparativeSupplyScore',
				// Use a custom rendering function
				cell: ({ getValue }) => {
					const value = getValue() as number
					const color = themeColors.severity[value]
					const label = quintileToBedSupply[value]

					return (
						<CenterAligned>
							<div className="badge" style={{ backgroundColor: color }}>
								{label}
							</div>
						</CenterAligned>
					)
				},
			},
		],
		[],
	)

	return <DataTable columns={columns} data={rows} onRowClick={handleRowClick} />
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading />}>
			<LaTable />
		</React.Suspense>
	)
}

export { Suspended as LaTable }
