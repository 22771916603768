import {} from '@react-google-maps/api'
import * as turf from '@turf/turf'
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson'
import React, { useEffect, useState } from 'react'
import { fetchQuery, useRelayEnvironment } from 'react-relay'

import {
	PropertiesByLatLongQuery,
	PropertiesByLatLongQuery$variables,
} from '../../graphql/queries/__generated__/PropertiesByLatLongQuery.graphql'
import { PropertiesByLatLong } from '../../graphql/queries/PropertiesByLatLong'
import { GraphQlProperties } from '../../models/property'
import { Loading } from '../../shared/Loading'
import { MapFeatures } from '../../shared/map/MapFeatures'
import { themeColors } from '../../Theme'
import { acresToSquareMeters } from '../../utils/acres'

export type FeatureProperties = {
	id: string
	area: number
	color?: string
}

interface Props {
	latitude: number
	longitude: number
	zoom: number
	selectedPropertyIds: string[]
	onCenterChanged: (lat: number, lng: number) => void
	onZoomChanged: (zoom: number) => void
	onSelectedProperty: (item: FeatureProperties) => void
}

const PropertyPicker: React.FC<Props> = ({
	latitude,
	longitude,
	zoom,
	onCenterChanged,
	onZoomChanged,
	selectedPropertyIds,
	onSelectedProperty,
}) => {
	const [propertiesByLatLong, setPropertiesByLatLong] = useState<GraphQlProperties | null>(null)

	const env = useRelayEnvironment()
	const metres = getRadiusFromZoom(zoom, 5000, 200)

	const variables: PropertiesByLatLongQuery$variables = {
		latitude,
		longitude,
		metres,
		filter: {
			minArea: acresToSquareMeters(0.4),
			maxArea: acresToSquareMeters(5),
		},
	}

	// Fetch property features only when lat/long change
	useEffect(() => {
		return fetchQuery<PropertiesByLatLongQuery>(env, PropertiesByLatLong, variables).subscribe({
			next: ({ propertiesByLatLong }) => setPropertiesByLatLong(propertiesByLatLong),
		}).unsubscribe
	}, [latitude, longitude, env, metres])

	const features = propertiesByLatLong?.features || []

	const allFeatures: Feature<Polygon | MultiPolygon, FeatureProperties>[] = features.map((feature) => ({
		type: 'Feature',
		properties: {
			id: feature.id,
			area: feature.properties.area,
			color: selectedPropertyIds.includes(feature.id) ? themeColors.primary : themeColors.info,
		},
		geometry: feature.geometry as Polygon | MultiPolygon,
	}))

	const mapFeatures: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties> = turf.featureCollection(allFeatures)

	const handleFeatureClick = (properties: any, event?: google.maps.MapMouseEvent) => {
		event?.stop() // Prevents duplicate events from bubbling
		onSelectedProperty(properties as FeatureProperties)
	}

	return (
		<MapFeatures
			height="100%"
			featureCollections={[mapFeatures]}
			onCenterChanged={onCenterChanged}
			onZoomChanged={onZoomChanged}
			onFeatureClick={handleFeatureClick}
			zoomToFeatures={false}
			center={{ lat: latitude, lng: longitude }}
			zoom={zoom}
		/>
	)
}

const getRadiusFromZoom = (zoom: number, max: number, min: number) => {
	const earthCircumference = 40075000 // Earth's circumference in meters
	const radius = (earthCircumference / 2 / Math.pow(2, zoom)) * 2
	return Math.round(Math.max(min, Math.min(radius, max)))
}

const Suspended: React.FC<Props> = (props) => (
	<React.Suspense fallback={<Loading height="100%" />}>
		<PropertyPicker {...props} />
	</React.Suspense>
)

export { Suspended as PropertyPicker }
