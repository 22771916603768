import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { FeatureCollection, GeoJsonProperties, MultiPolygon, Point, Polygon } from 'geojson'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { getGoogleMapsParams } from '../../Config'
import { drawFeatures, drawMarkers, fitToFeatures, fitToLocations } from './utils'

export interface Marker {
	readonly location: Point
	readonly name: string
	readonly id: string
}

// Define the props interface
interface MapSelectorProps {
	mapType?: google.maps.MapTypeId
	zoom?: number
	center?: google.maps.LatLngLiteral
	zoomToFeatures?: boolean
	featureCollections: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>[] // Change `any` to the correct type if possible
	locations?: Marker[]
	width?: string
	height?: string
	onMapClick?: (lat: number, lng: number) => void
	onCenterChanged?: (lat: number, lng: number) => void
	onFeatureClick?: (properties: any) => void
	onZoomChanged?: (zoom: number) => void
}

const options = {
	scrollwheel: false, // Disable scroll wheel zoom
	draggable: true, // Optional: Disable dragging
	controlSize: 25, // Optional: Larger zoom control buttons
}

const gm = getGoogleMapsParams()

export const MapFeatures: FC<MapSelectorProps> = ({
	mapType = 'hybrid',
	zoom = 7,
	center = { lat: 53.48, lng: -2.24 },
	featureCollections,
	locations = [],
	height = '70vh',
	width = '100%',
	zoomToFeatures,
	onMapClick,
	onCenterChanged,
	onFeatureClick,
	onZoomChanged,
}) => {
	const containerStyle = {
		width,
		height,
	}

	const [map, setMap] = useState<google.maps.Map | undefined>(undefined)

	// Load the Google Maps API
	const { isLoaded } = useJsApiLoader(gm)

	// Handle map click
	const handleMapClick = (event: google.maps.MapMouseEvent) => {
		if (event.latLng) {
			const lat = event.latLng.lat().toFixed(3)
			const lng = event.latLng.lng().toFixed(3)
			if (onMapClick) {
				onMapClick(
					parseFloat(lat), // Parse the lat
					parseFloat(lng), // Parse the lng
				)
			}
		}
	}

	useEffect(() => {
		if (map && featureCollections) {
			drawFeatures(map, featureCollections)
			if (featureCollections.length > 0 && zoomToFeatures) {
				fitToFeatures(map, featureCollections)
			}
		}
	}, [map, featureCollections])

	useEffect(() => {
		if (map && locations.length > 0) {
			drawMarkers(map, locations)
			if (featureCollections.length <= 0 && locations.length > 0) {
				fitToLocations(map, locations)
			}
		}
	}, [map, locations])

	const onLoad = useCallback((map: google.maps.Map) => {
		setMap(map)

		map.setMapTypeId(mapType) // Force the map to open in Hybrid view

		map.data.addListener('click', (event: google.maps.Data.MouseEvent) => {
			const properties: any = {}

			event.feature.forEachProperty((value, key) => {
				properties[key] = value
			})

			if (onFeatureClick) {
				onFeatureClick(properties)
			}
		})

		map.data.addListener('mouseover', (event: google.maps.Data.MouseEvent) => {
			map.data.overrideStyle(event.feature, { strokeWeight: 4, fillOpacity: 0.7 })
		})

		map.data.addListener('mouseout', (event: google.maps.Data.MouseEvent) => {
			map.data.revertStyle(event.feature)
		})
	}, [])

	const onUnmount = useCallback(() => {
		setMap(undefined)
	}, [])

	// Handle map drag end (when the map stops being dragged)
	const handleCenterChange = () => {
		if (map && onCenterChanged) {
			const center = map.getCenter()
			if (center) {
				onCenterChanged(center.lat(), center.lng())
			}
		}
	}

	const handleZoomChange = () => {
		if (map && onZoomChanged) {
			const zoomLevel = map.getZoom()
			if (zoomLevel) {
				onZoomChanged(zoomLevel)
			}
		}
	}

	return (
		<div style={containerStyle}>
			{isLoaded ? (
				<GoogleMap
					mapTypeId={mapType}
					mapContainerStyle={containerStyle}
					center={center}
					zoom={zoom}
					onLoad={onLoad}
					onUnmount={onUnmount}
					onClick={handleMapClick} // Add the onClick handler
					onDragEnd={handleCenterChange} // Drag end handler
					onZoomChanged={handleZoomChange} // Zoom changed handler
					options={options}
				/>
			) : (
				<></>
			)}
		</div>
	)
}
