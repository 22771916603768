import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import {
	CareFundingType,
	currentYear,
	elderlyCareScores,
	knightFrank,
	Specialism,
	staffResidentRatio,
	valuationProxies,
} from '../../../Constants'
import { AAAwardTypesByCaQuery } from '../../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { AADisablingConditionsByCaQuery } from '../../../graphql/queries/__generated__/AADisablingConditionsByCaQuery.graphql'
import { CareFeeRatesByCaQuery } from '../../../graphql/queries/__generated__/CareFeeRatesByCaQuery.graphql'
import { CareFundingByCaQuery } from '../../../graphql/queries/__generated__/CareFundingByCaQuery.graphql'
import { CareStatsByCaQuery } from '../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { DementiaStatsByCaQuery } from '../../../graphql/queries/__generated__/DementiaStatsByCaQuery.graphql'
import { GeographiesByCodesQuery } from '../../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { GrossDisposableHouseholdIncomesByCaQuery } from '../../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { HourlyWagesByCaQuery } from '../../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { HousePricesByCaQuery } from '../../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { IndustryDistributionsByCaQuery } from '../../../graphql/queries/__generated__/IndustryDistributionsByCaQuery.graphql'
import { MigrationStatsByCaQuery } from '../../../graphql/queries/__generated__/MigrationStatsByCaQuery.graphql'
import { PopulationByCaQuery, RadiusValues } from '../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { SitesByCaQuery } from '../../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { TenureTypesByCaQuery } from '../../../graphql/queries/__generated__/TenureTypesByCaQuery.graphql'
import { UnemploymentRatesByCaQuery } from '../../../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { AAAwardTypesByCa } from '../../../graphql/queries/AAAwardTypesByCa'
import { AADisablingConditionsByCa } from '../../../graphql/queries/AADisablingConditionsByCa'
import { CareFeeRatesByCa } from '../../../graphql/queries/CareFeeRatesByCa'
import { CareFundingByCa } from '../../../graphql/queries/CareFundingByCa'
import { CareStatsByCa } from '../../../graphql/queries/CareStatsByCa'
import { DementiaStatsByCa } from '../../../graphql/queries/DementiaStatsByCa'
import { GeographiesByCodes } from '../../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByCa } from '../../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { HourlyWagesByCa } from '../../../graphql/queries/HourlyWagesByCa'
import { HousePricesByCa } from '../../../graphql/queries/HousePricesByCa'
import { IndustryDistributionsByCa } from '../../../graphql/queries/IndustryDistributionsByCa'
import { MigrationStatsByCa } from '../../../graphql/queries/MigrationStatsByCa'
import { PopulationByCa } from '../../../graphql/queries/PopulationByCa'
import { SitesByCa } from '../../../graphql/queries/SitesByCa'
import { TenureTypesByCa } from '../../../graphql/queries/TenureTypesByCa'
import { UnemploymentRatesByCa } from '../../../graphql/queries/UnemploymentRatesByCa'
import { calculateDistance, Point } from '../../../utils/calculateDistance'
import { formatCurrency, formatNumber, formatPercentage } from '../../../utils/formatNumber'
import { getGeoValuesFromDataset } from '../../../utils/getGeoValuesFromDataset'
import { filterEldery } from '../../../utils/sites'
import { isValidUrl } from '../../../utils/url'
import { CompetitionTableFragment$key } from '../shared/__generated__/CompetitionTableFragment.graphql'
import { CompetitionTableFragment } from '../shared/CompetitionTableFragment'

interface RowProps {
	data: string[]
	isMissing?: boolean
}

const TableRow: React.FC<RowProps> = ({ data, isMissing = false }) => {
	const className = isMissing ? 'table-danger' : ''
	return (
		<tr className={className}>
			{data.map((cell, index) => (
				<td key={index}>{cell}</td>
			))}
		</tr>
	)
}

const Header: React.FC<RowProps> = ({ data }) => {
	return (
		<tr className="table-light">
			{data.map((cell, index) => (
				<th key={index}>{cell}</th>
			))}
		</tr>
	)
}

interface AnchorRowProps {
	label: string
	src: string
}

const AnchorRow: React.FC<AnchorRowProps> = ({ label, src }) => {
	if (!isValidUrl(src)) {
		return <TableRow isMissing={true} data={[label, '', '', '', '']} />
	}
	return (
		<tr>
			<td>{label}</td>
			<td></td>
			<td>
				<a href={src} target="_blank" rel="noreferrer">
					{label}
				</a>
			</td>
			<td></td>
			<td></td>
		</tr>
	)
}

interface PopProps {
	label: string
	oaCode: string
	radius: RadiusValues
	minAge?: number
	maxAge?: number
	futureOffset?: number
}
const PopulationRow: React.FC<PopProps> = ({ label, oaCode, radius, minAge = 0, maxAge = 100, futureOffset = 0 }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge,
		maxAge,
		futureOffset,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}

	const idx = pop.years.length - 1

	const ca = geos[0].ageRanges[0].values[idx]
	const region = geos[geos.length - 2].ageRanges[0].values[idx]
	const country = geos[geos.length - 1].ageRanges[0].values[idx]

	return <TableRow data={[label, '', formatNumber(ca), formatNumber(region), formatNumber(country)]} />
}

interface CaRowProps {
	oaCode: string
	radius: RadiusValues
}

interface GeoRowProps extends CaRowProps {
	ladCode: string
}

const GeoRow: React.FC<GeoRowProps> = ({ ladCode, oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 0,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geoCodes = pop?.geographies || []
	if (geoCodes.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}

	const codes = pop.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const ca = geos.find((g) => g.code === ladCode)?.name || '-'
	const region = geos.find((g) => g.type === 'region')?.name || '-'
	const country = geos.find((g) => g.type === 'country')?.name || '-'

	return (
		<React.Fragment>
			<TableRow data={['Geo Codes', '', codes[0], codes[codes.length - 2], codes[codes.length - 1]]} />
			<TableRow data={['Geo Names', '', ca, region, country]} />
		</React.Fragment>
	)
}

const GDHIRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { grossDisposableHouseholdIncomesByCa: { datasets = [] } = {} } =
		useLazyLoadQuery<GrossDisposableHouseholdIncomesByCaQuery>(GrossDisposableHouseholdIncomesByCa, {
			oaCode,
			radius,
		})

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	return (
		<TableRow
			data={[
				'Gross Disposable Income',
				'',
				formatCurrency(ca, 0),
				formatCurrency(region, 0),
				formatCurrency(country, 0),
			]}
		/>
	)
}

const HPRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { housePricesByCa: { datasets = [] } = {} } = useLazyLoadQuery<HousePricesByCaQuery>(HousePricesByCa, {
		oaCode,
		radius,
	})

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	return (
		<TableRow
			data={['House Price Average', '', formatCurrency(ca, 0), formatCurrency(region, 0), formatCurrency(country, 0)]}
		/>
	)
}

const SRRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { tenureTypesByCa: { datasets = [] } = {} } = useLazyLoadQuery<TenureTypesByCaQuery>(TenureTypesByCa, {
		oaCode,
		radius,
	})

	const { ca, region, country } = getGeoValuesFromDataset(datasets, 11)

	return (
		<TableRow data={['Social Rent', '', formatPercentage(ca), formatPercentage(region), formatPercentage(country)]} />
	)
}

const HWRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { hourlyWagesByCa: { datasets = [] } = {} } = useLazyLoadQuery<HourlyWagesByCaQuery>(HourlyWagesByCa, {
		oaCode,
		radius,
	})

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	return <TableRow data={['Hourly Wage', '', formatCurrency(ca), formatCurrency(region), formatCurrency(country)]} />
}

const UERow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { unemploymentRatesByCa: { datasets = [] } = {} } = useLazyLoadQuery<UnemploymentRatesByCaQuery>(
		UnemploymentRatesByCa,
		{ oaCode, radius },
	)

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	return (
		<TableRow
			data={[
				'Unemployment Rate',
				'',
				formatPercentage(ca, 1, 1),
				formatPercentage(region, 1, 1),
				formatPercentage(country, 1, 1),
			]}
		/>
	)
}

const ECIFTNRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { aaAwardTypesByCa: aa } = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, {
		oaCode,
		radius,
	})

	const { ca: caH, region: regionH, country: countryH } = getGeoValuesFromDataset(aa.higherRate)
	const { ca: caL, region: regionL, country: countryL } = getGeoValuesFromDataset(aa.lowerRate)
	const { ca: caT, region: regionT, country: countryT } = getGeoValuesFromDataset(aa.total)

	return (
		<>
			<TableRow
				data={[
					'Elderly in need of care assistance',
					'',
					formatNumber(caT),
					formatNumber(regionT),
					formatNumber(countryT),
				]}
			/>
			<TableRow
				data={[
					'Elderly in need of full-time care',
					'',
					formatNumber(caH),
					formatNumber(regionH),
					formatNumber(countryH),
				]}
			/>
			<TableRow
				data={[
					'Elderly in need of part-time care',
					'',
					formatNumber(caL),
					formatNumber(regionL),
					formatNumber(countryL),
				]}
			/>
		</>
	)
}

const DisRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { aaDisablingConditionsByCa: { datasets = [] } = {} } = useLazyLoadQuery<AADisablingConditionsByCaQuery>(
		AADisablingConditionsByCa,
		{ oaCode, radius },
	)

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	return (
		<TableRow
			data={[
				'Disability Dementia AA',
				'',
				formatNumber(ca, 0, 0),
				formatNumber(region, 0, 0),
				formatNumber(country, 0, 0),
			]}
		></TableRow>
	)
}

const DementiaRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { dementiaStatsByCa: dem } = useLazyLoadQuery<DementiaStatsByCaQuery>(DementiaStatsByCa, {
		oaCode,
		radius,
	})

	const { ca: caE, region: regionE, country: countryE } = getGeoValuesFromDataset(dem.estimated)
	const { ca: caR, region: regionR, country: countryR } = getGeoValuesFromDataset(dem.registered)

	return (
		<>
			<TableRow
				data={[
					'Estimated Dementia Cases',
					'',
					formatNumber(caE, 0, 0),
					formatNumber(regionE, 0, 0),
					formatNumber(countryE, 0, 0),
				]}
			/>
			<TableRow
				data={[
					'Registered Dementia Cases',
					'',
					formatNumber(caR, 0, 0),
					formatNumber(regionR, 0, 0),
					formatNumber(countryR, 0, 0),
				]}
			/>
		</>
	)
}

const OccupancyRateRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caOccupancy = stats[0].occupancyRate
	const regionOccupancy = stats[stats.length - 2].occupancyRate
	const countryOccupancy = stats[stats.length - 1].occupancyRate

	return (
		<TableRow
			data={[
				'Average Occupancy Rate',
				'',
				formatPercentage(caOccupancy, 1, 1),
				formatPercentage(regionOccupancy, 1, 1),
				formatPercentage(countryOccupancy, 1, 1),
			]}
		></TableRow>
	)
}

const RatingRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caRating = stats[0].rating * 100
	const regionRating = stats[stats.length - 2].rating * 100
	const countryRating = stats[stats.length - 1].rating * 100

	return (
		<TableRow
			data={[
				'Average CQC Rating',
				'',
				formatPercentage(caRating, 1, 1),
				formatPercentage(regionRating, 1, 1),
				formatPercentage(countryRating, 1, 1),
			]}
		></TableRow>
	)
}

const BedRows: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const ca = stats[0]
	const region = stats[stats.length - 2]
	const country = stats[stats.length - 1]

	return (
		<>
			<TableRow
				data={[
					'Total Elderly Care Beds',
					'',
					formatNumber(ca.numberOfBeds),
					formatNumber(region.numberOfBeds),
					formatNumber(country.numberOfBeds),
				]}
			></TableRow>
			<TableRow
				data={[
					'Nursing Beds',
					'',
					formatNumber(ca.nursingBeds),
					formatNumber(region.nursingBeds),
					formatNumber(country.nursingBeds),
				]}
			></TableRow>
			<TableRow
				data={[
					'Residential Care Beds',
					'',
					formatNumber(ca.residentialBeds),
					formatNumber(region.residentialBeds),
					formatNumber(country.residentialBeds),
				]}
			></TableRow>
		</>
	)
}

interface CareFeesRowProps extends CaRowProps {
	specialism: Specialism
}

const DementiaBedsRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.dementia,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].numberOfBeds
	const regionBeds = stats[stats.length - 2].numberOfBeds
	const countryBeds = stats[stats.length - 1].numberOfBeds

	return (
		<TableRow
			data={[
				'Dementia Beds',
				'',
				formatNumber(caBeds, 0, 0),
				formatNumber(regionBeds, 0, 0),
				formatNumber(countryBeds, 0, 0),
			]}
		></TableRow>
	)
}

const DementiaBedsNursingRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.dementia,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].nursingBeds
	const regionBeds = stats[stats.length - 2].nursingBeds
	const countryBeds = stats[stats.length - 1].nursingBeds

	return (
		<TableRow
			data={[
				'Dementia Beds - Nursing',
				'',
				formatNumber(caBeds, 0, 0),
				formatNumber(regionBeds, 0, 0),
				formatNumber(countryBeds, 0, 0),
			]}
		></TableRow>
	)
}

const DementiaBedsResidentialRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.dementia,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].residentialBeds
	const regionBeds = stats[stats.length - 2].residentialBeds
	const countryBeds = stats[stats.length - 1].residentialBeds

	return (
		<TableRow
			data={[
				'Dementia Beds - Residential',
				'',
				formatNumber(caBeds, 0, 0),
				formatNumber(regionBeds, 0, 0),
				formatNumber(countryBeds, 0, 0),
			]}
		></TableRow>
	)
}

const DomiciliaryCapacityRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caDomCap = stats[0].domiciliaryCareWorkers * staffResidentRatio.homecare
	const regionDomCap = stats[stats.length - 2].domiciliaryCareWorkers * staffResidentRatio.homecare
	const countryDomCap = stats[stats.length - 1].domiciliaryCareWorkers * staffResidentRatio.homecare

	return (
		<TableRow
			data={[
				'Domiciliary Care Capacity',
				'',
				formatNumber(caDomCap, 0, 0),
				formatNumber(regionDomCap, 0, 0),
				formatNumber(countryDomCap, 0, 0),
			]}
		></TableRow>
	)
}

const MarketAnalyticsRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const { aaAwardTypesByCa: aa } = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, {
		oaCode,
		radius,
	})

	const { populationByCa: pop75Current } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: 2024,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 3,
		includeIntermediates: false,
	})

	const geos = pop75Current?.geographies || []

	const caPop75_2024 = geos[0].ageRanges[0].values[0]
	const regionPop75_2024 = geos[geos.length - 2].ageRanges[0].values[0]
	const countryPop75_2024 = geos[geos.length - 1].ageRanges[0].values[0]

	const caPop75_2027 = geos[0].ageRanges[0].values[1]
	const regionPop75_2027 = geos[geos.length - 2].ageRanges[0].values[1]
	const countryPop75_2027 = geos[geos.length - 1].ageRanges[0].values[1]

	const caDomCap = stats[0].domiciliaryCareWorkers * staffResidentRatio.homecare
	const regionDomCap = stats[stats.length - 2].domiciliaryCareWorkers * staffResidentRatio.homecare
	const countryDomCap = stats[stats.length - 1].domiciliaryCareWorkers * staffResidentRatio.homecare

	const caBeds = stats[0].numberOfBeds
	const regionBeds = stats[stats.length - 2].numberOfBeds
	const countryBeds = stats[stats.length - 1].numberOfBeds

	const caTotalCap = caDomCap + caBeds
	const regionTotalCap = regionDomCap + regionBeds
	const countryTotalCap = countryDomCap + countryBeds

	const { ca: caH, region: regionH, country: countryH } = getGeoValuesFromDataset(aa.higherRate)
	const { ca: caT, region: regionT, country: countryT } = getGeoValuesFromDataset(aa.total)

	const caTotalShortage = caTotalCap - caT
	const regionTotalShortage = regionTotalCap - regionT
	const countryTotalShortage = countryTotalCap - countryT

	const caCareSupplyRate = (caTotalCap / caT) * 100
	const regionCareSupplyRate = (regionTotalCap / regionT) * 100
	const countryCareSupplyRate = (countryTotalCap / countryT) * 100

	const caHighherRateSupplyRate = (caBeds / caH) * 100
	const regionHigherRateSupplyRate = (regionBeds / regionH) * 100
	const countryHigherRateSupplyRate = (countryBeds / countryH) * 100

	const caHRPopRatio = caH / caPop75_2024
	const regionHRPopRatio = regionH / regionPop75_2024
	const countryHRPopRatio = countryH / countryPop75_2024

	const caHigherRateShortage_2024 = caTotalCap - caH
	const caHigherRateShortage_2027 = caTotalCap - caHRPopRatio * caPop75_2027

	const regionHigherRateShortage_2024 = regionTotalCap - regionH
	const regionHigherRateShortage_2027 = regionTotalCap - regionHRPopRatio * regionPop75_2027

	const countryHigherRateShortage_2024 = countryTotalCap - countryH
	const countryHigherRateShortage_2027 = countryTotalCap - countryHRPopRatio * countryPop75_2027

	const caBedShortage = caBeds - caT
	const regionBedShortage = regionBeds - regionT
	const countryBedShortage = countryBeds - countryT

	const caHigherRateBedShortage_2024 = caBeds - caH
	const regionHigherRateBedShortage_2024 = regionBeds - regionH
	const countryHigherRateBedShortage_2024 = countryBeds - countryH

	const caHigherRateBedShortage_2027 = caBeds - caHRPopRatio * caPop75_2027
	const regionHigherRateBedShortage_2027 = regionBeds - regionHRPopRatio * regionPop75_2027
	const countryHigherRateBedShortage_2027 = countryBeds - countryHRPopRatio * countryPop75_2027

	return (
		<>
			<Header data={['CARE SHORTAGES', '', '', '', '']}></Header>
			<Header
				data={[
					'Total Care Capacity incl. domiciliary care supply',
					'',
					formatNumber(caTotalCap, 0, 0),
					formatNumber(regionTotalCap, 0, 0),
					formatNumber(countryTotalCap, 0, 0),
				]}
			></Header>
			<TableRow
				data={[
					'Total Care Demand (all elderly in need of care)',
					'',
					formatNumber(caT, 0, 0),
					formatNumber(regionT, 0, 0),
					formatNumber(countryT, 0, 0),
				]}
			></TableRow>
			<TableRow
				isMissing={true}
				data={[
					'Total Care Shortage',
					'',
					formatNumber(caTotalShortage, 0, 0),
					formatNumber(regionTotalShortage, 0, 0),
					formatNumber(countryTotalShortage, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Total Care Supply Rate',
					'',
					formatPercentage(caCareSupplyRate, 0, 1),
					formatPercentage(regionCareSupplyRate, 0, 1),
					formatPercentage(countryCareSupplyRate, 0, 1),
				]}
			></TableRow>
			<TableRow
				data={[
					'Full-time Care Demand 2024',
					'',
					formatNumber(caH, 0, 0),
					formatNumber(regionH, 0, 0),
					formatNumber(countryH, 0, 0),
				]}
			></TableRow>
			<TableRow
				isMissing={true}
				data={[
					'Full-time Care Shortage 2024',
					'',
					formatNumber(caHigherRateShortage_2024, 0, 0),
					formatNumber(regionHigherRateShortage_2024, 0, 0),
					formatNumber(countryHigherRateShortage_2024, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Full-time Shortage 2027',
					'',
					formatNumber(caHigherRateShortage_2027, 0, 0),
					formatNumber(regionHigherRateShortage_2027, 0, 0),
					formatNumber(countryHigherRateShortage_2027, 0, 0),
				]}
			></TableRow>
			<Header
				data={[
					'Total Care Bed Capacity',
					'',
					formatNumber(caBeds, 0, 0),
					formatNumber(regionBeds, 0, 0),
					formatNumber(countryBeds, 0, 0),
				]}
			></Header>
			<TableRow
				data={[
					'Total Care Demand (all elderly in need of care)',
					'',
					formatNumber(caT, 0, 0),
					formatNumber(regionT, 0, 0),
					formatNumber(countryT, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Total Care Bed Shortage 2024',
					'',
					formatNumber(caBedShortage, 0, 0),
					formatNumber(regionBedShortage, 0, 0),
					formatNumber(countryBedShortage, 0, 0),
				]}
			></TableRow>
			<TableRow
				isMissing={true}
				data={[
					'Full-time Care Demand 2024',
					'',
					formatNumber(caH, 0, 0),
					formatNumber(regionH, 0, 0),
					formatNumber(countryH, 0, 0),
				]}
			></TableRow>
			<TableRow
				isMissing={true}
				data={[
					'Full-time Care Bed Shortage 2024',
					'',
					formatNumber(caHigherRateBedShortage_2024, 0, 0),
					formatNumber(regionHigherRateBedShortage_2024, 0, 0),
					formatNumber(countryHigherRateBedShortage_2024, 0, 0),
				]}
			></TableRow>

			<TableRow
				isMissing={true}
				data={[
					'Full-time Care Demand 2027',
					'',
					formatNumber(caHRPopRatio * caPop75_2027, 0, 0),
					formatNumber(regionHRPopRatio * regionPop75_2027, 0, 0),
					formatNumber(countryHRPopRatio * countryPop75_2027, 0, 0),
				]}
			></TableRow>
			<TableRow
				isMissing={true}
				data={[
					'Full-time Care Bed Shortage 2027',
					'',
					formatNumber(caHigherRateBedShortage_2027, 0, 0),
					formatNumber(regionHigherRateBedShortage_2027, 0, 0),
					formatNumber(countryHigherRateBedShortage_2027, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Full-time Care Bed Supply Rate 2024',
					'',
					formatPercentage(caHighherRateSupplyRate, 0, 0),
					formatPercentage(regionHigherRateSupplyRate, 0, 0),
					formatPercentage(countryHigherRateSupplyRate, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'75+ 2024',
					'',
					formatNumber(caPop75_2024, 0, 0),
					formatNumber(regionPop75_2024, 0, 0),
					formatNumber(countryPop75_2024, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'75+ Increment 2024-2027',
					'',
					formatNumber(caPop75_2027 - caPop75_2024, 0, 0),
					formatNumber(regionPop75_2027 - regionPop75_2024, 0, 0),
					formatNumber(countryPop75_2027 - countryPop75_2024, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'75+ 2027',
					'',
					formatNumber(caPop75_2027, 0, 0),
					formatNumber(regionPop75_2027, 0, 0),
					formatNumber(countryPop75_2027, 0, 0),
				]}
			></TableRow>
		</>
	)
}

const CareScoreRow: React.FC<CareFeesRowProps> = ({ specialism, oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}
	const idx = pop.years.length - 1

	const caPop75 = geos[0].ageRanges[0].values[idx]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[idx]
	const countryPop75 = geos[geos.length - 1].ageRanges[0].values[idx]

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].numberOfBeds
	const regionBeds = stats[stats.length - 2].numberOfBeds
	const countryBeds = stats[stats.length - 1].numberOfBeds

	const caRate = caBeds / caPop75
	const regionRate = regionBeds / regionPop75
	const countryRate = countryBeds / countryPop75

	const { min, max } = elderlyCareScores[specialism]

	const ca = ((caRate - min) / (max - min)) * 100
	const region = ((regionRate - min) / (max - min)) * 100
	const country = ((countryRate - min) / (max - min)) * 100

	const specialismValue = Specialism[specialism].charAt(0).toUpperCase() + Specialism[specialism].slice(1).toLowerCase()

	return (
		<TableRow
			data={[
				`${specialismValue} Care Bed Score`,
				'',
				formatPercentage(ca, 0, 0),
				formatPercentage(region, 0, 0),
				formatPercentage(country, 0, 0),
			]}
		></TableRow>
	)
}

const NursingScoreRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}
	const idx = pop.years.length - 1

	const caPop75 = geos[0].ageRanges[0].values[idx]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[idx]
	const countryPop75 = geos[geos.length - 1].ageRanges[0].values[idx]

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].nursingBeds
	const regionBeds = stats[stats.length - 2].nursingBeds
	const countryBeds = stats[stats.length - 1].nursingBeds

	const caRate = caBeds / caPop75
	const regionRate = regionBeds / regionPop75
	const countryRate = countryBeds / countryPop75

	const { min, max } = elderlyCareScores.nursing

	const ca = ((caRate - min) / (max - min)) * 100
	const region = ((regionRate - min) / (max - min)) * 100
	const country = ((countryRate - min) / (max - min)) * 100
	return (
		<TableRow
			data={[
				'Nursing Opportunity Score',
				'',
				formatPercentage(100 - ca, 0, 0),
				formatPercentage(100 - region, 0, 0),
				formatPercentage(100 - country, 0, 0),
			]}
		></TableRow>
	)
}

const ResidentialScoreRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}

	const caPop75 = geos[0].ageRanges[0].values[0]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[0]
	const countryPop75 = geos[geos.length - 1].ageRanges[0].values[0]

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].residentialBeds
	const regionBeds = stats[stats.length - 2].residentialBeds
	const countryBeds = stats[stats.length - 1].residentialBeds

	const caRate = caBeds / caPop75
	const regionRate = regionBeds / regionPop75
	const countryRate = countryBeds / countryPop75

	const { min, max } = elderlyCareScores.residential

	const ca = ((caRate - min) / (max - min)) * 100
	const region = ((regionRate - min) / (max - min)) * 100
	const country = ((countryRate - min) / (max - min)) * 100
	return (
		<>
			<TableRow
				data={[
					'Residential Opportunity Score',
					'',
					formatPercentage(100 - ca, 0, 0),
					formatPercentage(100 - region, 0, 0),
					formatPercentage(100 - country, 0, 0),
				]}
			></TableRow>
		</>
	)
}

const ExtraCareScoreRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []

	const caPop75 = geos[0].ageRanges[0].values[0]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[0]
	const countryPop75 = geos[geos.length - 1].ageRanges[0].values[0]

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caDomCap = stats[0].domiciliaryCareWorkers * staffResidentRatio.homecare
	const regionDomCap = stats[stats.length - 2].domiciliaryCareWorkers * staffResidentRatio.homecare
	const countryDomCap = stats[stats.length - 1].domiciliaryCareWorkers * staffResidentRatio.homecare

	const caRate = caDomCap / caPop75
	const regionRate = regionDomCap / regionPop75
	const countryRate = countryDomCap / countryPop75

	const { min, max } = elderlyCareScores.domiciliary

	const ca = ((caRate - min) / (max - min)) * 100
	const region = ((regionRate - min) / (max - min)) * 100
	const country = ((countryRate - min) / (max - min)) * 100
	return (
		<TableRow
			data={[
				'Extra Care Opportunity Score',
				'',
				formatPercentage(100 - ca, 0, 0),
				formatPercentage(100 - region, 0, 0),
				formatPercentage(100 - country, 0, 0),
			]}
		></TableRow>
	)
}

const DementiaScoreRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { dementiaStatsByCa: dem } = useLazyLoadQuery<DementiaStatsByCaQuery>(DementiaStatsByCa, {
		oaCode,
		radius,
	})

	const { ca: caDementia, region: regionDementia, country: countryDementia } = getGeoValuesFromDataset(dem.estimated)

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.dementia,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caBeds = stats[0].numberOfBeds
	const regionBeds = stats[stats.length - 2].numberOfBeds
	const countryBeds = stats[stats.length - 1].numberOfBeds

	const caRate = caBeds / caDementia
	const regionRate = regionBeds / regionDementia
	const countryRate = countryBeds / countryDementia

	const { min, max } = elderlyCareScores.dementia

	const ca = ((caRate - min) / (max - min)) * 100
	const region = ((regionRate - min) / (max - min)) * 100
	const country = ((countryRate - min) / (max - min)) * 100
	return (
		<TableRow
			data={[
				'Dementia Opportunity Score',
				'',
				formatPercentage(100 - ca, 0, 0),
				formatPercentage(100 - region, 0, 0),
				formatPercentage(100 - country, 0, 0),
			]}
		></TableRow>
	)
}

const MigrantFactorRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { migrationStatsByCa: { datasets = [] } = {} } = useLazyLoadQuery<MigrationStatsByCaQuery>(MigrationStatsByCa, {
		oaCode,
		radius,
	})

	const caDomestic = datasets[0].values[0]
	const regionDomestic = datasets[datasets.length - 2].values[0]
	const countryDomestic = datasets[datasets.length - 1].values[0]

	const caInternational = datasets[0].values[1]
	const regionInternational = datasets[datasets.length - 2].values[1]
	const countryInternational = datasets[datasets.length - 1].values[1]

	const caTotal = caDomestic + caInternational
	const regionTotal = regionDomestic + regionInternational
	const countryTotal = countryDomestic + countryInternational

	return (
		<>
			<TableRow
				data={[
					'Year-to-Year Migration Indicator',
					'',
					formatPercentage(caTotal, 0, 1),
					formatPercentage(regionTotal, 0, 1),
					formatPercentage(countryTotal, 1),
				]}
			/>
			<TableRow
				data={[
					'UK Migration',
					'',
					formatPercentage(caDomestic, 0, 1),
					formatPercentage(regionDomestic, 0, 1),
					formatPercentage(countryDomestic, 1),
				]}
			/>
			<TableRow
				data={[
					'International Migration',
					'',
					formatPercentage(caInternational, 0, 1),
					formatPercentage(regionInternational, 0, 1),
					formatPercentage(countryInternational, 1),
				]}
			/>
		</>
	)
}

const HealthWorkforceRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 16,
		maxAge: 64,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}
	const idx = pop.years.length - 1

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	const caPop = geos[0].ageRanges[0].values[idx]
	const regionPop = geos[geos.length - 2].ageRanges[0].values[idx]
	const countryPop = geos[geos.length - 1].ageRanges[0].values[idx]

	const { populationByCa: elderly75Plus } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos75Plus = elderly75Plus?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}
	const idx75Plus = elderly75Plus.years.length - 1

	const caPop75Plus = geos75Plus[0].ageRanges[0].values[idx75Plus]
	const regionPop75Plus = geos75Plus[geos.length - 2].ageRanges[0].values[idx75Plus]
	const countryPop75Plus = geos75Plus[geos.length - 1].ageRanges[0].values[idx75Plus]

	const { industryDistributionsByCa: { datasets = [] } = {} } = useLazyLoadQuery<IndustryDistributionsByCaQuery>(
		IndustryDistributionsByCa,
		{ oaCode, radius },
	)

	const caWorkforceShare = datasets[0].values[7]
	const regionWorkforceShare = datasets[datasets.length - 2].values[7]
	const countryWorkforceShare = datasets[datasets.length - 1].values[7]

	const caWorkforce = (caPop * caWorkforceShare) / 100
	const regionWorkforce = (regionPop * regionWorkforceShare) / 100
	const countryWorkforce = (countryPop * countryWorkforceShare) / 100

	const caHealthPerElderly = (caWorkforce / caPop75Plus) * 100
	const regionHealthPerElderly = (regionWorkforce / regionPop75Plus) * 100
	const countryHealthPerElderly = (countryWorkforce / countryPop75Plus) * 100

	const caCareHomeWorkforce = stats[0].careHomeWorkers
	const regionCareHomeWorkforce = stats[stats.length - 2].careHomeWorkers
	const countryCareHomeWorkforce = stats[stats.length - 1].careHomeWorkers

	const caCareHomeWorkforcePerElderly = (caCareHomeWorkforce / caPop75Plus) * 100
	const regionCareHomeWorkforcePerElderly = (regionCareHomeWorkforce / regionPop75Plus) * 100
	const countryCareHomeWorkforcePerElderly = (countryCareHomeWorkforce / countryPop75Plus) * 100

	const caDomWorkforce = stats[0].domiciliaryCareWorkers
	const regionDomWorkforce = stats[stats.length - 2].domiciliaryCareWorkers
	const countryDomWorkforce = stats[stats.length - 1].domiciliaryCareWorkers

	const caDomWorkforcePerElderly = (caDomWorkforce / caPop75Plus) * 100
	const regionDomWorkforcePerElderly = (regionDomWorkforce / regionPop75Plus) * 100
	const countryDomWorkforcePerElderly = (countryDomWorkforce / countryPop75Plus) * 100

	const caTotalCareWorkforce = caCareHomeWorkforce + caDomWorkforce
	const regionTotalCareWorkforce = regionCareHomeWorkforce + regionDomWorkforce
	const countryTotalCareWorkforce = countryCareHomeWorkforce + countryDomWorkforce

	const caCareWorkersPerElderly = (caTotalCareWorkforce / caPop75Plus) * 100
	const regionCareWorkersPerElderly = (regionTotalCareWorkforce / regionPop75Plus) * 100
	const countryCareWorkersPerElderly = (countryTotalCareWorkforce / countryPop75Plus) * 100

	return (
		<>
			<TableRow
				data={[
					'Health Workforce',
					'',
					formatNumber(caWorkforce, 0, 0),
					formatNumber(regionWorkforce, 0, 0),
					formatNumber(countryWorkforce, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Health Workforce as % pf Working Population',
					'',
					formatPercentage(caWorkforceShare, 0, 1),
					formatPercentage(regionWorkforceShare, 0, 1),
					formatPercentage(countryWorkforceShare, 0, 1),
				]}
			></TableRow>
			<TableRow
				data={[
					'Health Workforce per 100 Elderly',
					'',
					formatNumber(caHealthPerElderly, 0, 1),
					formatNumber(regionHealthPerElderly, 0, 1),
					formatNumber(countryHealthPerElderly, 0, 1),
				]}
			></TableRow>
			<TableRow
				data={[
					'Care Home Workforce (incl. Nurses)',
					'',
					formatNumber(caCareHomeWorkforce, 0, 0),
					formatNumber(regionCareHomeWorkforce, 0, 0),
					formatNumber(countryCareHomeWorkforce, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Care Home Care Workers per 100 Elderly',
					'',
					formatNumber(caCareHomeWorkforcePerElderly, 0, 1),
					formatNumber(regionCareHomeWorkforcePerElderly, 0, 1),
					formatNumber(countryCareHomeWorkforcePerElderly, 0, 1),
				]}
			></TableRow>
			<TableRow
				data={[
					'Domiciliary Care Workforce',
					'',
					formatNumber(caDomWorkforce, 0, 0),
					formatNumber(regionDomWorkforce, 0, 0),
					formatNumber(countryDomWorkforce, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Domiciliary Care Workers per 100 Elderly',
					'',
					formatNumber(caDomWorkforcePerElderly, 0, 1),
					formatNumber(regionDomWorkforcePerElderly, 0, 1),
					formatNumber(countryDomWorkforcePerElderly, 0, 1),
				]}
			></TableRow>
			<TableRow
				data={[
					'Total Care Workforce',
					'',
					formatNumber(caTotalCareWorkforce, 0, 0),
					formatNumber(regionTotalCareWorkforce, 0, 0),
					formatNumber(countryTotalCareWorkforce, 0, 0),
				]}
			></TableRow>
			<TableRow
				data={[
					'Care Workers per 100 Elderly',
					'',
					formatNumber(caCareWorkersPerElderly, 0, 1),
					formatNumber(regionCareWorkersPerElderly, 0, 1),
					formatNumber(countryCareWorkersPerElderly, 0, 1),
				]}
			></TableRow>
		</>
	)
}

const CareWageRows: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { hourlyWagesByCa: { datasets = [] } = {} } = useLazyLoadQuery<HourlyWagesByCaQuery>(HourlyWagesByCa, {
		oaCode,
		radius,
	})

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	const caWageIndex = ca / country
	const regionWageIndex = region / country

	const countryPersonalCareWage = knightFrank.hourlyWages.residential
	const caPersonalCareWage = caWageIndex * countryPersonalCareWage
	const regionPersonalCareWage = regionWageIndex * countryPersonalCareWage

	const countryCareWage = knightFrank.hourlyWages.nursing
	const caCareWage = caWageIndex * countryCareWage
	const regionCareWage = regionWageIndex * countryCareWage

	const countryStaffingCost = knightFrank.annualStaffingCostPerBed.all
	const caStaffingCost = caWageIndex * countryStaffingCost
	const regionStaffingCost = regionWageIndex * countryStaffingCost

	const countryResidentialStaffingCost = knightFrank.annualStaffingCostPerBed.residential
	const caResidentialStaffingCost = caWageIndex * countryResidentialStaffingCost
	const regionResidentialStaffingCost = regionWageIndex * countryResidentialStaffingCost

	const countryNursingStaffingCost = knightFrank.annualStaffingCostPerBed.nursing
	const caNursingStaffingCost = caWageIndex * countryNursingStaffingCost
	const regionNursingStaffingCost = regionWageIndex * countryNursingStaffingCost

	return (
		<>
			<TableRow
				data={[
					'Personal Care Wage',
					'',
					formatCurrency(caPersonalCareWage),
					formatCurrency(regionPersonalCareWage),
					formatCurrency(countryPersonalCareWage),
				]}
			/>
			<TableRow
				data={[
					'Nursing Wage',
					'',
					formatCurrency(caCareWage),
					formatCurrency(regionCareWage),
					formatCurrency(countryCareWage),
				]}
			/>
			<TableRow
				data={[
					'Staffing cost per resident',
					'',
					formatCurrency(caStaffingCost, 0),
					formatCurrency(regionStaffingCost, 0),
					formatCurrency(countryStaffingCost, 0),
				]}
			/>
			<TableRow
				data={[
					'Staffing cost per resident - Residential',
					'',
					formatCurrency(countryResidentialStaffingCost, 0),
					formatCurrency(caResidentialStaffingCost, 0),
					formatCurrency(regionResidentialStaffingCost, 0),
				]}
			/>
			<TableRow
				data={[
					'Staffing cost per resident - Nursing',
					'',
					formatCurrency(caNursingStaffingCost, 0),
					formatCurrency(regionNursingStaffingCost, 0),
					formatCurrency(countryNursingStaffingCost, 0),
				]}
			/>
		</>
	)
}

interface BaProps extends CaRowProps {
	careHomeBeds: number
}

const BusinessAnalysisGroupRow: React.FC<BaProps> = ({ oaCode, radius, careHomeBeds }) => {
	const { careFundingByCa: careFunding } = useLazyLoadQuery<CareFundingByCaQuery>(CareFundingByCa, {
		careType: CareFundingType.careHome,
		oaCode,
		radius,
	})
	const { careFeeRatesByCa: careFees } = useLazyLoadQuery<CareFeeRatesByCaQuery>(CareFeeRatesByCa, {
		oaCode,
		radius,
	})

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData

	// Fee Analysis
	const caRating = stats[0].rating * 100

	const caMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + (knightFrank.cqcMargins.rangeCqcMargin * caRating) / 100
	const projectMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + knightFrank.cqcMargins.rangeCqcMargin * knightFrank.cqcMargins.projectRating
	const feeMultiplier = (1 - caMarginFraction) / (1 - projectMarginFraction)

	const caStateFundingShare = careFunding.StateFunded[0].values[0]
	const regionStateFundingShare = careFunding.StateFunded[careFunding.StateFunded.length - 2].values[0]
	const countryStateFundingShare = careFunding.StateFunded[careFunding.StateFunded.length - 1].values[0]

	const caLaFeeResidential = careFees.datasets[0].values[4]
	const regionLaFeeResidential = careFees.datasets[careFees.datasets.length - 2].values[4]
	const countryLaFeeResidential = careFees.datasets[careFees.datasets.length - 1].values[4]
	const projectLAFeeResidential = caLaFeeResidential * feeMultiplier

	const caPrivateFeeResidential = caLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	const regionPrivateFeeResidential = regionLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	const countryPrivateFeeResidential = knightFrank.privateBedFees.residential
	const projectPrivateFeeResidential = caPrivateFeeResidential * feeMultiplier

	const caLaFeeNursing = careFees.datasets[0].values[5]
	const regionLaFeeNursing = careFees.datasets[careFees.datasets.length - 2].values[5]
	const countryLaFeeNursing = careFees.datasets[careFees.datasets.length - 1].values[5]
	const projectLAFeeNursing = caLaFeeNursing * feeMultiplier

	const caPrivateFeeNursing = caLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate
	const regionPrivateFeeNursing = regionLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate
	const countryPrivateFeeNursing = knightFrank.privateBedFees.nursing
	const projectPrivateFeeNursing = caPrivateFeeNursing * feeMultiplier

	const caExpectedAvgResidentialFee =
		(caStateFundingShare * caLaFeeResidential) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeResidential
	const projectExpectedResidentialFee = caExpectedAvgResidentialFee * feeMultiplier
	const regionExpectedAvgResidentialFee =
		(regionStateFundingShare * regionLaFeeResidential) / 100 +
		(1 - regionStateFundingShare / 100) * regionPrivateFeeResidential
	const countryExpectedAvgResidentialFee =
		(countryStateFundingShare * countryLaFeeResidential) / 100 +
		(1 - countryStateFundingShare / 100) * countryPrivateFeeResidential

	const caExpectedAvgNursingFee =
		(caStateFundingShare * caLaFeeNursing) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeNursing
	const projectExpectedNursingFee = caExpectedAvgNursingFee * feeMultiplier
	const regionExpectedAvgNursingFee =
		(regionStateFundingShare * regionLaFeeNursing) / 100 + (1 - regionStateFundingShare / 100) * regionPrivateFeeNursing
	const countryExpectedAvgNursingFee =
		(countryStateFundingShare * countryLaFeeNursing) / 100 +
		(1 - countryStateFundingShare / 100) * countryPrivateFeeNursing

	const { hourlyWagesByCa: { datasets = [] } = {} } = useLazyLoadQuery<HourlyWagesByCaQuery>(HourlyWagesByCa, {
		oaCode,
		radius,
	})

	const { ca, region, country } = getGeoValuesFromDataset(datasets)

	// Profitability
	const caWageIndex = ca / country
	const regionWageIndex = region / country

	const countryStaffingCost_PC = knightFrank.annualStaffingCostPerBed.residential * knightFrank.occupancy.residential
	const caStaffingCost_PC = caWageIndex * countryStaffingCost_PC
	const regionStaffingCost_PC = regionWageIndex * countryStaffingCost_PC

	const countryStaffingCost_N = knightFrank.annualStaffingCostPerBed.nursing * knightFrank.occupancy.nursing
	const caStaffingCost_N = caWageIndex * countryStaffingCost_N
	const regionStaffingCost_N = regionWageIndex * countryStaffingCost_N

	const countryOperatingCost_PC = countryStaffingCost_PC + knightFrank.overheadCost.totalResidentialPerBed
	const caOperatingCost_PC = caStaffingCost_PC + knightFrank.overheadCost.totalResidentialPerBed
	const regionOperatingCost_PC = regionStaffingCost_PC + knightFrank.overheadCost.totalResidentialPerBed

	const countryOperatingCost_N = countryStaffingCost_N + knightFrank.overheadCost.totalNursingPerBed
	const caOperatingCost_N = caStaffingCost_N + knightFrank.overheadCost.totalNursingPerBed
	const regionOperatingCost_N = regionStaffingCost_N + knightFrank.overheadCost.totalNursingPerBed

	const caTurnover_PC = caExpectedAvgResidentialFee * 52
	const regionTurnover_PC = regionExpectedAvgResidentialFee * 52
	const countryTurnover_PC = countryExpectedAvgResidentialFee * 52
	const projectTurnover_PC = projectExpectedResidentialFee * 52

	const caTurnover_N = caExpectedAvgNursingFee * 52
	const regionTurnover_N = regionExpectedAvgNursingFee * 52
	const countryTurnover_N = countryExpectedAvgNursingFee * 52
	const projectTurnover_N = projectExpectedNursingFee * 52

	const caProfit_PC = caTurnover_PC - caOperatingCost_PC
	const regionProfit_PC = regionTurnover_PC - regionOperatingCost_PC
	const countryProfit_PC = countryTurnover_PC - countryOperatingCost_PC
	const projectProfit_PC = projectTurnover_PC - caOperatingCost_PC

	const caProfit_N = caTurnover_N - caOperatingCost_N
	const regionProfit_N = regionTurnover_N - regionOperatingCost_N
	const countryProfit_N = countryTurnover_N - countryOperatingCost_N
	const projectProfit_N = projectTurnover_N - caOperatingCost_N

	const caProfitMargin_PC = (caProfit_PC / caTurnover_PC) * 100
	const regionProfitMargin_PC = (regionProfit_PC / regionTurnover_PC) * 100
	const countryProfitMargin_PC = (countryProfit_PC / countryTurnover_PC) * 100
	const projectProfitMargin_PC = (projectProfit_PC / projectTurnover_PC) * 100

	const caProfitMargin_N = (caProfit_N / caTurnover_N) * 100
	const regionProfitMargin_N = (regionProfit_N / regionTurnover_N) * 100
	const countryProfitMargin_N = (countryProfit_N / countryTurnover_N) * 100
	const projectProfitMargin_N = (projectProfit_N / projectTurnover_N) * 100

	// Business Forecasts
	const occupancy = valuationProxies.occupancy

	// Nursing Home
	const projectTurnoverNursingHome = projectExpectedNursingFee * careHomeBeds * 52 * occupancy
	const projectOperatingCostNursingHome = caOperatingCost_N * careHomeBeds
	const projectEBITDARMNursingHome = projectTurnoverNursingHome - projectOperatingCostNursingHome
	const projectEBITDARMMarginNursingHome = (projectEBITDARMNursingHome / projectTurnoverNursingHome) * 100
	const projectRentNursingHome = projectEBITDARMNursingHome / valuationProxies.rentCover
	const projectRentCoverNursingHome = valuationProxies.rentCover

	const caTurnoverNursingHome = caExpectedAvgNursingFee * careHomeBeds * 52 * occupancy
	const caOperatingCostNursingHome = caOperatingCost_N * careHomeBeds
	const caEBITDARMNursingHome = caTurnoverNursingHome - caOperatingCostNursingHome
	const caEBITDARMMarginNursingHome = (caEBITDARMNursingHome / caTurnoverNursingHome) * 100
	const caRentNursingHome = caEBITDARMNursingHome / valuationProxies.rentCover
	const caRentCoverNursingHome = valuationProxies.rentCover

	const regionTurnoverNursingHome = regionExpectedAvgNursingFee * careHomeBeds * 52 * occupancy
	const regionOperatingCostNursingHome = regionOperatingCost_N * careHomeBeds
	const regionEBITDARMNursingHome = regionTurnoverNursingHome - regionOperatingCostNursingHome
	const regionEBITDARMMarginNursingHome = (regionEBITDARMNursingHome / regionTurnoverNursingHome) * 100
	const regionRentNursingHome = regionEBITDARMNursingHome / valuationProxies.rentCover
	const regionRentCoverNursingHome = valuationProxies.rentCover

	const countryTurnoverNursingHome = countryExpectedAvgNursingFee * careHomeBeds * 52 * occupancy
	const countryOperatingCostNursingHome = countryOperatingCost_N * careHomeBeds
	const countryEBITDARMNursingHome = countryTurnoverNursingHome - countryOperatingCostNursingHome
	const countryEBITDARMMarginNursingHome = (countryEBITDARMNursingHome / countryTurnoverNursingHome) * 100
	const countryRentNursingHome = countryEBITDARMNursingHome / valuationProxies.rentCover
	const countryRentCoverNursingHome = valuationProxies.rentCover

	// Residential Home
	const projectTurnoverResidentialHome = projectExpectedResidentialFee * careHomeBeds * 52 * occupancy
	const projectOperatingCostResidentialHome = caOperatingCost_PC * careHomeBeds
	const projectEBITDARMResidentialHome = projectTurnoverResidentialHome - projectOperatingCostResidentialHome
	const projectEBITDARMMarginResidentialHome = (projectEBITDARMResidentialHome / projectTurnoverResidentialHome) * 100
	const projectRentResidentialHome = projectEBITDARMResidentialHome / valuationProxies.rentCover
	const projectRentCoverResidentialHome = valuationProxies.rentCover

	const caTurnoverResidentialHome = caExpectedAvgResidentialFee * careHomeBeds * 52 * occupancy
	const caOperatingCostResidentialHome = caOperatingCost_PC * careHomeBeds
	const caEBITDARMResidentialHome = caTurnoverResidentialHome - caOperatingCostResidentialHome
	const caEBITDARMMarginResidentialHome = (caEBITDARMResidentialHome / caTurnoverResidentialHome) * 100
	const caRentResidentialHome = caEBITDARMResidentialHome / valuationProxies.rentCover
	const caRentCoverResidentialHome = valuationProxies.rentCover

	const regionTurnoverResidentialHome = regionExpectedAvgResidentialFee * careHomeBeds * 52 * occupancy
	const regionOperatingCostResidentialHome = regionOperatingCost_PC * careHomeBeds
	const regionEBITDARMResidentialHome = regionTurnoverResidentialHome - regionOperatingCostResidentialHome
	const regionEBITDARMMarginResidentialHome = (regionEBITDARMResidentialHome / regionTurnoverResidentialHome) * 100
	const regionRentResidentialHome = regionEBITDARMResidentialHome / valuationProxies.rentCover
	const regionRentCoverResidentialHome = valuationProxies.rentCover

	const countryTurnoverResidentialHome = countryExpectedAvgResidentialFee * careHomeBeds * 52 * occupancy
	const countryOperatingCostResidentialHome = countryOperatingCost_PC * careHomeBeds
	const countryEBITDARMResidentialHome = countryTurnoverResidentialHome - countryOperatingCostResidentialHome
	const countryEBITDARMMarginResidentialHome = (countryEBITDARMResidentialHome / countryTurnoverResidentialHome) * 100
	const countryRentResidentialHome = countryEBITDARMResidentialHome / valuationProxies.rentCover
	const countryRentCoverResidentialHome = valuationProxies.rentCover

	// Mixed: Residential and Nursing
	const projectTurnoverMixedHome =
		((1 - valuationProxies.nursingShareMixed) * projectExpectedResidentialFee +
			valuationProxies.nursingShareMixed * projectExpectedNursingFee) *
		careHomeBeds *
		52 *
		occupancy
	const projectStaffingCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * caStaffingCost_PC +
			valuationProxies.nursingShareMixed * caStaffingCost_N) *
		careHomeBeds
	const projectOverheadCostMixedHome = projectStaffingCostMixedHome * knightFrank.overheadCost.overheadStaffingRatio
	const projectOperatingCostMixedHome = projectStaffingCostMixedHome + projectOverheadCostMixedHome
	const projectEBITDARMMixedHome = projectTurnoverMixedHome - projectOperatingCostMixedHome
	const projectEBITDARMMarginMixedHome = (projectEBITDARMMixedHome / projectTurnoverMixedHome) * 100
	const projectRentMixedHome = projectEBITDARMMixedHome / valuationProxies.rentCover

	const caTurnoverMixedHome =
		((1 - valuationProxies.nursingShareMixed) * caExpectedAvgResidentialFee +
			valuationProxies.nursingShareMixed * caExpectedAvgNursingFee) *
		careHomeBeds *
		52 *
		occupancy
	const caStaffingCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * caStaffingCost_PC +
			valuationProxies.nursingShareMixed * caStaffingCost_N) *
		careHomeBeds
	const caOverheadCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * knightFrank.overheadCost.totalResidentialPerBed +
			valuationProxies.nursingShareMixed * knightFrank.overheadCost.totalNursingPerBed) *
		careHomeBeds
	const caOperatingCostMixedHome =
		(1 - valuationProxies.nursingShareMixed) * caOperatingCostResidentialHome +
		valuationProxies.nursingShareMixed * caOperatingCostNursingHome
	const caEBITDARMMixedHome = caTurnoverMixedHome - caOperatingCostMixedHome
	const caEBITDARMMarginMixedHome = (caEBITDARMMixedHome / caTurnoverMixedHome) * 100
	const caRentMixedHome = caEBITDARMMixedHome / valuationProxies.rentCover

	const regionTurnoverMixedHome =
		((1 - valuationProxies.nursingShareMixed) * regionExpectedAvgResidentialFee +
			valuationProxies.nursingShareMixed * regionExpectedAvgNursingFee) *
		careHomeBeds *
		52 *
		occupancy
	const regionStaffingCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * regionStaffingCost_PC +
			valuationProxies.nursingShareMixed * regionStaffingCost_N) *
		careHomeBeds
	const regionOverheadCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * knightFrank.overheadCost.totalResidentialPerBed +
			valuationProxies.nursingShareMixed * knightFrank.overheadCost.totalNursingPerBed) *
		careHomeBeds
	const regionOperatingCostMixedHome =
		(1 - valuationProxies.nursingShareMixed) * regionOperatingCostResidentialHome +
		valuationProxies.nursingShareMixed * regionOperatingCostNursingHome
	const regionEBITDARMMixedHome = regionTurnoverMixedHome - regionOperatingCostMixedHome
	const regionEBITDARMMarginMixedHome = (regionEBITDARMMixedHome / regionTurnoverMixedHome) * 100
	const regionRentMixedHome = regionEBITDARMMixedHome / valuationProxies.rentCover

	const countryTurnoverMixedHome =
		((1 - valuationProxies.nursingShareMixed) * countryExpectedAvgResidentialFee +
			valuationProxies.nursingShareMixed * countryExpectedAvgNursingFee) *
		careHomeBeds *
		52 *
		occupancy
	const countryStaffingCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * countryStaffingCost_PC +
			valuationProxies.nursingShareMixed * countryStaffingCost_N) *
		careHomeBeds
	const countryOverheadCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * knightFrank.overheadCost.totalResidentialPerBed +
			valuationProxies.nursingShareMixed * knightFrank.overheadCost.totalNursingPerBed) *
		careHomeBeds
	const countryOperatingCostMixedHome =
		(1 - valuationProxies.nursingShareMixed) * countryOperatingCostResidentialHome +
		valuationProxies.nursingShareMixed * countryOperatingCostNursingHome
	const countryEBITDARMMixedHome = countryTurnoverMixedHome - countryOperatingCostMixedHome
	const countryEBITDARMMarginMixedHome = (countryEBITDARMMixedHome / countryTurnoverMixedHome) * 100
	const countryRentMixedHome = countryEBITDARMMixedHome / valuationProxies.rentCover

	// Private Residential
	const projectTurnoverResidentialHomePrivate = projectPrivateFeeResidential * careHomeBeds * 52 * occupancy
	const projectOperatingCostResidentialHomePrivate = caOperatingCost_PC * careHomeBeds
	const projectEBITDARMResidentialHomePrivate =
		projectTurnoverResidentialHomePrivate - projectOperatingCostResidentialHomePrivate
	const projectEBITDARMMarginResidentialHomePrivate =
		(projectEBITDARMResidentialHomePrivate / projectTurnoverResidentialHomePrivate) * 100
	const projectRentResidentialHomePrivate = projectEBITDARMResidentialHomePrivate / valuationProxies.rentCover
	const projectRentCoverResidentialHomePrivate = valuationProxies.rentCover

	const caTurnoverResidentialHomePrivate = caPrivateFeeResidential * careHomeBeds * 52 * occupancy
	const caOperatingCostResidentialHomePrivate = caOperatingCost_PC * careHomeBeds
	const caEBITDARMResidentialHomePrivate = caTurnoverResidentialHomePrivate - caOperatingCostResidentialHomePrivate
	const caEBITDARMMarginResidentialHomePrivate =
		(caEBITDARMResidentialHomePrivate / caTurnoverResidentialHomePrivate) * 100
	const caRentResidentialHomePrivate = caEBITDARMResidentialHomePrivate / valuationProxies.rentCover
	const caRentCoverResidentialHomePrivate = valuationProxies.rentCover

	const regionTurnoverResidentialHomePrivate = regionPrivateFeeResidential * careHomeBeds * 52 * occupancy
	const regionOperatingCostResidentialHomePrivate = regionOperatingCost_PC * careHomeBeds
	const regionEBITDARMResidentialHomePrivate =
		regionTurnoverResidentialHomePrivate - regionOperatingCostResidentialHomePrivate
	const regionEBITDARMMarginResidentialHomePrivate =
		(regionEBITDARMResidentialHomePrivate / regionTurnoverResidentialHomePrivate) * 100
	const regionRentResidentialHomePrivate = regionEBITDARMResidentialHomePrivate / valuationProxies.rentCover
	const regionRentCoverResidentialHomePrivate = valuationProxies.rentCover

	const countryTurnoverResidentialHomePrivate = countryPrivateFeeResidential * careHomeBeds * 52 * occupancy
	const countryOperatingCostResidentialHomePrivate = countryOperatingCost_PC * careHomeBeds
	const countryEBITDARMResidentialHomePrivate =
		countryTurnoverResidentialHomePrivate - countryOperatingCostResidentialHomePrivate
	const countryEBITDARMMarginResidentialHomePrivate =
		(countryEBITDARMResidentialHomePrivate / countryTurnoverResidentialHomePrivate) * 100
	const countryRentResidentialHomePrivate = countryEBITDARMResidentialHomePrivate / valuationProxies.rentCover
	const countryRentCoverResidentialHomePrivate = valuationProxies.rentCover

	// Private Nursing
	const projectTurnoverNursingHomePrivate = projectPrivateFeeNursing * careHomeBeds * 52 * occupancy
	const projectOperatingCostNursingHomePrivate = caOperatingCost_N * careHomeBeds
	const projectEBITDARMNursingHomePrivate = projectTurnoverNursingHomePrivate - projectOperatingCostNursingHomePrivate
	const projectEBITDARMMarginNursingHomePrivate =
		(projectEBITDARMNursingHomePrivate / projectTurnoverNursingHomePrivate) * 100
	const projectRentNursingHomePrivate = projectEBITDARMNursingHomePrivate / valuationProxies.rentCover
	const projectRentCoverNursingHomePrivate = valuationProxies.rentCover

	const caTurnoverNursingHomePrivate = caPrivateFeeNursing * careHomeBeds * 52 * occupancy
	const caOperatingCostNursingHomePrivate = caOperatingCost_N * careHomeBeds
	const caEBITDARMNursingHomePrivate = caTurnoverNursingHomePrivate - caOperatingCostNursingHomePrivate
	const caEBITDARMMarginNursingHomePrivate = (caEBITDARMNursingHomePrivate / caTurnoverNursingHomePrivate) * 100
	const caRentNursingHomePrivate = caEBITDARMNursingHomePrivate / valuationProxies.rentCover
	const caRentCoverNursingHomePrivate = valuationProxies.rentCover

	const regionTurnoverNursingHomePrivate = regionPrivateFeeNursing * careHomeBeds * 52 * occupancy
	const regionOperatingCostNursingHomePrivate = regionOperatingCost_N * careHomeBeds
	const regionEBITDARMNursingHomePrivate = regionTurnoverNursingHomePrivate - regionOperatingCostNursingHomePrivate
	const regionEBITDARMMarginNursingHomePrivate =
		(regionEBITDARMNursingHomePrivate / regionTurnoverNursingHomePrivate) * 100
	const regionRentNursingHomePrivate = regionEBITDARMNursingHomePrivate / valuationProxies.rentCover
	const regionRentCoverNursingHomePrivate = valuationProxies.rentCover

	const countryTurnoverNursingHomePrivate = countryPrivateFeeNursing * careHomeBeds * 52 * occupancy
	const countryOperatingCostNursingHomePrivate = countryOperatingCost_N * careHomeBeds
	const countryEBITDARMNursingHomePrivate = countryTurnoverNursingHomePrivate - countryOperatingCostNursingHomePrivate
	const countryEBITDARMMarginNursingHomePrivate =
		(countryEBITDARMNursingHomePrivate / countryTurnoverNursingHomePrivate) * 100
	const countryRentNursingHomePrivate = countryEBITDARMNursingHomePrivate / valuationProxies.rentCover
	const countryRentCoverNursingHomePrivate = valuationProxies.rentCover

	return (
		<>
			<TableRow
				data={[
					'Project Fee Uplift Multiplier based on CQC Rating average distance to project rating of Good-Outstanding',
					formatNumber(feeMultiplier, 0, 3),
					'1',
					'1',
					'1',
				]}
			/>
			<TableRow
				data={[
					'Local Authority Funding Share',
					'',
					formatPercentage(caStateFundingShare, 0, 0),
					formatPercentage(regionStateFundingShare, 0, 0),
					formatPercentage(countryStateFundingShare, 0, 0),
				]}
			/>
			<TableRow
				data={[
					'Residential Care - Local Authority Fee',
					formatCurrency(projectLAFeeResidential, 0),
					formatCurrency(caLaFeeResidential, 0),
					formatCurrency(regionLaFeeResidential, 0),
					formatCurrency(countryLaFeeResidential, 0),
				]}
			/>
			<TableRow
				data={[
					'Residential Care - Private Fee (Est. Average)',
					formatCurrency(caPrivateFeeResidential * feeMultiplier, 0),
					formatCurrency(caPrivateFeeResidential, 0),
					formatCurrency(regionPrivateFeeResidential, 0),
					formatCurrency(countryPrivateFeeResidential, 0),
				]}
			/>
			<TableRow
				data={[
					'Nursing Care - Local Authority Fee',
					formatCurrency(projectLAFeeNursing, 0),
					formatCurrency(caLaFeeNursing, 0),
					formatCurrency(regionLaFeeNursing, 0),
					formatCurrency(countryLaFeeNursing, 0),
				]}
			/>
			<TableRow
				data={[
					'Nursing Care - Private Fee (Est. Average)',
					formatCurrency(caPrivateFeeNursing * feeMultiplier, 0),
					formatCurrency(caPrivateFeeNursing, 0),
					formatCurrency(regionPrivateFeeNursing, 0),
					formatCurrency(countryPrivateFeeNursing, 0),
				]}
			/>
			<TableRow
				data={[
					'Residential Care - Expected Average Income Fee',
					formatCurrency(projectExpectedResidentialFee, 0),
					formatCurrency(caExpectedAvgResidentialFee, 0),
					formatCurrency(regionExpectedAvgResidentialFee, 0),
					formatCurrency(countryExpectedAvgResidentialFee, 0),
				]}
			/>
			<TableRow
				data={[
					'Nursing Care - Expected Average Income Fee',
					formatCurrency(projectExpectedNursingFee, 0),
					formatCurrency(caExpectedAvgNursingFee, 0),
					formatCurrency(regionExpectedAvgNursingFee, 0),
					formatCurrency(countryExpectedAvgNursingFee, 0),
				]}
			/>
			<TableRow
				data={[
					'Project Fee Uplift Multiplier based on CQC Rating average distance to project rating of Good-Outstanding',
					formatNumber(feeMultiplier, 0, 3),
					'',
				]}
			/>

			<Header data={['PROFITABILITY Per Resident', '', '', '', '']}></Header>
			<TableRow
				data={[
					'Turnover per resident - Residential',
					formatCurrency(projectTurnover_PC, 0),
					formatCurrency(caTurnover_PC, 0),
					formatCurrency(regionTurnover_PC, 0),
					formatCurrency(countryTurnover_PC, 0),
				]}
			/>
			<TableRow
				data={[
					'Staffing Cost per resident - Residential (incl. agency costs)',
					formatCurrency(caStaffingCost_PC, 0),
					formatCurrency(caStaffingCost_PC, 0),
					formatCurrency(regionStaffingCost_PC, 0),
					formatCurrency(countryStaffingCost_PC, 0),
				]}
			/>
			<TableRow
				data={[
					'Overheads (Property maintenance, utilities, food, etc.) per resident - Residential',
					formatCurrency(knightFrank.overheadCost.totalResidentialPerBed, 0),
					formatCurrency(knightFrank.overheadCost.totalResidentialPerBed, 0),
					formatCurrency(knightFrank.overheadCost.totalResidentialPerBed, 0),
					formatCurrency(knightFrank.overheadCost.totalResidentialPerBed, 0),
				]}
			/>
			<TableRow
				data={[
					'Overheads % (Property maintenance, utilities, food, etc.) per resident - Residential',
					formatPercentage((knightFrank.overheadCost.totalResidentialPerBed / caStaffingCost_PC) * 100, 0, 0),
					formatPercentage((knightFrank.overheadCost.totalResidentialPerBed / caStaffingCost_PC) * 100, 0, 0),
					formatPercentage((knightFrank.overheadCost.totalResidentialPerBed / regionStaffingCost_PC) * 100, 0, 0),
					formatPercentage((knightFrank.overheadCost.totalResidentialPerBed / countryStaffingCost_PC) * 100, 0, 0),
				]}
			/>
			<TableRow
				data={[
					'Operating Cost per resident - Residential',
					formatCurrency(caOperatingCost_PC, 0),
					formatCurrency(caOperatingCost_PC, 0),
					formatCurrency(regionOperatingCost_PC, 0),
					formatCurrency(countryOperatingCost_PC, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM per resident - Residential',
					formatCurrency(projectProfit_PC, 0),
					formatCurrency(caProfit_PC, 0),
					formatCurrency(regionProfit_PC, 0),
					formatCurrency(countryProfit_PC, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin per resident - Residential',
					formatPercentage(projectProfitMargin_PC, 0),
					formatPercentage(caProfitMargin_PC, 0),
					formatPercentage(regionProfitMargin_PC, 0),
					formatPercentage(countryProfitMargin_PC, 0),
				]}
			/>
			<TableRow
				data={[
					'Turnover per resident - Nursing',
					formatCurrency(projectTurnover_N, 0),
					formatCurrency(caTurnover_N, 0),
					formatCurrency(regionTurnover_N, 0),
					formatCurrency(countryTurnover_N, 0),
				]}
			/>
			<TableRow
				data={[
					'Overheads (Property maintenance, utilities, food, etc.) per resident - Nursing',
					formatCurrency(knightFrank.overheadCost.totalNursingPerBed, 0),
					formatCurrency(knightFrank.overheadCost.totalNursingPerBed, 0),
					formatCurrency(knightFrank.overheadCost.totalNursingPerBed, 0),
					formatCurrency(knightFrank.overheadCost.totalNursingPerBed, 0),
				]}
			/>
			<TableRow
				data={[
					'Overheads % (Property maintenance, utilities, food, etc.) per resident - Nursing',
					formatPercentage((knightFrank.overheadCost.totalNursingPerBed / caStaffingCost_N) * 100, 0, 2),
					formatPercentage((knightFrank.overheadCost.totalNursingPerBed / caStaffingCost_N) * 100, 0, 2),
					formatPercentage((knightFrank.overheadCost.totalNursingPerBed / regionStaffingCost_N) * 100, 0, 2),
					formatPercentage((knightFrank.overheadCost.totalNursingPerBed / countryStaffingCost_N) * 100, 0, 2),
				]}
			/>
			<TableRow
				data={[
					'Operating Cost per resident - Nursing',
					formatCurrency(caOperatingCost_N, 0),
					formatCurrency(caOperatingCost_N, 0),
					formatCurrency(regionOperatingCost_N, 0),
					formatCurrency(countryOperatingCost_N, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM per resident - Nursing',
					formatCurrency(projectProfit_N, 0),
					formatCurrency(caProfit_N, 0),
					formatCurrency(regionProfit_N, 0),
					formatCurrency(countryProfit_N, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin per resident - Nursing',
					formatPercentage(projectProfitMargin_N, 0),
					formatPercentage(caProfitMargin_N, 0),
					formatPercentage(regionProfitMargin_N, 0),
					formatPercentage(countryProfitMargin_N, 0),
				]}
			/>
			<Header
				data={[
					'Business Forecast - Nursing Care Home',
					'Project',
					`${careHomeBeds} beds | Occupancy: ${occupancy * 100}%`,
					``,
					'',
				]}
			></Header>
			<TableRow
				data={[
					'Turnover',
					formatCurrency(projectTurnoverNursingHome, 0),
					formatCurrency(caTurnoverNursingHome, 0),
					formatCurrency(regionTurnoverNursingHome, 0),
					formatCurrency(countryTurnoverNursingHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Operating Cost',
					formatCurrency(projectOperatingCostNursingHome, 0),
					formatCurrency(caOperatingCostNursingHome, 0),
					formatCurrency(regionOperatingCostNursingHome, 0),
					formatCurrency(countryOperatingCostNursingHome, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM',
					formatCurrency(projectEBITDARMNursingHome, 0),
					formatCurrency(caEBITDARMNursingHome, 0),
					formatCurrency(regionEBITDARMNursingHome, 0),
					formatCurrency(countryEBITDARMNursingHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin',
					formatPercentage(projectEBITDARMMarginNursingHome, 0),
					formatPercentage(caEBITDARMMarginNursingHome, 0),
					formatPercentage(regionEBITDARMMarginNursingHome, 0),
					formatPercentage(countryEBITDARMMarginNursingHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent',
					formatCurrency(projectRentNursingHome, 0),
					formatCurrency(caRentNursingHome, 0),
					formatCurrency(regionRentNursingHome, 0),
					formatCurrency(countryRentNursingHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent Cover',
					formatNumber(projectRentCoverNursingHome, 0, 1),
					formatNumber(caRentCoverNursingHome, 0, 1),
					formatNumber(regionRentCoverNursingHome, 0, 1),
					formatNumber(countryRentCoverNursingHome, 0, 1),
				]}
			/>
			<Header
				data={[
					'Business Forecast - Residential Care Home',
					'Project',
					`${careHomeBeds} beds | Occupancy: ${occupancy * 100}%`,
					'',
					'',
				]}
			></Header>
			<TableRow
				data={[
					'Turnover',
					formatCurrency(projectTurnoverResidentialHome, 0),
					formatCurrency(caTurnoverResidentialHome, 0),
					formatCurrency(regionTurnoverResidentialHome, 0),
					formatCurrency(countryTurnoverResidentialHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Operating Cost',
					formatCurrency(projectOperatingCostResidentialHome, 0),
					formatCurrency(caOperatingCostResidentialHome, 0),
					formatCurrency(regionOperatingCostResidentialHome, 0),
					formatCurrency(countryOperatingCostResidentialHome, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM',
					formatCurrency(projectEBITDARMResidentialHome, 0),
					formatCurrency(caEBITDARMResidentialHome, 0),
					formatCurrency(regionEBITDARMResidentialHome, 0),
					formatCurrency(countryEBITDARMResidentialHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin',
					formatPercentage(projectEBITDARMMarginResidentialHome, 0),
					formatPercentage(caEBITDARMMarginResidentialHome, 0),
					formatPercentage(regionEBITDARMMarginResidentialHome, 0),
					formatPercentage(countryEBITDARMMarginResidentialHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent',
					formatCurrency(projectRentResidentialHome, 0),
					formatCurrency(caRentResidentialHome, 0),
					formatCurrency(regionRentResidentialHome, 0),
					formatCurrency(countryRentResidentialHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent Cover',
					formatNumber(projectRentCoverResidentialHome, 0, 1),
					formatNumber(caRentCoverResidentialHome, 0, 1),
					formatNumber(regionRentCoverResidentialHome, 0, 1),
					formatNumber(countryRentCoverResidentialHome, 0, 1),
				]}
			/>
			<Header
				data={[
					'Business Forecast - Mixed Care Home - Residential (50%) and Nursing (50%)',
					'Project',
					`${careHomeBeds} beds | Occupancy: ${occupancy * 100}%`,
					'',
					'',
				]}
			></Header>
			<TableRow
				data={[
					'Turnover',
					formatCurrency(projectTurnoverMixedHome, 0),
					formatCurrency(caTurnoverMixedHome, 0),
					formatCurrency(regionTurnoverMixedHome, 0),
					formatCurrency(countryTurnoverMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Staffing Cost',
					formatCurrency(projectStaffingCostMixedHome, 0),
					formatCurrency(caStaffingCostMixedHome, 0),
					formatCurrency(regionStaffingCostMixedHome, 0),
					formatCurrency(countryStaffingCostMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Overhead Cost',
					formatCurrency(projectOverheadCostMixedHome, 0),
					formatCurrency(caOverheadCostMixedHome, 0),
					formatCurrency(regionOverheadCostMixedHome, 0),
					formatCurrency(countryOverheadCostMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Overhead Cost %',
					formatPercentage((projectOverheadCostMixedHome / projectStaffingCostMixedHome) * 100, 0, 1),
				]}
			/>

			<TableRow
				data={[
					'Operating Cost',
					formatCurrency(projectOperatingCostMixedHome, 0),
					formatCurrency(caOperatingCostMixedHome, 0),
					formatCurrency(regionOperatingCostMixedHome, 0),
					formatCurrency(countryOperatingCostMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM',
					formatCurrency(projectEBITDARMMixedHome, 0),
					formatCurrency(caEBITDARMMixedHome, 0),
					formatCurrency(regionEBITDARMMixedHome, 0),
					formatCurrency(countryEBITDARMMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin',
					formatPercentage(projectEBITDARMMarginMixedHome, 0),
					formatPercentage(caEBITDARMMarginMixedHome, 0),
					formatPercentage(regionEBITDARMMarginMixedHome, 0),
					formatPercentage(countryEBITDARMMarginMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent',
					formatCurrency(projectRentMixedHome, 0),
					formatCurrency(caRentMixedHome, 0),
					formatCurrency(regionRentMixedHome, 0),
					formatCurrency(countryRentMixedHome, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent Cover',
					formatNumber(valuationProxies.rentCover, 0, 1),
					formatNumber(valuationProxies.rentCover, 0, 1),
					formatNumber(valuationProxies.rentCover, 0, 1),
					formatNumber(valuationProxies.rentCover, 0, 1),
				]}
			/>
			<Header
				data={[
					'Business Forecast - Residential Care Home - Private',
					'Project',
					`${careHomeBeds} beds | Occupancy: ${occupancy * 100}%`,
					'',
					'',
				]}
			></Header>
			<TableRow
				data={[
					'Turnover',
					formatCurrency(projectTurnoverResidentialHomePrivate),
					formatCurrency(caTurnoverResidentialHomePrivate, 0),
					formatCurrency(regionTurnoverResidentialHomePrivate, 0),
					formatCurrency(countryTurnoverResidentialHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Operating Cost',
					formatCurrency(projectOperatingCostResidentialHomePrivate, 0),
					formatCurrency(caOperatingCostResidentialHomePrivate, 0),
					formatCurrency(regionOperatingCostResidentialHomePrivate, 0),
					formatCurrency(countryOperatingCostResidentialHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM',
					formatCurrency(projectEBITDARMResidentialHomePrivate, 0),
					formatCurrency(caEBITDARMResidentialHomePrivate, 0),
					formatCurrency(regionEBITDARMResidentialHomePrivate, 0),
					formatCurrency(countryEBITDARMResidentialHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin',
					formatPercentage(projectEBITDARMMarginResidentialHomePrivate),
					formatPercentage(caEBITDARMMarginResidentialHomePrivate, 0),
					formatPercentage(regionEBITDARMMarginResidentialHomePrivate, 0),
					formatPercentage(countryEBITDARMMarginResidentialHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent',
					formatCurrency(projectRentResidentialHomePrivate, 0),
					formatCurrency(caRentResidentialHomePrivate, 0),
					formatCurrency(regionRentResidentialHomePrivate, 0),
					formatCurrency(countryRentResidentialHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent Cover',
					formatNumber(projectRentCoverResidentialHomePrivate),
					formatNumber(caRentCoverResidentialHomePrivate, 0, 1),
					formatNumber(regionRentCoverResidentialHomePrivate, 0, 1),
					formatNumber(countryRentCoverResidentialHomePrivate, 0, 1),
				]}
			/>
			<Header
				data={[
					'Business Forecast - Nursing Care Home - Private',
					'Project',
					`${careHomeBeds} beds | Occupancy: ${occupancy * 100}%`,
					'',
					'',
				]}
			></Header>
			<TableRow
				data={[
					'Turnover',
					formatCurrency(projectTurnoverNursingHomePrivate, 0),
					formatCurrency(caTurnoverNursingHomePrivate, 0),
					formatCurrency(regionTurnoverNursingHomePrivate, 0),
					formatCurrency(countryTurnoverNursingHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Operating Cost',
					formatCurrency(projectOperatingCostNursingHomePrivate, 0),
					formatCurrency(caOperatingCostNursingHomePrivate, 0),
					formatCurrency(regionOperatingCostNursingHomePrivate, 0),
					formatCurrency(countryOperatingCostNursingHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'EBITDARM',
					formatCurrency(projectEBITDARMNursingHomePrivate, 0),
					formatCurrency(caEBITDARMNursingHomePrivate, 0),
					formatCurrency(regionEBITDARMNursingHomePrivate, 0),
					formatCurrency(countryEBITDARMNursingHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Margin',
					formatPercentage(projectEBITDARMMarginNursingHomePrivate),
					formatPercentage(caEBITDARMMarginNursingHomePrivate, 0),
					formatPercentage(regionEBITDARMMarginNursingHomePrivate, 0),
					formatPercentage(countryEBITDARMMarginNursingHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent',
					formatCurrency(projectRentNursingHomePrivate, 0),
					formatCurrency(caRentNursingHomePrivate, 0),
					formatCurrency(regionRentNursingHomePrivate, 0),
					formatCurrency(countryRentNursingHomePrivate, 0),
				]}
			/>
			<TableRow
				data={[
					'Rent Cover',
					formatNumber(projectRentCoverNursingHomePrivate),
					formatNumber(caRentCoverNursingHomePrivate, 0, 1),
					formatNumber(regionRentCoverNursingHomePrivate, 0, 1),
					formatNumber(countryRentCoverNursingHomePrivate, 0, 1),
				]}
			/>
		</>
	)
}

const MostViableUseCaseRow: React.FC<CaRowProps> = ({ oaCode, radius }) => {
	const { populationByCa: pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return <TableRow data={['FAILED ^', '', '', '', '']} />
	}
	const idx = pop.years.length - 1

	const caPop75 = geos[0].ageRanges[0].values[idx]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[idx]
	const countryPop75 = geos[geos.length - 1].ageRanges[0].values[idx]

	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	// Dementia Beds & Opportunities
	const { dementiaStatsByCa: dem } = useLazyLoadQuery<DementiaStatsByCaQuery>(DementiaStatsByCa, {
		oaCode,
		radius,
	})

	const { ca: caDementia, region: regionDementia, country: countryDementia } = getGeoValuesFromDataset(dem.estimated)

	const { careStatsByCa: dcsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.dementia,
		oaCode,
		radius,
	})

	// Nursing Beds & Opportunities
	const caBeds_N = ecsData.stats[0].nursingBeds
	const regionBeds_N = ecsData.stats[ecsData.stats.length - 2].nursingBeds
	const countryBeds_N = ecsData.stats[ecsData.stats.length - 1].nursingBeds

	const caRate_N = caBeds_N / caPop75
	const regionRate_N = regionBeds_N / regionPop75
	const countryRate_N = countryBeds_N / countryPop75

	const min_N = elderlyCareScores.nursing.min
	const max_N = elderlyCareScores.nursing.max

	const caOpportunity_N = 100 - ((caRate_N - min_N) / (max_N - min_N)) * 100
	const regionOpportunity_N = 100 - ((regionRate_N - min_N) / (max_N - min_N)) * 100
	const countryOpportunity_N = 100 - ((countryRate_N - min_N) / (max_N - min_N)) * 100

	// Residential Beds & Opportunities
	const caBeds_PC = ecsData.stats[0].residentialBeds
	const regionBeds_PC = ecsData.stats[ecsData.stats.length - 2].residentialBeds
	const countryBeds_PC = ecsData.stats[ecsData.stats.length - 1].residentialBeds

	const caRate_PC = caBeds_PC / caPop75
	const regionRate_PC = regionBeds_PC / regionPop75
	const countryRate_PC = countryBeds_PC / countryPop75

	const min_PC = elderlyCareScores.residential.min
	const max_PC = elderlyCareScores.residential.max

	const caOpportunity_PC = 100 - ((caRate_PC - min_PC) / (max_PC - min_PC)) * 100
	const regionOpportunity_PC = 100 - ((regionRate_PC - min_PC) / (max_PC - min_PC)) * 100
	const countryOpportunity_PC = 100 - ((countryRate_PC - min_PC) / (max_PC - min_PC)) * 100

	// Home Care Capacity & Opportunities
	const caDomCap = ecsData.stats[0].domiciliaryCareWorkers * staffResidentRatio.homecare
	const regionDomCap = ecsData.stats[ecsData.stats.length - 2].domiciliaryCareWorkers * staffResidentRatio.homecare
	const countryDomCap = ecsData.stats[ecsData.stats.length - 1].domiciliaryCareWorkers * staffResidentRatio.homecare

	const caRate_H = caDomCap / caPop75
	const regionRate_H = regionDomCap / regionPop75
	const countryRate_H = countryDomCap / countryPop75

	const min_H = elderlyCareScores.domiciliary.min
	const max_H = elderlyCareScores.domiciliary.max

	const caOpportunity_H = 100 - ((caRate_H - min_H) / (max_H - min_H)) * 100
	const regionOpportunity_H = 100 - ((regionRate_H - min_H) / (max_H - min_H)) * 100
	const countryOpportunity_H = 100 - ((countryRate_H - min_H) / (max_H - min_H)) * 100

	const caBeds_D = dcsData.stats[0].numberOfBeds
	const regionBeds_D = dcsData.stats[dcsData.stats.length - 2].numberOfBeds
	const countryBeds_D = dcsData.stats[dcsData.stats.length - 1].numberOfBeds

	const caRate_D = caBeds_D / caDementia
	const regionRate_D = regionBeds_D / regionDementia
	const countryRate_D = countryBeds_D / countryDementia

	const { min, max } = elderlyCareScores.dementia

	const caOpportunity_D = 100 - ((caRate_D - min) / (max - min)) * 100
	const regionOpportunity_D = 100 - ((regionRate_D - min) / (max - min)) * 100
	const countryOpportunity_D = 100 - ((countryRate_D - min) / (max - min)) * 100

	// Define thresholds
	const significantDiff = 10
	const extraCareThreshold = 50 // Adjust if needed

	// For CA
	let caBestOpportunity = '-'
	if (caOpportunity_N >= 50 || caOpportunity_PC >= 50) {
		if (caOpportunity_N >= 50 && caOpportunity_PC < 50) {
			caBestOpportunity = 'Nursing Care Home'
		} else if (caOpportunity_PC >= 50 && caOpportunity_N < 50) {
			caBestOpportunity = 'Residential Care Home'
		} else {
			if (Math.abs(caOpportunity_N - caOpportunity_PC) > significantDiff) {
				caBestOpportunity = caOpportunity_N > caOpportunity_PC ? 'Nursing Care Home' : 'Residential Care Home'
			} else {
				caBestOpportunity = 'Nursing and Residential Care Home'
			}
		}
	}
	// Incorporate Extra Care (Home Care)
	if (caOpportunity_H >= extraCareThreshold) {
		if (caBestOpportunity === '-') {
			caBestOpportunity = 'Extra Care'
		} else {
			caBestOpportunity += ' & Extra Care'
		}
	}
	// Incorporate Dementia (if opportunity is high enough)
	if (caOpportunity_D >= 60) {
		if (caBestOpportunity.includes('Nursing Care Home')) {
			caBestOpportunity = caBestOpportunity.replace('Nursing Care Home', 'Dementia Nursing Care Home')
		} else if (caBestOpportunity.includes('Residential Care Home')) {
			caBestOpportunity = caBestOpportunity.replace('Residential Care Home', 'Dementia Residential Care Home')
		} else if (caBestOpportunity.includes('Nursing and Residential Care Home')) {
			caBestOpportunity = caBestOpportunity.replace(
				'Nursing and Residential Care Home',
				'Nursing and Residential Dementia Care Home',
			)
		} else if (caBestOpportunity.includes('Extra Care')) {
			caBestOpportunity = 'Dementia Extra Care'
		}
	}

	// For Region
	let regionBestOpportunity = '-'
	if (regionOpportunity_N >= 50 || regionOpportunity_PC >= 50) {
		if (regionOpportunity_N >= 50 && regionOpportunity_PC < 50) {
			regionBestOpportunity = 'Nursing Care Home'
		} else if (regionOpportunity_PC >= 50 && regionOpportunity_N < 50) {
			regionBestOpportunity = 'Residential Care Home'
		} else {
			if (Math.abs(regionOpportunity_N - regionOpportunity_PC) > significantDiff) {
				regionBestOpportunity =
					regionOpportunity_N > regionOpportunity_PC ? 'Nursing Care Home' : 'Residential Care Home'
			} else {
				regionBestOpportunity = 'Nursing and Residential Care Home'
			}
		}
	}
	// Extra Care for Region
	if (regionOpportunity_H >= extraCareThreshold) {
		if (regionBestOpportunity === '-') {
			regionBestOpportunity = 'Extra Care'
		} else {
			regionBestOpportunity += ' & Extra Care'
		}
	}
	// Dementia for Region
	if (regionOpportunity_D >= 60) {
		if (regionBestOpportunity.includes('Nursing Care Home')) {
			regionBestOpportunity = regionBestOpportunity.replace('Nursing Care Home', 'Dementia Nursing Care Home')
		} else if (regionBestOpportunity.includes('Residential Care Home')) {
			regionBestOpportunity = regionBestOpportunity.replace('Residential Care Home', 'Dementia Residential Care Home')
		} else if (regionBestOpportunity.includes('Nursing and Residential Care Home')) {
			regionBestOpportunity = regionBestOpportunity.replace(
				'Nursing and Residential Care Home',
				'Nursing and Residential Dementia Care Home',
			)
		} else if (regionBestOpportunity.includes('Extra Care')) {
			regionBestOpportunity = 'Dementia Extra Care'
		}
	}

	// For Country
	let countryBestOpportunity = '-'
	if (countryOpportunity_N >= 50 || countryOpportunity_PC >= 50) {
		if (countryOpportunity_N >= 50 && countryOpportunity_PC < 50) {
			countryBestOpportunity = 'Nursing Care Home'
		} else if (countryOpportunity_PC >= 50 && countryOpportunity_N < 50) {
			countryBestOpportunity = 'Residential Care Home'
		} else {
			if (Math.abs(countryOpportunity_N - countryOpportunity_PC) > significantDiff) {
				countryBestOpportunity =
					countryOpportunity_N > countryOpportunity_PC ? 'Nursing Care Home' : 'Residential Care Home'
			} else {
				countryBestOpportunity = 'Nursing and Residential Care'
			}
		}
	}
	// Extra Care for Country
	if (countryOpportunity_H >= extraCareThreshold) {
		if (countryBestOpportunity === '-') {
			countryBestOpportunity = 'Extra Care'
		} else {
			countryBestOpportunity += ' & Extra Care'
		}
	}
	// Dementia for Country
	if (countryOpportunity_D >= 60) {
		if (countryBestOpportunity.includes('Nursing Care Home')) {
			countryBestOpportunity = countryBestOpportunity.replace('Nursing Care Home', 'Dementia Nursing Care')
		} else if (countryBestOpportunity.includes('Residential Care Home')) {
			countryBestOpportunity = countryBestOpportunity.replace('Residential Care Home', 'Dementia Residential Care')
		} else if (countryBestOpportunity.includes('Nursing and Residential Care')) {
			countryBestOpportunity = countryBestOpportunity.replace(
				'Nursing and Residential Care',
				'Nursing and Residential Dementia Care',
			)
		} else if (countryBestOpportunity.includes('Extra Care')) {
			countryBestOpportunity = 'Dementia Extra Care'
		}
	}

	// For the table display
	const caOpportunityDisplay = caBestOpportunity
	const regionOpportunityDisplay = regionBestOpportunity
	const countryOpportunityDisplay = countryBestOpportunity

	return (
		<>
			<TableRow
				data={['Most Viable Use Case', '', caOpportunityDisplay, regionOpportunityDisplay, countryOpportunityDisplay]}
			/>
			<TableRow
				data={[
					'Nursing Opportunity Score',
					'',
					formatPercentage(caOpportunity_N, 0, 0),
					formatPercentage(regionOpportunity_N, 0, 0),
					formatPercentage(countryOpportunity_N, 0, 0),
				]}
			/>
			<TableRow
				data={[
					'Residential Opportunity Score',
					'',
					formatPercentage(caOpportunity_PC, 0, 0),
					formatPercentage(regionOpportunity_PC, 0, 0),
					formatPercentage(countryOpportunity_PC, 0, 0),
				]}
			/>
			<TableRow
				data={[
					'Extra Care Opportunity Score',
					'',
					formatPercentage(caOpportunity_H, 0, 0),
					formatPercentage(regionOpportunity_H, 0, 0),
					formatPercentage(countryOpportunity_H, 0, 0),
				]}
			/>
			<TableRow
				data={[
					'Dementia Opportunity Score',
					'',
					formatPercentage(caOpportunity_D, 0, 0),
					formatPercentage(regionOpportunity_D, 0, 0),
					formatPercentage(countryOpportunity_D, 0, 0),
				]}
			/>
		</>
	)
}

interface CompetitionTableProps extends CaRowProps {
	lat: number
	long: number
}

const CompetitionRows: React.FC<CompetitionTableProps> = ({ oaCode, radius, lat, long }) => {
	const data = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, { oaCode, radius })

	const sitesData = React.useMemo(() => {
		return data.sitesByCa || []
	}, [data])

	const sites = useFragment<CompetitionTableFragment$key>(CompetitionTableFragment, sitesData.sites)

	const elderlySites = filterEldery(sites)

	const rows = elderlySites.map((site) => {
		const type = site.serviceTypes.join(', ')
		const from: Point = { latitude: site.latitude!, longitude: site.longitude! }
		const to: Point = { latitude: lat, longitude: long }
		return [
			site.name,
			formatNumber(calculateDistance(from, to)),
			site.operatorName || '',
			`${new Date(site.registrationDate).getFullYear()}`,
			type,
			formatNumber(site.numberOfBeds || 0),
			formatNumber(site.overallRating || 0),
		]
	})

	// sort by distance
	rows.sort((a, b) => parseFloat(a[1]) - parseFloat(b[1]))

	return (
		<>
			{rows.map((row, idx) => (
				<TableRow key={idx} data={row} />
			))}
		</>
	)
}

export {
	AnchorRow,
	BedRows,
	BusinessAnalysisGroupRow,
	CareScoreRow,
	CareWageRows,
	CompetitionRows,
	DementiaBedsNursingRow,
	DementiaBedsResidentialRow,
	DementiaBedsRow,
	DementiaRow,
	DementiaScoreRow,
	DisRow,
	DomiciliaryCapacityRow,
	ECIFTNRow,
	ExtraCareScoreRow,
	GDHIRow,
	GeoRow,
	Header,
	HealthWorkforceRow,
	HPRow,
	HWRow,
	MarketAnalyticsRow,
	MigrantFactorRow,
	MostViableUseCaseRow,
	NursingScoreRow,
	OccupancyRateRow,
	PopulationRow,
	RatingRow,
	ResidentialScoreRow,
	SRRow,
	TableRow,
	UERow,
}
