/**
 * @generated SignedSource<<175443e497b97ad9a500e96f1e79e304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PropertiesFilterOpts = {
  maxArea?: number | null | undefined;
  minArea?: number | null | undefined;
};
export type PropertiesByLatLongQuery$variables = {
  filter?: PropertiesFilterOpts | null | undefined;
  latitude: number;
  longitude: number;
  metres?: number | null | undefined;
};
export type PropertiesByLatLongQuery$data = {
  readonly propertiesByLatLong: {
    readonly features: ReadonlyArray<{
      readonly geometry: {
        readonly coordinates: any;
        readonly type: string;
      };
      readonly id: string;
      readonly properties: {
        readonly aonbIds: ReadonlyArray<string>;
        readonly area: number;
        readonly brownfieldIds: ReadonlyArray<string>;
        readonly floodRiskZoneIds: ReadonlyArray<string>;
        readonly greenBeltIds: ReadonlyArray<string>;
        readonly listedBuildingIds: ReadonlyArray<string>;
        readonly sitesOfSpecialScientificInterestIds: ReadonlyArray<string>;
        readonly specialProtectionAreaIds: ReadonlyArray<string>;
        readonly treePreservationZoneIds: ReadonlyArray<string>;
      };
      readonly type: string;
    }>;
  };
};
export type PropertiesByLatLongQuery = {
  response: PropertiesByLatLongQuery$data;
  variables: PropertiesByLatLongQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "latitude"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "longitude"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metres"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "latitude",
        "variableName": "latitude"
      },
      {
        "kind": "Variable",
        "name": "longitude",
        "variableName": "longitude"
      },
      {
        "kind": "Variable",
        "name": "metres",
        "variableName": "metres"
      }
    ],
    "concreteType": "PropertyFeatures",
    "kind": "LinkedField",
    "name": "propertiesByLatLong",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyFeature",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GeoJsonGeometry",
            "kind": "LinkedField",
            "name": "geometry",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyProperties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "area",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aonbIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brownfieldIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "greenBeltIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floodRiskZoneIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "listedBuildingIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialProtectionAreaIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sitesOfSpecialScientificInterestIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "treePreservationZoneIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertiesByLatLongQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PropertiesByLatLongQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "92444e1324abdc87cbd25d8c06835396",
    "id": null,
    "metadata": {},
    "name": "PropertiesByLatLongQuery",
    "operationKind": "query",
    "text": "query PropertiesByLatLongQuery(\n  $latitude: Float!\n  $longitude: Float!\n  $metres: Int\n  $filter: PropertiesFilterOpts\n) {\n  propertiesByLatLong(latitude: $latitude, longitude: $longitude, metres: $metres, filter: $filter) {\n    features {\n      id\n      type\n      geometry {\n        type\n        coordinates\n      }\n      properties {\n        area\n        aonbIds\n        brownfieldIds\n        greenBeltIds\n        floodRiskZoneIds\n        listedBuildingIds\n        specialProtectionAreaIds\n        sitesOfSpecialScientificInterestIds\n        treePreservationZoneIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0609b49b66b22a2efe1be52b5b922cd7";

export default node;
