async function fetchPostcodeData(url: string) {
	try {
		const response = await fetch(url)
		if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)
		const data = await response.json()
		return data.result || null
	} catch (error) {
		console.error('Error fetching postcode data:', error)
		return null
	}
}

async function getLatLongByPostCode(postCode: string): Promise<[number, number] | null> {
	const sanitizedPostCode = postCode.trim().replace(/\s/g, '')
	if (!sanitizedPostCode) return null

	const result = await fetchPostcodeData(`https://api.postcodes.io/postcodes/${sanitizedPostCode}`)
	return result?.latitude && result?.longitude ? [result.latitude, result.longitude] : null
}

async function getPostCodeByLatLong(lat: number, long: number): Promise<string | null> {
	const result = await fetchPostcodeData(`https://api.postcodes.io/postcodes?lat=${lat}&lon=${long}`)
	return result?.[0]?.postcode || null
}

export { getLatLongByPostCode, getPostCodeByLatLong }
