/**
 * @generated SignedSource<<87bc329945a95ed53f4fbd5b594f3045>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorGroupProvideOperatorFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly operatorGroupId: string | null | undefined;
  readonly sites: ReadonlyArray<{
    readonly hasActiveRegistration: boolean;
    readonly id: string;
    readonly isCareHome: boolean;
    readonly ladCode: string;
    readonly latitude: number | null | undefined;
    readonly longitude: number | null | undefined;
    readonly name: string;
    readonly numberOfBeds: number | null | undefined;
    readonly overallRating: number | null | undefined;
    readonly regionCode: string;
    readonly registrationDate: any | null | undefined;
    readonly serviceTypes: ReadonlyArray<string>;
    readonly specialisms: ReadonlyArray<string>;
  }>;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "OperatorGroupProvideOperatorFragment";
}>;
export type OperatorGroupProvideOperatorFragment$key = ReadonlyArray<{
  readonly " $data"?: OperatorGroupProvideOperatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorGroupProvideOperatorFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OperatorGroupProvideOperatorFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "sites",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCareHome",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overallRating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registrationDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasActiveRegistration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ladCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceTypes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specialisms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "regionCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Operator",
  "abstractKey": null
};
})();

(node as any).hash = "da9d71635de75c9e6c738a64c0635546";

export default node;
