function defineCareType(
	bedsPerHomeMedian: number,
	dementiaHomesShare: number,
	nursingHomesShare: number,
	residentialHomesShare: number,
): string {
	let size: string
	if (bedsPerHomeMedian <= 10) {
		size = 'Supersmall'
	} else if (bedsPerHomeMedian <= 40) {
		size = 'Small'
	} else if (bedsPerHomeMedian <= 60) {
		size = 'Mid-size'
	} else if (bedsPerHomeMedian <= 100) {
		size = 'Big'
	} else {
		size = 'Superlarge'
	}

	let type = size

	if (dementiaHomesShare > 0.5) {
		type += ' Dementia'
	}

	if (nursingHomesShare >= 0.5) {
		type += ' Nursing'
		if (residentialHomesShare >= 0.5) {
			type += ' & Residential'
		}
	} else if (residentialHomesShare > 0.5) {
		type += ' Residential'
	}

	return type
}

export { defineCareType }
