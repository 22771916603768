import graphql from 'babel-plugin-relay/macro'

export const LocalAuthorityFeatures = graphql`
	query LocalAuthorityFeaturesQuery($version: GeographyVersions!) {
		localAuthorityFeatures(version: $version) {
			features {
				type
				geometry {
					type
					coordinates
				}
				properties {
					id
					name
					latitude
					longitude
				}
			}
		}
	}
`
