import graphql from 'babel-plugin-relay/macro'

export const OperatorsTableFragment = graphql`
	fragment OperatorsTableFragment on Operator @relay(plural: true) {
		id
		name
		operatorGroupName
		registrationDate
		website
		sites {
			id
			isCareHome
			overallRating
			registrationDate
			hasActiveRegistration
			ladCode
			numberOfBeds
			specialisms
			regionCode
		}
	}
`
