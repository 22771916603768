import { Ca, defaultCa, defaultLad } from '../Constants'

const setItem = (key: string, value: any): void => {
	localStorage.setItem(key, JSON.stringify(value))
}

const getItem = (key: string, fallback: any = null): any | null => {
	const item = localStorage.getItem(key)
	return item ? JSON.parse(item) : fallback
}

const setStoredLadCode = (ladCode: string): void => {
	setItem('ladCode', ladCode)
}

const getStoredLadCode = (): string => {
	return getItem('ladCode', defaultLad)
}

const setStoredCa = (lat: number, long: number, radius: string): void => {
	setItem('ca', { lat, long, radius })
}

const getStoredCa = (): Ca => {
	return getItem('ca', defaultCa)
}

const setProject = (project: string): void => {
	setItem('project', project)
}

const getProject = (): string => {
	return getItem('project', 'default')
}

export { getProject, getStoredCa, getStoredLadCode, setProject, setStoredCa, setStoredLadCode }
