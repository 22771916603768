import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

import { dementiaSpecialismKey, elderlySpecialismKey, nursingHomesKey, residentialHomesKey } from '../../Constants'
import { defineCareGroupType } from '../../metrics/careGroupType'
import { calculateCareHomeSizeScore } from '../../metrics/careHomeSizeScore'
import { defineCareType } from '../../metrics/careType'
import { calculateCareTypeScore } from '../../metrics/careTypeScore'
import { calculateCqcRatingScore } from '../../metrics/cqcRatingScore'
import { calculateGeoScore } from '../../metrics/geoScore'
import { Operator, OperatorGroup } from '../../models/cqc'
import { Project } from '../../models/project'
import { useOperatorGroups } from '../../providers/OperatorGroupProvider'
import { CSVDownloadButton } from '../../shared/CSVDownloadButton'
import { DataTable, RowData } from '../../shared/DataTable'
import { FloatingWrapper } from '../../shared/FloatingWrapper'
import { calculateCAGR } from '../../utils/CAGR'
import { calculateDistance, Point } from '../../utils/calculateDistance'
import { calculateMedian } from '../../utils/calculateMedian'
import { calculateOmaMatchingScore } from '../../utils/calculateOmaMatchingScore'
import { calculateOperatorGrowthScoreDynamic } from '../../utils/calculateOperatorGrowthScores'
import { formatNumber, formatPercentage } from '../../utils/formatNumber'

type TableData = RowData & {
	aggregatedGrowthScore: number
	bedsCAGR3Years: number
	bedsCAGR5Years: number
	bedsCount3YearsAgo: number
	bedsCount5YearsAgo: number
	bedsCount7YearsAgo: number
	bedsCountCurrent: number
	bedsNew3YearsCount: number
	bedsNew5YearsCount: number
	bedsPerHomeMax: number
	bedsPerHomeMaxMinusMin: number
	bedsPerHomeMean: number
	bedsPerHomeMedian: number
	bedsPerHomeMin: number
	careHomeCount3YearsAgo: number
	careHomeCount5YearsAgo: number
	careHomeCount7YearsAgo: number
	careHomeCountCurrent: number
	careHomesCAGR3Years: number
	careHomesCAGR5Years: number
	careHomeSizeScore: number
	careHomesIn10MileRadius: number
	careHomesIn20MileRadius: number
	careHomesIn3MileRadius: number
	careHomesIn5MileRadius: number
	careHomesNew3YearsCount: number
	careHomesNew5YearsCount: number
	careType: string
	careTypeScore: number
	coefficientOfVariation_meanBased: number
	coefficientOfVariation_medianBased: number
	cqcRatingScore: number
	dementiaBedsCount: number
	dementiaBedsShare: number
	dementiaHomesCount: number
	dementiaHomesShare: number
	elderlyBedsCount: number
	elderlyBedsShare: number
	elderlyHomesCount: number
	elderlyHomesShare: number
	geoScore: number
	groupType: string
	id: string
	matchingScore: number
	nursingBedsCount: number
	nursingBedsShare: number
	nursingHomeBedsMean: number
	nursingHomeBedsMedian: number
	nursingHomesCount: number
	nursingHomesShare: number
	operatorGroupName: string
	operatorMedianBedsDeviation: number
	operatorMedianBedsDeviationCount: number
	ratingOperatorMean: number
	regionsCoverageShare: number
	registeredCareHomesCount: number
	residentalHomeBedsMean: number
	residentalHomeBedsMedian: number
	residentialBedsCount: number
	residentialBedsShare: number
	residentialHomesCount: number
	residentialHomesShare: number
	totalBeds: number
}

interface Props {
	project: Project
	projectUseCaseNursing: boolean
	projectUseCaseResidential: boolean
	projectUseCaseDementia: boolean
}

const OmaDataTable: React.FC<Props> = ({ project }) => {
	const { operatorGroups } = useOperatorGroups()

	const columns = React.useMemo<ColumnDef<TableData, any>[]>(
		() => [
			{ header: 'Name', accessorKey: 'operatorGroupName' },
			{
				header: 'Provider Type',
				accessorKey: 'groupType',
			},
			{
				header: 'Care Home Profile',
				accessorKey: 'careType',
			},
			{
				header: 'Total Beds',
				accessorKey: 'totalBeds',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{ header: 'Total Sites', accessorKey: 'registeredCareHomesCount' },
			{
				header: 'Matching Score',
				accessorKey: 'matchingScore',
				cell: ({ getValue }) => formatNumber(getValue(), 0, 1),
			},
			{
				header: 'CQC Score',
				accessorKey: 'cqcRatingScore',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Geo Score',
				accessorKey: 'geoScore',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Care Size Score',
				accessorKey: 'careHomeSizeScore',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Care Type Score',
				accessorKey: 'careTypeScore',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Growth Score',
				accessorKey: 'aggregatedGrowthScore',
				cell: ({ getValue }) => formatNumber(getValue(), 0, 0),
			},
			{
				header: 'Care Homes new 3 years',
				accessorKey: 'careHomesNew3YearsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Care Homes CAGR 3 years',
				accessorKey: 'careHomesCAGR3Years',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Beds CAGR 3 years',
				accessorKey: 'bedsCAGR3Years',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Care Homes new 5 years',
				accessorKey: 'careHomesNew5YearsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Care Homes CAGR 5 years',
				accessorKey: 'careHomesCAGR5Years',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'CQC Rating Average',
				accessorKey: 'ratingOperatorMean',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 1),
			},
			{
				header: 'Beds per site: Median',
				accessorKey: 'bedsPerHomeMedian',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds per site: Average',
				accessorKey: 'bedsPerHomeMean',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds per site: Min',
				accessorKey: 'bedsPerHomeMin',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds per site: Max',
				accessorKey: 'bedsPerHomeMax',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds per site: Max-Min',
				accessorKey: 'bedsPerHomeMaxMinusMin',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds per site: Coefficient of Variation % (Mean-based)',
				accessorKey: 'coefficientOfVariation_meanBased',
				cell: ({ getValue }) => formatPercentage(getValue(), 0, 0),
			},
			{
				header: 'Beds per site: Coefficient of Variation % (Median-based)',
				accessorKey: 'coefficientOfVariation_medianBased',
				cell: ({ getValue }) => formatPercentage(getValue(), 0, 0),
			},
			{
				header: 'Beds Deviation from Project Beds',
				accessorKey: 'operatorMedianBedsDeviationCount',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Beds Deviation from Project Beds %',
				accessorKey: 'operatorMedianBedsDeviation',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0),
			},
			{
				header: 'Nursing Homes',
				accessorKey: 'nursingHomesCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Nursing Beds',
				accessorKey: 'nursingBedsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Nursing Beds: Median',
				accessorKey: 'nursingHomeBedsMedian',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Nursing Beds: Mean',
				accessorKey: 'nursingHomeBedsMean',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Nursing Homes %',
				accessorKey: 'nursingHomesShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Nursing Beds %',
				accessorKey: 'nursingBedsShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Residential Homes',
				accessorKey: 'residentialHomesCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Residential Beds',
				accessorKey: 'residentialBedsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Residential Beds: Median',
				accessorKey: 'residentalHomeBedsMedian',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Residential Beds: Mean',
				accessorKey: 'residentalHomeBedsMean',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Residential Homes %',
				accessorKey: 'residentialHomesShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Residential Beds %',
				accessorKey: 'residentialBedsShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Elderly Homes',
				accessorKey: 'elderlyHomesCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Elderly Beds',
				accessorKey: 'elderlyBedsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Elderly Homes %',
				accessorKey: 'elderlyHomesShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Elderly Beds %',
				accessorKey: 'elderlyBedsShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Dementia Homes',
				accessorKey: 'dementiaHomesCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Dementia Beds',
				accessorKey: 'dementiaBedsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Dementia Homes %',
				accessorKey: 'dementiaHomesShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Dementia Beds %',
				accessorKey: 'dementiaBedsShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Regions active %',
				accessorKey: 'regionsCoverageShare',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Care Homes 7 years ago',
				accessorKey: 'careHomeCount7YearsAgo',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Care Homes 5 years ago',
				accessorKey: 'careHomeCount5YearsAgo',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Care Homes 3 years ago',
				accessorKey: 'careHomeCount3YearsAgo',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds 7 years ago',
				accessorKey: 'bedsCount7YearsAgo',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds 5 years ago',
				accessorKey: 'bedsCount5YearsAgo',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds 3 years ago',
				accessorKey: 'bedsCount3YearsAgo',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds new 3 years',
				accessorKey: 'bedsNew3YearsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds new 5 years',
				accessorKey: 'bedsNew5YearsCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds CAGR 5 years',
				accessorKey: 'bedsCAGR5Years',
				cell: ({ getValue }) => formatPercentage(getValue() * 100, 0, 0),
			},
			{
				header: 'Sites in 3 Miles',
				accessorKey: 'careHomesIn3MileRadius',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Sites in 5 Miles',
				accessorKey: 'careHomesIn5MileRadius',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Sites in 10 Miles',
				accessorKey: 'careHomesIn10MileRadius',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
			{
				header: 'Sites in 20 Miles',
				accessorKey: 'careHomesIn20MileRadius',
				cell: ({ getValue }) => formatNumber(getValue(), 0),
			},
		],
		[],
	)

	const csvHeaders = columns.reduce(
		(acc, col) => {
			if ('accessorKey' in col && typeof col.accessorKey === 'string') {
				acc.push({
					label: typeof col.header === 'string' ? col.header : '',
					key: col.accessorKey,
				})
			}
			return acc
		},
		[] as { label: string; key: string }[],
	)

	const single = operatorGroups

	const rows: TableData[] = mergedOperatorGroups(single, project)

	return (
		<div style={{ position: 'relative' }}>
			<DataTable
				columns={columns}
				data={rows}
				fontSize={10}
				wrapCells={false}
				wrapHeaders={false}
				stickyColumn={true}
				stickyHeader={true}
			/>
			<FloatingWrapper corner="bottomRight">
				<CSVDownloadButton data={rows} headers={csvHeaders} />
			</FloatingWrapper>
		</div>
	)
}

function mergedOperatorGroups(operatorGroups: OperatorGroup[], project: Project): TableData[] {
	const projectUseCaseResidential = project.mvu.types.includes('residential')
	const projectUseCaseNursing = project.mvu.types.includes('nursing')
	const projectUseCaseDementia = project.mvu.conditions.includes('dementia')
	const mergedData: TableData[] = operatorGroups.map((og) => {
		const totalBeds = og.operators.reduce(
			(acc, o) => acc + o.registeredCareHomes.reduce((siteAcc, s) => siteAcc + (s.numberOfBeds ?? 0), 0),
			0,
		)
		const registeredCareHomesCount = og.operators.reduce((acc, o) => acc + o.registeredCareHomes.length, 0)
		const ratings = og.operators.reduce<number[]>(
			(acc, o) =>
				acc.concat(o.registeredCareHomes.map((s) => s.ratingValue).filter((rating): rating is number => rating !== -1)),
			[],
		)
		const ratingOperatorMean = ratings.length > 0 ? ratings.reduce((acc, r) => acc + r, 0) / ratings.length : 0
		const cqcRatingScore = calculateCqcRatingScore(ratingOperatorMean)

		// Beds per home stats
		const bedNumbers = og.operators.flatMap((o) => o.registeredCareHomes.map((s) => s.numberOfBeds ?? 0))
		const bedsPerHomeMedian = calculateMedian(bedNumbers)
		const bedsPerHomeMean = bedNumbers.reduce((acc, b) => acc + b, 0) / registeredCareHomesCount
		const bedsPerHomeMin = Math.min(...bedNumbers)
		const bedsPerHomeMax = Math.max(...bedNumbers)
		const bedsPerHomeMaxMinusMin = bedsPerHomeMax - bedsPerHomeMin
		// Population variance and standard deviation
		const bedsPerHomeVariance =
			bedNumbers.reduce((acc, b) => acc + (b - bedsPerHomeMean) ** 2, 0) / registeredCareHomesCount
		const bedsPerHomeStandardDeviation = Math.sqrt(bedsPerHomeVariance)
		// Coefficient of Variation (as a percentage) as an indicator of dispersion:
		// A lower percentage means the bed counts are clustered around the mean.
		const coefficientOfVariation_meanBased = (bedsPerHomeStandardDeviation / bedsPerHomeMean) * 100
		const coefficientOfVariation_medianBased = (bedsPerHomeStandardDeviation / bedsPerHomeMedian) * 100

		// Care home type stats
		const nursingHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(nursingHomesKey)),
		).length
		const nursingBedsCount = og.operators
			.flatMap((o) => o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(nursingHomesKey)))
			.reduce((acc, s) => acc + (s.numberOfBeds ?? 0), 0)
		const nursingHomeBedsMedian = calculateMedian(
			og.operators.flatMap((o) =>
				o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(nursingHomesKey)).map((s) => s.numberOfBeds ?? 0),
			),
		)
		const nursingHomeBedsMean = nursingBedsCount / nursingHomesCount > 0 ? nursingBedsCount / nursingHomesCount : 0
		const nursingHomesShare = nursingHomesCount / registeredCareHomesCount
		const nursingBedsShare = nursingBedsCount / totalBeds
		const residentialHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(residentialHomesKey)),
		).length
		const residentialBedsCount = og.operators
			.flatMap((o) => o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(residentialHomesKey)))
			.reduce((acc, s) => acc + (s.numberOfBeds ?? 0), 0)
		const residentalHomeBedsMedian = calculateMedian(
			og.operators.flatMap((o) =>
				o.registeredCareHomes
					.filter((s) => s.serviceTypes?.includes(residentialHomesKey))
					.map((s) => s.numberOfBeds ?? 0),
			),
		)
		const residentalHomeBedsMean =
			residentialBedsCount / residentialHomesCount > 0 ? residentialBedsCount / residentialHomesCount : 0
		const residentialHomesShare = residentialHomesCount / registeredCareHomesCount
		const residentialBedsShare = residentialBedsCount / totalBeds
		const elderlyHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.specialisms?.includes(elderlySpecialismKey)),
		).length
		const elderlyBedsCount = og.operators
			.flatMap((o) => o.registeredCareHomes.filter((s) => s.specialisms?.includes(elderlySpecialismKey)))
			.reduce((acc, s) => acc + (s.numberOfBeds ?? 0), 0)
		const elderlyHomesShare = elderlyHomesCount / registeredCareHomesCount
		const elderlyBedsShare = elderlyBedsCount / totalBeds
		const dementiaHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.specialisms?.includes(dementiaSpecialismKey)),
		).length
		const dementiaBedsCount = og.operators
			.flatMap((o) => o.registeredCareHomes.filter((s) => s.specialisms?.includes(dementiaSpecialismKey)))
			.reduce((acc, s) => acc + (s.numberOfBeds ?? 0), 0)
		const dementiaHomesShare = dementiaHomesCount / registeredCareHomesCount
		const dementiaBedsShare = dementiaBedsCount / totalBeds

		// GROWTH SCORING
		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()
		const sevenYearsAgo = new Date(currentYear - 7, currentMonth)
		const fiveYearsAgo = new Date(currentYear - 5, currentMonth)
		const threeYearsAgo = new Date(currentYear - 3, currentMonth)
		const currentDate = new Date()

		const bedsCount7YearsAgo = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string; numberOfBeds?: number }) =>
						s.registrationDate && new Date(s.registrationDate) <= sevenYearsAgo
							? siteAcc + (s.numberOfBeds ?? 0)
							: siteAcc,
					0,
				),
			0,
		)
		const bedsCount5YearsAgo = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string; numberOfBeds?: number }) =>
						s.registrationDate && new Date(s.registrationDate) <= fiveYearsAgo
							? siteAcc + (s.numberOfBeds ?? 0)
							: siteAcc,
					0,
				),
			0,
		)
		const bedsCount3YearsAgo = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string; numberOfBeds?: number }) =>
						s.registrationDate && new Date(s.registrationDate) <= threeYearsAgo
							? siteAcc + (s.numberOfBeds ?? 0)
							: siteAcc,
					0,
				),
			0,
		)
		const bedsCountCurrent = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string; numberOfBeds?: number }) =>
						s.registrationDate && new Date(s.registrationDate) <= currentDate
							? siteAcc + (s.numberOfBeds ?? 0)
							: siteAcc,
					0,
				),
			0,
		)
		const careHomeCount7YearsAgo = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string }) =>
						s.registrationDate && new Date(s.registrationDate) <= sevenYearsAgo ? siteAcc + 1 : siteAcc,
					0,
				),
			0,
		)
		const careHomeCount5YearsAgo = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string }) =>
						s.registrationDate && new Date(s.registrationDate) <= fiveYearsAgo ? siteAcc + 1 : siteAcc,
					0,
				),
			0,
		)
		const careHomeCount3YearsAgo = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string }) =>
						s.registrationDate && new Date(s.registrationDate) <= threeYearsAgo ? siteAcc + 1 : siteAcc,
					0,
				),
			0,
		)
		const careHomeCountCurrent = og.operators.reduce(
			(oAcc, o) =>
				oAcc +
				o.registeredCareHomes.reduce(
					(siteAcc: number, s: { registrationDate?: string }) =>
						s.registrationDate && new Date(s.registrationDate) <= currentDate ? siteAcc + 1 : siteAcc,
					0,
				),
			0,
		)
		// CAGR
		const bedsCAGR3Years = calculateCAGR(bedsCount3YearsAgo, bedsCountCurrent, 3)
		const bedsCAGR5Years = calculateCAGR(bedsCount5YearsAgo, bedsCountCurrent, 5)
		const careHomesCAGR3Years = calculateCAGR(careHomeCount3YearsAgo, careHomeCountCurrent, 3)
		const careHomesCAGR5Years = calculateCAGR(careHomeCount5YearsAgo, careHomeCountCurrent, 5)

		// New Beds and Care Home
		const bedsNew3YearsCount = bedsCountCurrent - bedsCount3YearsAgo
		const bedsNew5YearsCount = bedsCountCurrent - bedsCount5YearsAgo
		const careHomesNew3YearsCount = careHomeCountCurrent - careHomeCount3YearsAgo
		const careHomesNew5YearsCount = careHomeCountCurrent - careHomeCount5YearsAgo
		const careHomesNew7YearsCount = careHomeCountCurrent - careHomeCount7YearsAgo

		// GROWTH SCORING
		const aggregatedGrowthScore = calculateOperatorGrowthScoreDynamic({
			registeredCareHomesCount: registeredCareHomesCount,
			careHomesNew3Years: careHomesNew3YearsCount,
			careHomesNew5Years: careHomesNew5YearsCount,
			careHomesNew7Years: careHomesNew7YearsCount,
			bedsCAGR3Years: bedsCAGR3Years,
			bedsCAGR5Years: bedsCAGR5Years,
		})

		// GEO SCORING
		const regionsOfCareHomes = og.operators.flatMap((o) => o.registeredCareHomes.map((s) => s.regionCode))
		const regionsActive = Array.from(new Set(regionsOfCareHomes))
		const regionsActiveCount = regionsActive.length
		const regionsCoverageShare = regionsActiveCount / 9

		const projectCoordinates: Point = { latitude: project.latitude, longitude: project.longitude }

		const distanceReducer = (miles: number) => (acc: number, operator: Operator) => {
			return (
				acc +
				operator.registeredCareHomes.filter((s) => {
					const siteCoordinates: Point = { latitude: s.latitude!, longitude: s.longitude! }
					const dist = calculateDistance(siteCoordinates, projectCoordinates)
					return dist < miles
				}).length
			)
		}
		const careHomesIn3MileRadius = og.operators.reduce(distanceReducer(3), 0)
		const careHomesIn5MileRadius = og.operators.reduce(distanceReducer(5), 0)
		const careHomesIn10MileRadius = og.operators.reduce(distanceReducer(10), 0)
		const careHomesIn20MileRadius = og.operators.reduce(distanceReducer(20), 0)
		const careHomesIn30MileRadius = og.operators.reduce(distanceReducer(30), 0)

		const geoScore = calculateGeoScore({
			careHomesIn5MileRadius,
			careHomesIn10MileRadius,
			careHomesIn20MileRadius,
			careHomesIn30MileRadius,
		})

		// PROFILE SCORING
		const projectsBeds = project.settings.numberOfBeds ?? 60
		const operatorMedianBedsDeviationCount = bedsPerHomeMedian - projectsBeds
		const operatorMedianBedsDeviation = operatorMedianBedsDeviationCount / projectsBeds
		const careHomeSizeScore = calculateCareHomeSizeScore(operatorMedianBedsDeviationCount, projectsBeds)

		const careTypeScore = calculateCareTypeScore(
			nursingHomesShare,
			residentialHomesShare,
			dementiaHomesShare,
			projectUseCaseNursing,
			projectUseCaseResidential,
			projectUseCaseDementia,
		)

		const matchingScore = calculateOmaMatchingScore({
			cqcRatingScore: cqcRatingScore,
			growthScore: aggregatedGrowthScore,
			geoScore: geoScore,
			careHomeSizeScore: careHomeSizeScore,
			careTypeScore: careTypeScore,
		})

		// CATEGORISING
		const groupType = defineCareGroupType(registeredCareHomesCount, regionsActiveCount)
		const careType = defineCareType(bedsPerHomeMedian, dementiaHomesShare, nursingHomesShare, residentialHomesShare)

		const td: TableData = {
			aggregatedGrowthScore,
			bedsCAGR3Years,
			bedsCAGR5Years,
			bedsCount3YearsAgo,
			bedsCount5YearsAgo,
			bedsCount7YearsAgo,
			bedsCountCurrent,
			bedsNew3YearsCount,
			bedsNew5YearsCount,
			bedsPerHomeMax,
			bedsPerHomeMaxMinusMin,
			bedsPerHomeMean,
			bedsPerHomeMedian,
			bedsPerHomeMin,
			careHomeCount3YearsAgo,
			careHomeCount5YearsAgo,
			careHomeCount7YearsAgo,
			careHomeCountCurrent,
			careHomesCAGR3Years,
			careHomesCAGR5Years,
			careHomesIn10MileRadius,
			careHomesIn20MileRadius,
			careHomesIn3MileRadius,
			careHomesIn5MileRadius,
			careHomeSizeScore,
			careHomesNew3YearsCount,
			careHomesNew5YearsCount,
			careType,
			careTypeScore,
			coefficientOfVariation_meanBased,
			coefficientOfVariation_medianBased,
			cqcRatingScore,
			dementiaBedsCount,
			dementiaBedsShare,
			dementiaHomesCount,
			dementiaHomesShare,
			elderlyBedsCount,
			elderlyBedsShare,
			elderlyHomesCount,
			elderlyHomesShare,
			geoScore,
			groupType,
			id: og.id,
			matchingScore,
			nursingBedsCount,
			nursingBedsShare,
			nursingHomeBedsMean,
			nursingHomeBedsMedian,
			nursingHomesCount,
			nursingHomesShare,
			operatorGroupName: og.name,
			operatorMedianBedsDeviation,
			operatorMedianBedsDeviationCount,
			ratingOperatorMean,
			regionsCoverageShare,
			registeredCareHomesCount,
			residentalHomeBedsMean,
			residentalHomeBedsMedian,
			residentialBedsCount,
			residentialBedsShare,
			residentialHomesCount,
			residentialHomesShare,
			totalBeds,
		}

		return td
	})

	return mergedData.sort((a: TableData, b: TableData) => b.matchingScore - a.matchingScore)
}

export { OmaDataTable }
