import Polyglot from 'node-polyglot'

// Define translations with pluralization
const phrases = {
	create_project: {
		page_title: 'Create Project',
		form_title: 'Create Project',
		submit_button: 'Submit',
	},
	update_project: {
		page_title: 'Edit Project',
		form_title: 'Edit Project',
		submit_button: 'Submit',
	},
	project_form: {
		project_name: 'Project Name',
		client: 'Client',
		post_code: 'Post Code',
		latitude: 'Latitude',
		longitude: 'Longitude',
		project_date: 'Project Date',
		number_of_beds: 'No. of Beds',
		number_of_extra_care_units: 'No. of Extra Care Units',
		selected_properties: '%{smart_count} property |||| %{smart_count} properties',
		add_project: 'Add Project',
		invalid_date_format: 'Invalid date format. Please enter a valid date.',
	},
}

// Initialize Polyglot
const polyglot = new Polyglot({
	phrases,
	locale: 'en-GB',
})

// Function for plural translations
const t = (key: string, count?: number) => polyglot.t(key, { smart_count: count })

export { t }
