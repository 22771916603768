import React, { startTransition, useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { SitesQuery } from '../../graphql/queries/__generated__/SitesQuery.graphql'
import { Sites as Request } from '../../graphql/queries/Sites'
import { Page } from '../../layout/page/Page'
import { DataTable } from '../../shared/DataTable'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { SegmentedButton } from '../../shared/SegmentedButton'
import { PageTitle, Paragraph } from '../../shared/Text'
import { SitesSiteFragment$key } from './__generated__/SitesSiteFragment.graphql'
import { SitesSiteFragment } from './SitesSiteFragment'

const Sites: React.FC = () => {
	const limit = 100 // Example limit
	const [offset, setOffset] = useState(0)
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const pageParam = searchParams.get('page')

	// Parse the current page from the URL (default to 0)
	useEffect(() => {
		const page = pageParam ? parseInt(pageParam, 10) : 1 // default to page 1
		setOffset((page - 1) * limit)
	}, [pageParam])

	const data = useLazyLoadQuery<SitesQuery>(Request, {
		limit,
		offset,
		filter: {
			elderlyCare: true,
			englandOnly: true,
			careHomes: true,
			registered: true,
		},
	})

	const tableData = React.useMemo(() => {
		return data.sites || []
	}, [data])

	const sites = useFragment<SitesSiteFragment$key>(SitesSiteFragment, tableData.sites)

	const columns = React.useMemo(
		() => [
			{ header: 'Site Name', accessorKey: 'name' },
			{ header: 'Beds', accessorKey: 'numberOfBeds' },
			{ header: 'Lat', accessorKey: 'latitude' },
			{ header: 'Long', accessorKey: 'longitude' },
			{ header: 'Phone', accessorKey: 'mainPhoneNumber' },
		],
		[],
	)

	const handleNextPage = () => {
		if (tableData.hasNextPage) {
			startTransition(() => {
				setOffset((prevOffset) => prevOffset + limit) // Increment offset
				navigate(`?page=${offset / limit + 2}`) // Update URL with new page number
			})
		}
	}

	const handlePrevPage = () => {
		if (offset > 0) {
			startTransition(() => {
				setOffset((prevOffset) => Math.max(prevOffset - limit, 0)) // Decrement offset
				navigate(`?page=${offset / limit}`) // Update URL with new page number
			})
		}
	}

	const buttonOpts = [
		{
			children: <ChevronLeft />,
			value: 'prev',
			disabled: offset === 0,
		},
		{
			children: <ChevronRight />,
			value: 'next',
			disabled: !tableData.hasNextPage,
		},
	]

	const handleRowClick = (id: string) => {
		navigate(`/site/${id}`) // Navigate to the site detail page using the row's ID
	}

	return (
		<Page title="Sites">
			<Container>
				<VerticalSpace size="md" />
				<Row>
					<div className="col-12">
						<PageTitle>Sites</PageTitle>
					</div>
				</Row>
				<VerticalSpace size="lg" />
				<Row>
					<div className="col-12">
						<Panel>
							{tableData.sites.length > 0 ? (
								<>
									<SpaceBetween className="pagination">
										<Paragraph>
											Showing {offset + 1} to {offset + tableData.sites.length}
										</Paragraph>
										<SegmentedButton
											options={buttonOpts}
											onClick={(value) => {
												if (value === 'prev') handlePrevPage()
												else if (value === 'next') handleNextPage()
											}}
										/>
									</SpaceBetween>
									<HorizontalSpace size="sm" />
									<DataTable columns={columns} data={sites} onRowClick={handleRowClick} />
									<SpaceBetween className="pagination">
										<Paragraph>
											Showing {offset + 1} to {offset + tableData.sites.length}
										</Paragraph>
										<SegmentedButton
											options={buttonOpts}
											onClick={(value) => {
												if (value === 'prev') handlePrevPage()
												else if (value === 'next') handleNextPage()
											}}
										/>
									</SpaceBetween>
								</>
							) : (
								<p>No sites found.</p>
							)}
						</Panel>
					</div>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Sites />
		</React.Suspense>
	)
}

export { Suspended as Sites }
