import graphql from 'babel-plugin-relay/macro'

export const DeleteProject = graphql`
	mutation DeleteProjectMutation($id: UUID!) {
		deleteProject(id: $id) {
			id @deleteRecord
			success
		}
	}
`
