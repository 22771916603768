import React, { useEffect, useRef } from 'react'

import { Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Message } from './Message'
import { Answer } from './request'

interface ChatWindowProps {
	messages: Answer[]
	errorMessage?: string | null
	isLoading: boolean
	maxWidth?: number
}

export const Chat: React.FC<ChatWindowProps> = ({ messages, errorMessage, isLoading, maxWidth = 800 }) => {
	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setTimeout(() => {
			if (containerRef.current) {
				containerRef.current.scrollTo({
					top: containerRef.current.scrollHeight,
					behavior: 'smooth',
				})
			}
		}, 1000)
	}, [messages]) // Scroll to bottom when messages change

	const fakeMessage = {
		content: { text: 'Reasoning...' },
		isUser: false,
	}

	return (
		<div
			ref={containerRef}
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				overflowY: 'auto',
				padding: '20px 20px 60px 20px',
			}}
		>
			<Container>
				<Row>
					<div className="mx-auto" style={{ maxWidth }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{errorMessage && <p style={{ color: 'red', marginBottom: '1rem' }}>{errorMessage}</p>}
							{messages.map((message, index) => (
								<Message key={index} message={message} />
							))}
							{isLoading && <Message message={fakeMessage} />}
							<VerticalSpace size="lg" />
						</div>
					</div>
				</Row>
			</Container>
		</div>
	)
}
