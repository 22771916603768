import { Color } from '@deck.gl/core'
import { MVTLayer } from '@deck.gl/geo-layers'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import type { MultiPolygon, Polygon } from 'geojson'
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { getGoogleMapId } from '../Config'
import { AnswerMapMarker } from '../pages/agent/request'

export interface Tile {
	name: string
	color: Color
	urlTemplate: string
}

export type GeoPolygon = Polygon | MultiPolygon

interface GoogleMapProps {
	center?: { lat: number; long: number }
	zoom?: number
	markers?: AnswerMapMarker[]
	polygons?: GeoPolygon[]
	tiles?: Tile[]
	style?: React.CSSProperties
}

export interface GoogleMapHandle {
	updateMap: (next: {
		center?: { lat: number; long: number }
		zoom?: number
		markers?: AnswerMapMarker[]
		polygons?: GeoPolygon[]
		tiles?: Tile[]
	}) => void
}

const defaultCenter = { lat: 51.6718, long: -0.2101 }

const GoogleMap = forwardRef<GoogleMapHandle, GoogleMapProps>(
	(
		{
			center = defaultCenter,
			zoom = 10,
			markers = [],
			polygons = [],
			tiles = [],
			style = { width: '100%', height: '100%' },
		},
		ref,
	) => {
		const mapContainer = useRef<HTMLDivElement>(null)
		const mapRef = useRef<google.maps.Map>()
		const overlayRef = useRef<GoogleMapsOverlay>()
		const markerRefs = useRef<google.maps.marker.AdvancedMarkerElement[]>([])
		const polygonRefs = useRef<google.maps.Polygon[]>([])
		const infoWindowRef = useRef<google.maps.InfoWindow>()

		// 🧠 Imperative update function
		const updateMap = (next: {
			center?: { lat: number; long: number }
			zoom?: number
			markers?: AnswerMapMarker[]
			polygons?: GeoPolygon[]
			tiles?: Tile[]
		}) => {
			if (!mapRef.current) return
			console.debug({ center, zoom, markers, polygons, tiles })

			if (center) {
				console.debug('Updating center:', center)
				mapRef.current.panTo({ lat: center.lat, lng: center.long })
			}
			if (zoom !== undefined) {
				console.debug('Updating zoom:', zoom)
				mapRef.current.setZoom(zoom)
			}

			const bounds = new google.maps.LatLngBounds()

			// Markers
			markerRefs.current.forEach((m) => (m.map = null))
			markerRefs.current = []
			next.markers?.forEach(({ lat, long, title, type }) => {
				const color = type === 'site' ? '#1C4DD3' : '#FF0000'

				const position = { lat, lng: long }
				const marker = new google.maps.marker.AdvancedMarkerElement({
					map: mapRef.current!,
					position,
					title,
					content: createStyledMarker(color), // 👈 your color here
				})
				markerRefs.current.push(marker)
				bounds.extend(position)

				// 💬 Show tooltip on click
				marker.addListener('click', () => {
					if (infoWindowRef.current) {
						const infoWindow = infoWindowRef.current!
						const { body, header } = createToolTip(color, title)
						infoWindow.setHeaderContent(header)
						infoWindow.setContent(body)
						infoWindow.setOptions({ position: { lat, lng: long }, pixelOffset: new google.maps.Size(0, -15) }) // 👈 shift upward
						infoWindow.open({
							map: mapRef.current,
						})
					}
				})
			})

			// 👇 Only fit bounds if no center/zoom was explicitly provided
			if (next.markers?.length) {
				mapRef.current.fitBounds(bounds, 100) // padding in pixels
			}

			// Polygons
			polygonRefs.current.forEach((p) => p.setMap(null))
			polygonRefs.current = []
			polygons?.forEach((geo) => {
				console.debug('Adding polygon:', geo)
				const rings = geo.type === 'MultiPolygon' ? geo.coordinates.flat() : geo.coordinates
				rings.forEach((ring) => {
					const path = ring.map(([lng, lat]) => ({ lat, lng }))
					const polygon = new google.maps.Polygon({
						paths: path,
						map: mapRef.current!,
						fillColor: '#1C4DD350',
						strokeColor: '#1C4DD3',
						strokeWeight: 3,
					})
					polygonRefs.current.push(polygon)
				})
			})

			// Tiles / Deck.gl
			// overlayRef.current?.setMap(null)
			if (next.tiles?.length) {
				const layers = next.tiles.map(({ urlTemplate, color, name }) => {
					console.debug('Adding tile:', name)
					return new MVTLayer({
						id: urlTemplate,
						data: urlTemplate,
						pickable: true,
						getFillColor: () => color,
						getLineColor: () => color,
						getLineWidth: 1,
						minZoom: 10,
						maxZoom: 18,
						onClick: (info) => {
							if (info.object && info.coordinate) {
								const lngLat = info.coordinate // [x, y] → [lng, lat]

								const { body, header } = createToolTip('#1C4DD3', info.object.id, info.object.properties)
								infoWindowRef.current!.setHeaderContent(header)
								infoWindowRef.current!.setContent(body)
								infoWindowRef.current!.setOptions({
									position: { lat: lngLat[1], lng: lngLat[0] },
									pixelOffset: new google.maps.Size(0, -15),
								})
								infoWindowRef.current!.open({
									map: mapRef.current,
								})
							}
						},
					})
				})
				const overlay = new GoogleMapsOverlay({ layers })
				overlay.setMap(mapRef.current)
				overlayRef.current = overlay
			}
		}

		// 🗺️ Initialize the map once
		useEffect(() => {
			if (window.google && mapContainer.current && !mapRef.current) {
				const map = new google.maps.Map(mapContainer.current, {
					center: { lat: center.lat, lng: center.long },
					zoom,
					mapId: getGoogleMapId(),
					zoomControl: true,
					controlSize: 24,
					fullscreenControlOptions: {
						position: google.maps.ControlPosition.RIGHT_BOTTOM,
					},
				})
				mapRef.current = map

				updateMap({ center, zoom, markers, polygons, tiles })
			}
			if (!infoWindowRef.current) {
				infoWindowRef.current = new google.maps.InfoWindow()
			}
		}, [])

		// 📦 Expose updateMap() to parent via ref
		useImperativeHandle(ref, () => ({
			updateMap,
		}))

		return <div ref={mapContainer} style={style} />
	},
)

GoogleMap.displayName = 'GoogleMap'

function createStyledMarker(color: string): HTMLElement {
	const div = document.createElement('div')
	div.style.width = '16px'
	div.style.height = '16px'
	div.style.borderRadius = '50%'
	div.style.backgroundColor = color
	div.style.border = '2px solid white'
	div.style.boxShadow = '0 0 4px rgba(0,0,0,0.3)'
	return div
}

function createToolTip(
	color: string,
	title: string,
	dict: Record<string, string> = {},
): { body: HTMLElement; header: HTMLElement } {
	// Create styled header
	const header = document.createElement('div')
	header.innerText = title
	header.style.fontWeight = 'bold'
	header.style.color = color
	header.style.fontSize = '13px'

	// Create body
	const body = document.createElement('div')
	body.innerText = Object.entries(dict)
		.map(([key, value]) => `${formatKey(key)}: ${value}`)
		.join('\n')
	body.style.fontSize = '12px'
	body.style.fontWeight = 'normal'
	body.style.marginTop = '4px'
	body.style.color = '#555'

	// Set both
	return { header, body }
}

function formatKey(str: string): string {
	return str.replace(/_/g, ' ').charAt(0).toUpperCase() + str.slice(1)
}

export { GoogleMap }
