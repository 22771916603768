import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { RadiusValues, TenureTypesByCaQuery } from '../../graphql/queries/__generated__/TenureTypesByCaQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { TenureTypesByCa } from '../../graphql/queries/TenureTypesByCa'
import { Model } from '../../shared/charts/types'
import { TenureTypePieChart } from './TenureTypePieChart'

interface CaTenureTypesChartProps {
	oaCode: string
	radius: RadiusValues
	aspectRatio?: number
}

const filteredTenureTypes = ['Owned', 'Social rented', 'Private rented']

const CaTenureTypesPieChart: React.FC<CaTenureTypesChartProps> = ({ oaCode, radius, aspectRatio }) => {
	const { tenureTypesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<TenureTypesByCaQuery>(
		TenureTypesByCa,
		{ oaCode, radius },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const indexMap = new Map<string, number>()
	filteredTenureTypes.forEach((label) => {
		const index = labels.indexOf(label)
		indexMap.set(label, index)
	})

	const model: Model = {
		labels: filteredTenureTypes,
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment area',
			values: filteredTenureTypes.map((label) => d.values[indexMap.get(label) || 0]),
		})),
	}

	return <TenureTypePieChart model={model} aspectRatio={aspectRatio} />
}

export { CaTenureTypesPieChart }
