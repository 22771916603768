import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	GrossDisposableHouseholdIncomesByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { HousePricesByCaQuery as HousePricesResponse } from '../../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByCa } from '../../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { HousePricesByCa } from '../../graphql/queries/HousePricesByCa'
import { calculateYearsToBuy } from '../../utils/calculateYearsToBuy'
import { HousingYearsToBuyChart } from './HousingYearsToBuyChart'

interface CaHousingYearsToBuyChartProps {
	oaCode: string
	radius: RadiusValues
	aspectRatio?: number
}

const CaHousingYearsToBuyChart: React.FC<CaHousingYearsToBuyChartProps> = ({ oaCode, radius, aspectRatio }) => {
	const { housePricesByCa: hp } = useLazyLoadQuery<HousePricesResponse>(HousePricesByCa, { oaCode, radius })

	const { grossDisposableHouseholdIncomesByCa: gdhi } = useLazyLoadQuery<GrossDisposableHouseholdIncomesByCaQuery>(
		GrossDisposableHouseholdIncomesByCa,
		{
			oaCode,
			radius,
		},
	)

	const codes = hp.datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model = calculateYearsToBuy(hp, gdhi, geos)

	return <HousingYearsToBuyChart model={model} aspectRatio={aspectRatio} />
}

export { CaHousingYearsToBuyChart }
