import { RadiusValues } from './graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'

const admins = [
	'auth0|6720b27b5cee3153df5513a5', // Brendon Dev
	'auth0|6720b27b598081a7d03ba495', // Brendon Production

	'auth0|6720b27b79a76c27c9514c89', // Hans Staging
	'auth0|6720b27b5cee3153df5513a6', // Hans Production
]

const currentYear = 2024

const elderlySpecialismKey = 'caring_for_adults_over_65_yrs'
const dementiaSpecialismKey = 'dementia'

const residentialHomesKey = 'residential_homes'
const nursingHomesKey = 'nursing_homes'
export type ServiceType = typeof residentialHomesKey | typeof nursingHomesKey

const defaultLad = 'E08000008'

export type Ca = {
	lat: number
	long: number
	radius: RadiusValues
}

const defaultCa: Ca = {
	lat: 51.54185,
	long: 0.078038,
	radius: 'one',
}

const siteRatingPoints = {
	Outstanding: 1,
	Good: 2 / 3,
	'Requires improvement': 1 / 3,
	Inadequate: 0,
}

const radiusValues: Record<RadiusValues, RadiusValues> = {
	one: 'one',
	two: 'two',
	three: 'three',
	four: 'four',
	five: 'five',
	six: 'six',
	seven: 'seven',
	eight: 'eight',
	nine: 'nine',
	ten: 'ten',
	'%future added value': 'one', // this is because of the generated keys
}

const milesRadiusValueInInts: Record<RadiusValues, number> = {
	one: 1,
	two: 2,
	three: 3,
	four: 4,
	five: 5,
	six: 6,
	seven: 7,
	eight: 8,
	nine: 9,
	ten: 10,
	'%future added value': 0, // this is because of the generated keys
}

const milesRadiusValueInMeters: Record<RadiusValues, number> = {
	one: 1609,
	two: 3218,
	three: 4828,
	four: 6437,
	five: 8046,
	six: 9656,
	seven: 11265,
	eight: 12875,
	nine: 14484,
	ten: 16093,
	'%future added value': 0, // this is because of the generated keys
}

enum GeographyTypes {
	lad = 'lad',
	region = 'region',
	country = 'country',
}

enum Specialism {
	elderly = 'elderly',
	dementia = 'dementia',
}

enum CareFundingType {
	community = 'community',
	careHome = 'care_home',
}

const awardTypesLabelMap: { [key: string]: string } = { lowerRate: 'Part-Time', higherRate: 'Full-time' }

// Market scoring threshold values (based on quintile analysis of local authority data)
const elderlyCareScores = {
	elderly: {
		min: 0.021584,
		q20: 0.060716,
		q40: 0.06971,
		q60: 0.078081,
		q80: 0.08921,
		max: 0.156882,
		sample_size: 287,
	},
	residential: {
		min: 0.000458,
		q20: 0.024646,
		q40: 0.032456,
		q60: 0.039601,
		q80: 0.048808,
		max: 0.090501,
		sample_size: 287,
	},
	nursing: {
		min: 0.005026,
		q20: 0.024402,
		q40: 0.032941,
		q60: 0.039571,
		q80: 0.047683,
		max: 0.121245,
		sample_size: 287,
	},
	domiciliary: {
		min: 0.0,
		q20: 0.06691,
		q40: 0.081623,
		q60: 0.101541,
		q80: 0.133644,
		max: 0.417224,
		sample_size: 286,
	},
	dementia: {
		min: 0.108706,
		q20: 0.363537,
		q40: 0.437366,
		q60: 0.497968,
		q80: 0.564005,
		max: 1.088021,
		sample_size: 287,
	},
}

const omaMatchingWeights = {
	careTypeScore: 0.2,
	growthScore: 0.2,
	geoScore: 0.2,
	cqcRatingScore: 0.2,
	careHomeSizeScore: 0.2,
}

//National data from: Knight Frank, UK Care Homes Trading Performance Review, 2024, https://content.knightfrank.com/research/548/documents/en/care-homes-trading-performance-review-2024-11762.pdf
const knightFrank = {
	hourlyWages: {
		residential: 11.48,
		nursing: 18.4,
	},
	annualStaffingCostPerBed: {
		all: 35299,
		residential: 32405,
		nursing: 40730,
	},
	privateBedFees: {
		residential: 1253,
		nursing: 1594,
		residentialStateToPrivate: 1.49344,
		nursingStateToPrivate: 1.47729,
	},
	overheadCost: {
		totalPerBed: 6016,
		totalNursingPerBed: 6924,
		totalResidentialPerBed: 5509,
		propertyPerBed: 3794,
		nursingFoodPerBed: 1970,
		residentialFoodPerBed: 1960,
		propertyExtraCarePerBed: 0.5 * 3794,
		overheadStaffingRatio: 0.15,
	},
	occupancy: {
		residential: 0.88,
		nursing: 0.885,
	},
	cqcMargins: {
		baseCqcMargin: 0.02, // 2% minimum (Inadequate)
		rangeCqcMargin: 0.28, // up to +28% for Outstanding
		projectRating: 5 / 6, // (between Good and Outstanding)
	},
}

const staffResidentRatio = {
	homecare: 1.2,
}

const valuationProxies = {
	rentTurnoverRatio: 0.2,
	occupancy: 0.9,
	inflationAnnual: 0.03,
	rentToTurnoverRatio: 0.2,
	rentCover: 1.8,
	nursingShareMixed: 0.5,
}

export {
	admins,
	awardTypesLabelMap,
	CareFundingType,
	currentYear,
	defaultCa,
	defaultLad,
	dementiaSpecialismKey,
	elderlyCareScores,
	elderlySpecialismKey,
	GeographyTypes,
	knightFrank,
	milesRadiusValueInInts,
	milesRadiusValueInMeters,
	nursingHomesKey,
	omaMatchingWeights,
	radiusValues,
	residentialHomesKey,
	siteRatingPoints,
	Specialism,
	staffResidentRatio,
	valuationProxies,
}
