import './AppRoutes.css'

import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Admin } from '../pages/admin/Admin'
import { Agent } from '../pages/agent/Agent'
import { CatchmentArea, ProjectCatchmentArea } from '../pages/ca/Ca'
import { Lad } from '../pages/lad/Lad'
import { Lads } from '../pages/lads/Lads'
import { Me } from '../pages/me/Me'
import { Oma } from '../pages/oma/Oma'
import { OmaData } from '../pages/oma/OmaData'
import { Operator } from '../pages/operator/Operator'
import { OperatorGroup } from '../pages/operatorGroup/OperatorGroup'
import { OperatorGroups } from '../pages/operatorGroups/OperatorGroups'
import { Operators } from '../pages/operators/Operators'
import { CreateProjectPage } from '../pages/projects/CreateProject'
import { Projects } from '../pages/projects/Projects'
import { UpdateProjectPage } from '../pages/projects/UpdateProject'
import { MvuData } from '../pages/report/mvuData/MvuData'
import { Report } from '../pages/report/Report'
import { Site } from '../pages/site/Site'
import { Sites } from '../pages/sites/Sites'
import { MainLayout } from './MainLayout'

const AppRoutes: React.FC = () => (
	<Router
		future={{
			v7_relativeSplatPath: true,
			v7_startTransition: true,
		}}
	>
		<Routes>
			<Route element={<MainLayout />}>
				<Route path="/" element={<Lad />} />
				<Route path="/agent" element={<Agent />} />
				<Route path="/admin" element={<Admin />} />
				<Route path="/ca" element={<CatchmentArea />} />
				<Route path="/ca/:latLong" element={<CatchmentArea />} />
				<Route path="/ca/:latLong/:radius" element={<CatchmentArea />} />
				<Route path="/ca/:latLong/:radius/:category" element={<CatchmentArea />} />
				<Route path="/ca/:latLong/:radius/:category/:tab" element={<CatchmentArea />} />
				<Route path="/groups" element={<OperatorGroups />} />
				<Route path="/group/:id" element={<OperatorGroup />} />
				<Route path="/lads" element={<Lads />} />
				<Route path="/lad" element={<Lad />} />
				<Route path="/lad/:ladCode" element={<Lad />} />
				<Route path="/lad/:ladCode" element={<Lad />} />
				<Route path="/lad/:ladCode/:category" element={<Lad />} />
				<Route path="/lad/:ladCode/:category/:tab" element={<Lad />} />
				<Route path="/me" element={<Me />} />
				<Route path="/operators" element={<Operators />} />
				<Route path="/operator/:id" element={<Operator />} />
				<Route path="/projects" element={<Projects />} />
				<Route path="/project" element={<ProjectCatchmentArea />} />
				<Route path="/project/:reference" element={<ProjectCatchmentArea />} />
				<Route path="/project/:reference/:radius" element={<ProjectCatchmentArea />} />
				<Route path="/project/:reference/:radius/:category" element={<ProjectCatchmentArea />} />
				<Route path="/project/:reference/:radius/:category/:tab" element={<ProjectCatchmentArea />} />
				<Route path="/project/create" element={<CreateProjectPage />} />
				<Route path="/project/update/:reference" element={<UpdateProjectPage />} />
				<Route path="/report/:type/:reference" element={<Report />} />
				<Route path="/sites" element={<Sites />} />
				<Route path="/site/:id" element={<Site />} />
				<Route path="/data/oma/:reference" element={<OmaData />} />
				<Route path="/data/mvy/:reference" element={<MvuData />} />
				<Route path="/data/:type/:reference/:beds" element={<MvuData />} />
				<Route path="/oma/:reference" element={<Oma />} />
			</Route>
		</Routes>
	</Router>
)

export { AppRoutes }
