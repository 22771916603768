import graphql from 'babel-plugin-relay/macro'

export const Prompt = graphql`
	query PromptQuery($content: String!, $key: String!) {
		prompt(content: $content, key: $key) {
			response
			version
		}
	}
`
