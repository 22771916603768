import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { SocialGradesByLadCodeQuery } from '../../graphql/queries/__generated__/SocialGradesByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { SocialGradesByLadCode } from '../../graphql/queries/SocialGradesByLad'
import { Model } from '../../shared/charts/types'
import { SocialGradeChart } from './SocialGradeChart'

interface LadSocialGradeChartProps {
	ladCode: string
	aspectRatio?: number
}

const LadSocialGradeChart: React.FC<LadSocialGradeChartProps> = ({ ladCode, aspectRatio }) => {
	const { socialGradesByLadCode: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<SocialGradesByLadCodeQuery>(
		SocialGradesByLadCode,
		{ ladCode },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <SocialGradeChart model={model} aspectRatio={aspectRatio} />
}

export { LadSocialGradeChart }
