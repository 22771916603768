import { PropertiesByLatLongQuery$data } from '../graphql/queries/__generated__/PropertiesByLatLongQuery.graphql'

export type GraphQlProperties = PropertiesByLatLongQuery$data['propertiesByLatLong']
export type GraphQlProperty = PropertiesByLatLongQuery$data['propertiesByLatLong']['features'][0]

export interface Property {
	readonly geometry: {
		readonly coordinates: number[][][] | number[][][][]
		readonly type: string
	}
	readonly id: string
	readonly properties: {
		id: string
		readonly aonbIds: ReadonlyArray<string>
		readonly area: number
		readonly brownfieldIds: ReadonlyArray<string>
		readonly floodRiskZoneIds: ReadonlyArray<string>
		readonly greenBeltIds: ReadonlyArray<string>
		readonly listedBuildingIds: ReadonlyArray<string>
		readonly sitesOfSpecialScientificInterestIds: ReadonlyArray<string>
		readonly specialProtectionAreaIds: ReadonlyArray<string>
		readonly treePreservationZoneIds: ReadonlyArray<string>
	}
	readonly type: string
}

const createPropertyModel = (gp: GraphQlProperty): Property => {
	return {
		geometry: gp.geometry,
		id: gp.id,
		properties: {
			id: gp.id,
			...gp.properties,
		},
		type: gp.type,
	}
}

export { createPropertyModel }
