import React from 'react'
import * as i from 'react-feather'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { AddOperatorToGroupButton } from '../../actions/AddOperatorToGroup'
import { RemoveOperatorFromGroupButton } from '../../actions/RemoveOperatorFromGroup'
import { OperatorByIdQuery } from '../../graphql/queries/__generated__/OperatorByIdQuery.graphql'
import { OperatorById } from '../../graphql/queries/OperatorById'
import { Page } from '../../layout/page/Page'
import { LeftAligned, SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { Heading, PageTitle } from '../../shared/Text'
import { OperatorFragment$key } from './__generated__/OperatorFragment.graphql'
import { OperatorFragment } from './OperatorFragment'

type RouteParams = { id: string }

const Operator: React.FC = () => {
	const { id = '' } = useParams<RouteParams>() // Get the id from the URL

	const { operatorById: operatorData } = useLazyLoadQuery<OperatorByIdQuery>(OperatorById, { id })

	const op = useFragment<OperatorFragment$key>(OperatorFragment, operatorData)

	const hasGroup = !!(op.operatorGroupId && op.operatorGroupId !== '')

	const numberOfBeds = op.sites.reduce((acc, s) => acc + (s.numberOfBeds ?? 0), 0)

	return (
		<Page title={`Operator - ${op.name}`}>
			<Container>
				<VerticalSpace size="md" />
				<Row>
					<Col count={12}>
						<SpaceBetween>
							<PageTitle>{op.name}</PageTitle>
							{hasGroup && <RemoveOperatorFromGroupButton operatorId={op.id} groupId={op.operatorGroupId} />}
							{!hasGroup && <AddOperatorToGroupButton operatorId={op.id} />}
						</SpaceBetween>
					</Col>
				</Row>
				<VerticalSpace size="md" />
				<Row>
					<Col count={6}>
						<Panel padding={16}>
							<div className="list-group">
								<OperatorRow label="Name" icon={<i.User />}>
									{op.name}
								</OperatorRow>
								<OperatorRow label="Operator Group" icon={<i.Users />}>
									<a href={`/group/${op.operatorGroupId}`}>{op.operatorGroupName}</a>
								</OperatorRow>
								<OperatorRow label="Companies House Number" icon={<i.Home />}>
									{op.companiesHouseNumber}
								</OperatorRow>

								<OperatorRow label="Main Phone Number" icon={<i.Phone />}>
									{op.mainPhoneNumber}
								</OperatorRow>

								<OperatorRow label="Postal Address" icon={<i.Mail />}>
									{`${op.postalAddressLine1} ${op.postalAddressLine2} ${op.postalAddressTownCity} ${op.postalCode}`}
								</OperatorRow>

								<OperatorRow label="Registration Date" icon={<i.Calendar />}>
									{new Date(op.registrationDate).toLocaleDateString()}
								</OperatorRow>

								<OperatorRow label="Type" icon={<i.List />}>
									{op.type}
								</OperatorRow>

								<OperatorRow label="Website" icon={<i.Globe />}>
									<a href={`http://${op.website}`} target="_blank" rel="noopener noreferrer">
										{op.website}
									</a>
								</OperatorRow>

								<OperatorRow label="Number of sites" icon={<i.List />}>
									{op.sites.length}
								</OperatorRow>
								<OperatorRow label="Number of bed" icon={<i.Home />}>
									{numberOfBeds}
								</OperatorRow>
							</div>
						</Panel>
					</Col>
					<Col count={6}>
						<Panel padding={16}>
							<div className="list-group">
								<div className="list-group-item">
									<Heading level={6}>Sites</Heading>
								</div>
								{op.sites.map((s) => (
									<div key={s.id} className="list-group-item">
										<SpaceBetween>
											<a href={`/site/${s.id}`}>{s.name}</a>
										</SpaceBetween>
									</div>
								))}
							</div>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

const OperatorRow = ({
	label,
	children,
	icon,
}: {
	label: string
	children: React.ReactNode
	icon: React.ReactNode
}) => (
	<div className="list-group-item">
		<LeftAligned>
			<div>{icon}</div>
			<HorizontalSpace size="xs" />
			<div>
				<strong>{label}:</strong> {children}
			</div>
		</LeftAligned>
	</div>
)

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Operator />
		</React.Suspense>
	)
}

export { Suspended as Operator }
