import React, { useState } from 'react'

import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../shared/layout/Space'
import { Panel } from '../../shared/Panel'
import { themeColors } from '../../Theme'

interface PromptBarProps {
	onSubmit: (input: string) => void
	isLoading: boolean
	maxWidth?: number
}

export const PromptBar: React.FC<PromptBarProps> = ({ onSubmit, isLoading, maxWidth = 800 }) => {
	const [inputValue, setInputValue] = useState('')

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		onSubmit(inputValue)
		setInputValue('')
	}

	const background = `linear-gradient(to top, ${themeColors.canvas} 50%, transparent)`
	return (
		<div
			style={{
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				height: 98,
				zIndex: 10,
			}}
		>
			<Container>
				<div style={{ background }}>
					<VerticalSpace size="md" />
					<Row>
						<div className="mx-auto" style={{ maxWidth, height: 50 }}>
							<Panel padding={8}>
								<form onSubmit={handleSubmit} className="form-inline">
									<SpaceBetween>
										<input
											type="text"
											className="form-control"
											value={inputValue}
											onChange={(e) => setInputValue(e.target.value)}
											placeholder="Ask anything..."
											style={{
												border: 'none',
												outline: 'none',
												boxShadow: 'none',
											}}
										/>
										<HorizontalSpace size="xs" />
										<button type="submit" className="btn btn-dark" disabled={isLoading}>
											{isLoading ? 'Thinking...' : 'Ask'}
										</button>
									</SpaceBetween>
								</form>
							</Panel>
						</div>
					</Row>
					<Row>
						<VerticalSpace size="md" />
					</Row>
				</div>
			</Container>
		</div>
	)
}
