/**
 * @generated SignedSource<<b587b3af96129e87fb31e34cfb70bcdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyBedShortagesForecastChartSiteFragment$data = ReadonlyArray<{
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly specialisms: ReadonlyArray<string>;
  readonly " $fragmentType": "ElderlyBedShortagesForecastChartSiteFragment";
}>;
export type ElderlyBedShortagesForecastChartSiteFragment$key = ReadonlyArray<{
  readonly " $data"?: ElderlyBedShortagesForecastChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedShortagesForecastChartSiteFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ElderlyBedShortagesForecastChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "aaded6cbca4a2e392f31ccbab26b1c1c";

export default node;
