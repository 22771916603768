import * as turf from '@turf/turf'

export interface Point {
	latitude: number
	longitude: number
}

function calculateDistance(point1: Point, point2: Point) {
	const dist = turf.distance([point1.longitude, point1.latitude], [point2.longitude, point2.latitude], {
		units: 'miles',
	})
	return dist
}

export { calculateDistance }
