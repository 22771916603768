import graphql from 'babel-plugin-relay/macro'

export const ParentsByCode = graphql`
	query ParentsByCodeQuery($code: String!) {
		parentsByCode(code: $code) {
			code
			parentCode
			name
			type
		}
	}
`
