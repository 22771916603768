import { ElderlyBedShortagesForecastChartSiteFragment$data } from '../charts/ElderlyBedShortagesForecastChart/__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'
import { AAAwardTypesByLadCodeQuery$data } from '../graphql/queries/__generated__/AAAwardTypesByLadCodeQuery.graphql'
import { calculateBedDemandData } from './calculateBedDemand'
import { calculateBedSupplyData, SitesData } from './calculateBedSupplyData'
import { forecastData } from './forecastData'

// Helper function to process Bed Demand
function processBedDemand(
	aaData: AAAwardTypesByLadCodeQuery$data['aaAwardTypesByLadCode'],
	historicalYears: number[],
	forecastYears: number[],
) {
	const historical = calculateBedDemandData(aaData, historicalYears)
	const forecast = forecastData(historical, forecastYears)
	return { ...historical, ...forecast }
}

// Helper function to process Bed Supply
function processBedSupply(
	sitesData: ElderlyBedShortagesForecastChartSiteFragment$data,
	historicalYears: number[],
	forecastYears: number[],
) {
	// 1. Convert to local typed array
	const allSites: SitesData[] = sitesData.map((site) => ({
		registrationDate: site.registrationDate,
		numberOfBeds: site.numberOfBeds,
		specialisms: [...(site.specialisms || [])],
	}))

	// 3. Calculate historical supply
	const historical = calculateBedSupplyData(allSites, historicalYears)
	// 4. Forecast supply
	const forecast = forecastData(historical, forecastYears)

	return { ...historical, ...forecast }
}

function getBedSupplyAndDemandHistoricalForecast(
	aaData: AAAwardTypesByLadCodeQuery$data['aaAwardTypesByLadCode'],
	sitesData: ElderlyBedShortagesForecastChartSiteFragment$data,
	displayStartYear: number,
	displayEndYear: number,
) {
	// Define historical and forecast years
	const bedSupplyHistoricalYears = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
	const bedSupplyForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	const bedDemandHistoricalYears = [2019, 2020, 2021, 2022, 2023, 2024]
	const bedDemandForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	// Process Bed Demand and Bed Supply
	const bedDemandCombined = processBedDemand(aaData, bedDemandHistoricalYears, bedDemandForecastYears)
	const bedSupplyCombined = processBedSupply(sitesData, bedSupplyHistoricalYears, bedSupplyForecastYears)

	// Combine all years from both datasets
	const allYearsSet = new Set<number>([
		...bedSupplyHistoricalYears,
		...bedSupplyForecastYears,
		...bedDemandHistoricalYears,
		...bedDemandForecastYears,
	])
	const allYears = Array.from(allYearsSet).sort((a, b) => a - b)

	// Calculate Bed Shortage (Supply - Demand)
	const bedShortageCombined: { [year: number]: number } = {}
	allYears.forEach((year) => {
		const supply = bedSupplyCombined[year] || 0
		const demand = bedDemandCombined[year] || 0
		bedShortageCombined[year] = supply - demand
	})

	// Determine which years are forecasted
	const forecastYearSet = new Set<number>([...bedSupplyForecastYears, ...bedDemandForecastYears])

	// Apply display range filter
	const filteredYears = allYears.filter((year) => year >= displayStartYear && year <= displayEndYear)

	// Prepare labels with 'E' prefix for forecasted years
	const labels = filteredYears.map((year) => (forecastYearSet.has(year) ? `E${year}` : `${year}`))

	// Prepare datasets
	const datasets = [
		{
			id: 'Bed Demand',
			values: filteredYears.map((year) => bedDemandCombined[year] || null),
		},
		{
			id: 'Bed Supply',
			values: filteredYears.map((year) => bedSupplyCombined[year] || null),
		},
		{
			id: 'Bed Shortage',
			values: filteredYears.map((year) => bedShortageCombined[year] || null),
		},
	]

	return {
		labels,
		datasets,
	}
}

export { getBedSupplyAndDemandHistoricalForecast, processBedDemand, processBedSupply }
