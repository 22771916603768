import React, { useState } from 'react'
import * as i from 'react-feather'
import { useMutation } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { DeleteProject } from '../graphql/queries/DeleteProject'
import { DangerButton, SecondaryButton, SecondaryOutlineButton } from '../shared/Button'
import { Modal } from '../shared/modal/Modal'

interface DeleteProjectButtonProps {
	projectId: string
}

const DeleteProjectButton: React.FC<DeleteProjectButtonProps> = ({ projectId }) => {
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const [commitMutation, isMutationInFlight] = useMutation(DeleteProject)

	const handleShow = () => setShowModal(true)
	const handleClose = () => setShowModal(false)

	const handleConfirm = () => {
		commitMutation({
			variables: {
				id: projectId,
			},
			onCompleted: () => {
				handleClose()
				navigate('/projects')
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	return (
		<>
			<SecondaryOutlineButton onClick={handleShow}>
				<i.Trash size={14} />
			</SecondaryOutlineButton>

			<Modal
				isOpen={showModal}
				onClose={handleClose}
				title="Confirm Deletion"
				footer={
					<>
						<SecondaryButton onClick={handleClose} disabled={isMutationInFlight}>
							Cancel
						</SecondaryButton>
						<DangerButton onClick={handleConfirm} disabled={isMutationInFlight}>
							<i.Trash size={14} />
						</DangerButton>
					</>
				}
			>
				<p>Are you sure you want to remove this project? This action cannot be undone.</p>
			</Modal>
		</>
	)
}

export { DeleteProjectButton }
