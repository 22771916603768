import React, { useMemo } from 'react'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import { ProjectByReferenceQuery } from '../../graphql/queries/__generated__/ProjectByReferenceQuery.graphql'
import { ProjectByReference } from '../../graphql/queries/ProjectByReference'
import { UpdateProject } from '../../graphql/queries/UpdateProject'
import { Page } from '../../layout/page/Page'
import { createProjectModel } from '../../models/project'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { t } from '../../utils/localisation'
import { FormData, ProjectForm } from './ProjectForm'

const UpdateProjectPage: React.FC = () => {
	const [commitMutation, isMutationInFlight] = useMutation(UpdateProject)
	const navigate = useNavigate()
	const { reference = '' } = useParams<{ reference: string }>()

	const { projectByReference } = useLazyLoadQuery<ProjectByReferenceQuery>(ProjectByReference, { id: reference })
	const project = useMemo(() => createProjectModel(projectByReference), [projectByReference])

	const initialFormData: FormData = useMemo(
		() => ({
			name: project.name,
			client: project.client,
			date: project.date,
			numberOfBeds: project.settings.numberOfBeds || 0,
			numberOfExtraCareUnits: project.settings.numberOfExtraCareUnits || 0,
			postCode: project.postalCode,
			latitude: project.latitude,
			longitude: project.longitude,
			zoom: project.settings.mapZoom || 16,
			properties: project.properties.map((p) => ({
				id: p.id,
				area: p.properties.area,
			})),
			status: project.status,
		}),
		[project],
	)

	const onSubmit = (formData: FormData) => {
		const parsedDate = new Date(formData.date)

		commitMutation({
			variables: {
				id: project.id,
				input: {
					reference,
					client: formData.client,
					date: parsedDate.toISOString(),
					latitude: formData.latitude,
					longitude: formData.longitude,
					name: formData.name,
					propertyIds: formData.properties.map((property) => property.id),
					status: formData.status,
					settings: JSON.stringify({
						...project.settings,
						mapZoom: formData.zoom,
						numberOfBeds: formData.numberOfBeds,
						numberOfExtraCareUnits: formData.numberOfExtraCareUnits,
					}),
				},
			},
			onCompleted: () => {
				navigate('/projects')
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	return (
		<Page title={t('update_project.page_title')}>
			<Container>
				<VerticalSpace size="md" />
				<ProjectForm
					initialFormData={initialFormData}
					isMutationInFlight={isMutationInFlight}
					formLabel={t('update_project.form_title')}
					buttonLabel={t('update_project.submit_button')}
					onSubmit={onSubmit}
				/>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<UpdateProjectPage />
		</React.Suspense>
	)
}

export { Suspended as UpdateProjectPage }
