import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

import { Paragraph } from './Text'

// RowData interface
export interface RowData {
	id: string
}

// Make DataTableProps accept a generic RowData type
interface DataTableProps<T> {
	columns: ColumnDef<T, any>[] // Use the generic RowData type
	data: ReadonlyArray<T> // Use the generic RowData type
	onRowClick?: (id: string) => void
	fontSize?: number // Allow font size to be passed as a prop
	stickyHeader?: boolean
	stickyColumn?: boolean
	wrapHeaders?: boolean
	wrapCells?: boolean
}

// Use generic T for DataTable
const DataTable = <T extends RowData>({
	columns,
	data,
	onRowClick,
	fontSize = 13,
	stickyHeader = false,
	stickyColumn = false,
	wrapHeaders = false,
	wrapCells = false,
}: DataTableProps<T>) => {
	const [sorting, setSorting] = React.useState<SortingState>([])

	const memoizedColumns = React.useMemo(() => columns, [columns])
	const memoizedData = React.useMemo(() => [...data], [data])

	const table = useReactTable({
		data: memoizedData,
		columns: memoizedColumns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	})

	return (
		<table className="table table-hover table" style={{ fontSize: fontSize }}>
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => {
							const sorted = header.column.getIsSorted()
							return (
								<th
									key={header.id}
									colSpan={header.colSpan}
									onClick={header.column.getToggleSortingHandler()}
									style={{
										cursor: 'pointer',
										userSelect: 'none',
										position: stickyHeader ? 'sticky' : 'static',
										top: 50,
										background: 'white',
										zIndex: 1, // Make sure the header stays on top of the table rows
										fontSize, // Apply font size to header
										whiteSpace: wrapHeaders ? 'normal' : 'nowrap', // Allow header text to wrap if wrapHeaders is true
									}}
								>
									{flexRender(header.column.columnDef.header, header.getContext())}
									<span style={{ marginLeft: '5px' }}>
										{sorted === 'asc' && <ChevronUp size={13} />}
										{sorted === 'desc' && <ChevronDown size={13} />}
									</span>
								</th>
							)
						})}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr
						key={row.id}
						onClick={() => onRowClick && onRowClick(row.original.id)}
						style={{ cursor: onRowClick ? 'pointer' : 'default' }}
					>
						{row.getVisibleCells().map((cell, index) => (
							<td
								key={cell.id}
								style={{
									fontSize,
									position: stickyColumn && index === 0 ? 'sticky' : 'static', // Sticky first column only if stickyColumn is true
									left: stickyColumn && index === 0 ? 0 : 'auto', // Make first column sticky horizontally
									zIndex: 0, // Ensure that first column remains under the header
									whiteSpace: wrapCells ? 'normal' : 'nowrap', // Allow cell text to wrap if wrapCells is true
								}}
							>
								{renderCellContent(cell)}
							</td> // Apply font size to cells
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

// Helper function to handle cell content rendering
const renderCellContent = (cell: any) => {
	const cellValue = cell.getValue()

	// Check if the column has a custom cell rendering function
	if (cell.column.columnDef.cell) {
		// If there is a custom cell renderer, use it
		return flexRender(cell.column.columnDef.cell, cell.getContext())
	}

	// Default rendering when no custom cell renderer exists
	// If value is null, render '-'
	if (cellValue === null || cellValue === undefined) {
		return <Paragraph>-</Paragraph>
	}

	// If the value is not a JSX element, wrap it in a <Paragraph>
	if (typeof cellValue === 'string' || typeof cellValue === 'number' || typeof cellValue === 'boolean') {
		return <Paragraph>{cellValue}</Paragraph>
	}

	// Otherwise, render the value directly if it's already JSX or another valid type
	return flexRender(cell.column.columnDef.cell, cell.getContext())
}

export { DataTable }
