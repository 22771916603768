/**
 * @generated SignedSource<<1a51c8ab3535f32bd7543d80db6ccecd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LocalAuthoritiesByLadsQuery$variables = {
  ladCodes: ReadonlyArray<string>;
};
export type LocalAuthoritiesByLadsQuery$data = {
  readonly localAuthoritiesByLadCodes: ReadonlyArray<{
    readonly countryCode: string;
    readonly countryName: string;
    readonly geometry: string;
    readonly ladCode: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string;
    readonly regionCode: string;
    readonly regionName: string;
  }>;
};
export type LocalAuthoritiesByLadsQuery = {
  response: LocalAuthoritiesByLadsQuery$data;
  variables: LocalAuthoritiesByLadsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCodes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCodes",
        "variableName": "ladCodes"
      }
    ],
    "concreteType": "LocalAuthority",
    "kind": "LinkedField",
    "name": "localAuthoritiesByLadCodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "geometry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countryName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countryCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthoritiesByLadsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocalAuthoritiesByLadsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6999e505a826f27c0835a1136e777b7b",
    "id": null,
    "metadata": {},
    "name": "LocalAuthoritiesByLadsQuery",
    "operationKind": "query",
    "text": "query LocalAuthoritiesByLadsQuery(\n  $ladCodes: [String!]!\n) {\n  localAuthoritiesByLadCodes(ladCodes: $ladCodes) {\n    ladCode\n    latitude\n    longitude\n    name\n    geometry\n    regionName\n    regionCode\n    countryName\n    countryCode\n  }\n}\n"
  }
};
})();

(node as any).hash = "40a0ad79519eb4cefa48aee9273d7752";

export default node;
