import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DataTable, RowData } from '../../shared/DataTable'
import { calculateOperatorMetrics } from '../../utils/calculateOperatorMetrics'
import { OperatorsOperatorFragment$data } from './__generated__/OperatorsOperatorFragment.graphql'

interface AllOperatorsTableProps {
	operators: OperatorsOperatorFragment$data[]
}

const AllOperatorsTable: React.FC<AllOperatorsTableProps> = ({ operators }) => {
	const navigate = useNavigate()

	const columns = React.useMemo<ColumnDef<RowData, any>[]>(
		() => [
			{
				header: 'Name',
				accessorKey: 'name',
			},
			{
				header: 'Group Name',
				accessorKey: 'operatorGroupName',
			},
			{
				header: 'Specialisms',
				accessorKey: 'specialismsUniqueAggregatedList',
			},
			{
				header: 'Total Beds',
				accessorKey: 'currentBedsCountTotal',
			},
			{
				header: 'Care Facilities in England',
				accessorKey: 'currentSitesCount',
			},
			{
				header: 'Local Authorities active',
				accessorKey: 'lasActiveListCount',
			},
			{
				header: 'Regions active',
				accessorKey: 'regionsActiveList',
			},
			{
				header: 'Ratings',
				accessorKey: 'currentRatingsMean',
			},
			{
				header: 'New Sites p.a.',
				accessorKey: 'avgNewSitesPerYear',
			},
			{
				header: 'Sites 3-Year CAGR',
				accessorKey: 'sitesCAGRDisplay',
			},
			{
				header: 'New Local Authorities p.a.',
				accessorKey: 'avgNewLasPerYear',
			},
			{
				header: 'New Beds p.a.',
				accessorKey: 'avgNewBedsPerYear',
			},
			{
				header: 'Beds 3-Year CAGR',
				accessorKey: 'bedsCAGRDisplay',
			},
		],
		[],
	)

	const formattedData: RowData[] = operators.map((op) => calculateOperatorMetrics(op))

	const handleRowClick = (id: string) => {
		navigate(`/operator/${id}`)
	}

	return (
		<DataTable columns={columns} data={formattedData} onRowClick={handleRowClick} wrapCells={true} wrapHeaders={true} />
	)
}

export { AllOperatorsTable }
