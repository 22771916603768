/**
 * @generated SignedSource<<cbadf5e8e160dc06d6cb82d961d944b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorFragment$data = {
  readonly alias: string | null | undefined;
  readonly companiesHouseNumber: string | null | undefined;
  readonly contacts: string | null | undefined;
  readonly deregistrationDate: any | null | undefined;
  readonly emails: string | null | undefined;
  readonly employees: string | null | undefined;
  readonly id: string;
  readonly linkedinId: string | null | undefined;
  readonly mainPhoneNumber: string | null | undefined;
  readonly name: string;
  readonly operatorGroupId: string | null | undefined;
  readonly operatorGroupName: string | null | undefined;
  readonly postalAddressCounty: string | null | undefined;
  readonly postalAddressLine1: string | null | undefined;
  readonly postalAddressLine2: string | null | undefined;
  readonly postalAddressTownCity: string;
  readonly postalCode: string;
  readonly registrationDate: any | null | undefined;
  readonly sites: ReadonlyArray<{
    readonly emails: string | null | undefined;
    readonly employees: string | null | undefined;
    readonly hasActiveRegistration: boolean;
    readonly id: string;
    readonly isCareHome: boolean;
    readonly ladCode: string;
    readonly latitude: number | null | undefined;
    readonly linkedinId: string | null | undefined;
    readonly location: string | null | undefined;
    readonly longitude: number | null | undefined;
    readonly mainPhoneNumber: string | null | undefined;
    readonly name: string;
    readonly numberOfBeds: number | null | undefined;
    readonly operatorId: string;
    readonly operatorName: string;
    readonly organisationType: string | null | undefined;
    readonly phoneNumber: string | null | undefined;
    readonly postalAddressCounty: string | null | undefined;
    readonly postalAddressLine1: string | null | undefined;
    readonly postalAddressLine2: string | null | undefined;
    readonly postalAddressTownCity: string;
    readonly postalCode: string;
    readonly regionCode: string;
    readonly registrationDate: any | null | undefined;
    readonly serviceTypes: ReadonlyArray<string>;
    readonly type: string;
    readonly uprn: string | null | undefined;
    readonly website: string | null | undefined;
  }>;
  readonly type: string;
  readonly uprn: string | null | undefined;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "OperatorFragment";
};
export type OperatorFragment$key = {
  readonly " $data"?: OperatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emails",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employees",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainPhoneNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkedinId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressCounty",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressLine1",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressLine2",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalAddressTownCity",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postalCode",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uprn",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OperatorFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companiesHouseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contacts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deregistrationDate",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorGroupName",
      "storageKey": null
    },
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "sites",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCareHome",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ladCode",
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latitude",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longitude",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "location",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organisationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "operatorId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "operatorName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "regionCode",
          "storageKey": null
        },
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasActiveRegistration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serviceTypes",
          "storageKey": null
        },
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Operator",
  "abstractKey": null
};
})();

(node as any).hash = "45c5fb9297b63796ba5bf82ce8274acd";

export default node;
