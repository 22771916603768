import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { CaHourlyWagesChart } from '../../charts/HourlyWagesChart/CaHourlyWagesChart'
import { LadHourlyWagesChart } from '../../charts/HourlyWagesChart/LadHourlyWagesChart'
import { CaHousePricesChart } from '../../charts/HousePrices/CaHousePricesChart'
import { LadHousePricesChart } from '../../charts/HousePrices/LadHousePricesChart'
import { CaHousingYearsToBuyChart } from '../../charts/HousingYearsToBuy/CaHousingYearsToBuyChart'
import { LadHousingYearsToBuyChart } from '../../charts/HousingYearsToBuy/LadHousingYearsToBuyChart'
import { CaIndustryDistributionChart } from '../../charts/IndustryDistributionChart/CaIndustryDistributionChart'
import { LadIndustryDistributionChart } from '../../charts/IndustryDistributionChart/LadIndustryDistributionChart'
import { CaPopulationGrowthByAgeGroupChart } from '../../charts/PopulationGrowthByAgeGroup/CaPopulationGrowthByAgeGroupChart'
import { LadPopulationGrowthByAgeGroupChart } from '../../charts/PopulationGrowthByAgeGroup/LadPopulationGrowthByAgeGroupChart'
import { CaSocialGradeChart } from '../../charts/SocialGrade/CaSocialGradeChart'
import { LadSocialGradeChart } from '../../charts/SocialGrade/LadSocialGradeChart'
import { CaTenureTypesPieChart } from '../../charts/TenureTypes/CaTenureTypesPieChart'
import { LadTenureTypesPieChart } from '../../charts/TenureTypes/LadTenureTypesPieChart'
import { CaUnemploymentRateChart } from '../../charts/UnemploymentRateChart/CaUnemploymentRateChart'
import { LadUnemploymentRateChart } from '../../charts/UnemploymentRateChart/LadUnemploymentRateChart'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { OutputAreaByLatLongQuery } from '../../graphql/queries/__generated__/OutputAreaByLatLongQuery.graphql'
import { OutputAreaByLatLong } from '../../graphql/queries/OutputAreaByLatLong'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { AnswerChart } from './request'

interface LadChartProps {
	ladCode: string
	chartType: string
}
const LadChartContent: React.FC<LadChartProps> = ({ ladCode, chartType }) => {
	if (!ladCode) return null

	if (chartType?.includes('HourlyWages')) {
		return <LadHourlyWagesChart ladCode={ladCode} aspectRatio={2} />
	}

	if (chartType?.includes('HousePrices')) {
		return <LadHousePricesChart ladCode={ladCode} aspectRatio={2} />
	}

	if (chartType?.includes('HousingYearsToBuy')) {
		return <LadHousingYearsToBuyChart ladCode={ladCode} aspectRatio={2} />
	}

	if (chartType?.includes('IndustryDistribution')) {
		return <LadIndustryDistributionChart ladCode={ladCode} aspectRatio={1} />
	}

	if (chartType?.includes('PopulationGrowthByAgeGroup')) {
		return <LadPopulationGrowthByAgeGroupChart ladCode={ladCode} aspectRatio={2} />
	}

	if (chartType?.includes('SocialGrade')) {
		return <LadSocialGradeChart ladCode={ladCode} aspectRatio={2} />
	}

	if (chartType?.includes('TenureTypes')) {
		return <LadTenureTypesPieChart ladCode={ladCode} aspectRatio={1} />
	}

	if (chartType?.includes('UnemploymentRate')) {
		return <LadUnemploymentRateChart ladCode={ladCode} aspectRatio={2} />
	}
	return null
}

const LadChart: React.FC<LadChartProps> = (props) => {
	return (
		<Panel>
			<React.Suspense fallback={<Loading />}>
				<LadChartContent {...props} />
			</React.Suspense>
		</Panel>
	)
}

interface LatLongRadiusChartProps {
	lat: number
	long: number
	radius: RadiusValues
	chartType: string
}
const LatLongRadiusChartContent: React.FC<LatLongRadiusChartProps> = ({ lat, long, radius, chartType }) => {
	if (!lat || !long) return null

	const { outputAreaByLatLong } = useLazyLoadQuery<OutputAreaByLatLongQuery>(OutputAreaByLatLong, {
		latitude: lat,
		longitude: long,
	})

	if (!outputAreaByLatLong) return null

	if (chartType?.includes('HourlyWages')) {
		return <CaHourlyWagesChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={2} />
	}

	if (chartType?.includes('HousePrices')) {
		return <CaHousePricesChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={2} />
	}

	if (chartType?.includes('HousingYearsToBuy')) {
		return <CaHousingYearsToBuyChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={2} />
	}

	if (chartType?.includes('IndustryDistribution')) {
		return <CaIndustryDistributionChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={1} />
	}

	if (chartType?.includes('PopulationGrowthByAgeGroup')) {
		return <CaPopulationGrowthByAgeGroupChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={2} />
	}

	if (chartType?.includes('SocialGrade')) {
		return <CaSocialGradeChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={2} />
	}

	if (chartType?.includes('TenureTypes')) {
		return <CaTenureTypesPieChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={1} />
	}

	if (chartType?.includes('UnemploymentRate')) {
		return <CaUnemploymentRateChart oaCode={outputAreaByLatLong.oaCode} radius={radius} aspectRatio={2} />
	}

	return null
}

const LatLongRadiusChart: React.FC<LatLongRadiusChartProps> = (props) => {
	return (
		<Panel>
			<React.Suspense fallback={<Loading />}>
				<LatLongRadiusChartContent {...props} />
			</React.Suspense>
		</Panel>
	)
}

const Charts: React.FC<AnswerChart> = ({ type, ca, ladCode }) => {
	if (!type || type === 'none') return null
	const hasLatLong = !!(ca.lat && ca.long && ca.radius)
	const hasLadCode = !!ladCode
	return (
		<>
			{hasLatLong && <LatLongRadiusChart lat={ca.lat} long={ca.long} radius={ca.radius} chartType={type} />}
			{hasLadCode && !hasLatLong && <LadChart ladCode={ladCode} chartType={type} />}
		</>
	)
}

export { Charts }
