import graphql from 'babel-plugin-relay/macro'

export const localAuthoritiesByLads = graphql`
	query LocalAuthoritiesByLadsQuery($ladCodes: [String!]!) {
		localAuthoritiesByLadCodes(ladCodes: $ladCodes) {
			ladCode
			latitude
			longitude
			name
			geometry
			regionName
			regionCode
			countryName
			countryCode
		}
	}
`
