import React from 'react'

import { BarChart } from '../../shared/charts/BarChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface IndustryDistributionChartProps {
	model: Model
	aspectRatio?: number
}

const IndustryDistributionChart: React.FC<IndustryDistributionChartProps> = ({ model, aspectRatio }) => {
	return (
		<>
			<Subtitle>Industry Distribution</Subtitle>
			<Paragraph>Emplyee jobs by industry | ONS Business Register and Employment Survey</Paragraph>
			<VerticalSpace size="md" />
			<BarChart
				horizontal={true}
				stacked={false}
				model={model}
				prefix=""
				suffix="%"
				showLabels={true}
				alpha={0.9}
				aspectRatio={aspectRatio}
			/>
		</>
	)
}

export { IndustryDistributionChart }
