import graphql from 'babel-plugin-relay/macro'

export const CareStatsByTypes = graphql`
	query CareStatsByTypesQuery($specialism: Specialism!) {
		careStatsBySpecialism(specialism: $specialism) {
			...ElderlyCareStatsFragment
			...LaTableElderlyCareStatsFragment
			...ElderlyCareStatsCaFragment
		}
	}
`
