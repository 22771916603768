import graphql from 'babel-plugin-relay/macro'

export const SitesSiteFragment = graphql`
	fragment SitesSiteFragment on Site @relay(plural: true) {
		id
		ladCode
		mainPhoneNumber
		name
		numberOfBeds
		latitude
		longitude
	}
`
