import React from 'react'
import * as i from 'react-feather'
import { useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { OperatorBySiteIdQuery } from '../../graphql/queries/__generated__/OperatorBySiteIdQuery.graphql'
import { SiteByIdQuery } from '../../graphql/queries/__generated__/SiteByIdQuery.graphql'
import { OperatorBySiteId } from '../../graphql/queries/OperatorBySiteId'
import { SiteById } from '../../graphql/queries/SiteById'
import { Page } from '../../layout/page/Page'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { MapFeatures, Marker } from '../../shared/map/MapFeatures'
import { Panel } from '../../shared/Panel'
import { PageTitle, Subtitle } from '../../shared/Text'

type RouteParams = { id: string }

const Site: React.FC = () => {
	const { id = '' } = useParams<RouteParams>() // Get lad from the route

	const { siteById: siteData } = useLazyLoadQuery<SiteByIdQuery>(SiteById, { id })
	const { operatorBySiteId: operatorData } = useLazyLoadQuery<OperatorBySiteIdQuery>(OperatorBySiteId, { id })

	const location: Marker = {
		location: siteData.location ? JSON.parse(siteData.location) : {},
		name: siteData.name,
		id: siteData.id,
	}

	return (
		<Page title={`Care Home - ${siteData.name}`}>
			<Container>
				<VerticalSpace size="md" />
				<Row>
					<div className="col-12">
						<PageTitle>{siteData.name}</PageTitle>
					</div>
				</Row>
				<Row>
					<div className="col-12">
						<VerticalSpace size="lg" />
						<Panel padding={8} height="auto">
							<MapFeatures featureCollections={[]} locations={[location]} height="40vh" />
						</Panel>
						<VerticalSpace size="lg" />
					</div>
				</Row>
				<Row>
					<Col count={6}>
						<Panel>
							<Subtitle>Site Details</Subtitle>
							<div className="list-group-flush">
								<div className="list-group-item">
									<i.Home /> Care Home: {siteData.isCareHome ? 'Yes' : 'No'}
								</div>

								<div className="list-group-item">
									<i.MapPin /> Local Authority: {siteData.ladCode}
								</div>
								<div className="list-group-item">
									<i.Phone /> Main Phone Number: {siteData.mainPhoneNumber}
								</div>
								<div className="list-group-item">
									<i.Hash /> Number of Beds: {siteData.numberOfBeds}
								</div>

								<div className="list-group-item">
									<i.Compass /> Latitude: {siteData.latitude}
								</div>
								<div className="list-group-item">
									<i.Compass /> Longitude: {siteData.longitude}
								</div>
								<div className="list-group-item">
									<i.Type /> Organisation Type: {siteData.organisationType}
								</div>
								<div className="list-group-item">
									<i.Phone /> Phone Number: {siteData.phoneNumber}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Line 1: {siteData.postalAddressLine1}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Town/City: {siteData.postalAddressTownCity}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Code: {siteData.postalCode}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Region: {siteData.regionCode}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Registration Date: {new Date(siteData.registrationDate).toLocaleDateString()}
								</div>
								<div className="list-group-item">
									<i.CheckCircle /> Registration Status: {siteData.hasActiveRegistration ? 'Active' : 'Inactive'}
								</div>
								<div className="list-group-item">
									<i.List /> Service Types: {siteData.serviceTypes}
								</div>
								<div className="list-group-item">
									<i.Type /> Type: {siteData.type}
								</div>

								<div className="list-group-item">
									<i.Hash /> UPRN: {siteData.uprn}
								</div>
								<div className="list-group-item">
									<i.Globe /> Website: {siteData.website}
								</div>
							</div>
						</Panel>
					</Col>

					<Col count={6}>
						<Panel>
							<Subtitle>Operator Details</Subtitle>
							<div className="list-group-flush">
								<div className="list-group-item">
									<i.User /> Name: {operatorData.name}
								</div>
								<div className="list-group-item">
									<i.Tag /> Group Name: {operatorData.operatorGroupName}
								</div>
								<div className="list-group-item">
									<i.Tag /> Group ID: {operatorData.operatorGroupId}
								</div>
								<div className="list-group-item">
									<i.Hash /> Companies House Number: {operatorData.companiesHouseNumber}
								</div>
								<div className="list-group-item">
									<i.Phone /> Main Phone Number: {operatorData.mainPhoneNumber}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Line 1: {operatorData.postalAddressLine1}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Line 2: {operatorData.postalAddressLine2}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Address Town/City: {operatorData.postalAddressTownCity}
								</div>
								<div className="list-group-item">
									<i.MapPin /> Postal Code: {operatorData.postalCode}
								</div>
								<div className="list-group-item">
									<i.Calendar /> Registration Date: {new Date(operatorData.registrationDate).toLocaleDateString()}
								</div>
								<div className="list-group-item">
									<i.Type /> Type: {operatorData.type}
								</div>
								<div className="list-group-item">
									<i.Globe /> Website:
									<a href={`http://${operatorData.website}`} target="_blank" rel="noopener noreferrer">
										{operatorData.website}
									</a>
								</div>
							</div>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Site />
		</React.Suspense>
	)
}

export { Suspended as Site }
