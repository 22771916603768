/**
 * @generated SignedSource<<8b85d75d7a6375fc90228b9651621a45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CareHomesSitesByLadFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly location: string | null | undefined;
  readonly name: string;
  readonly specialisms: ReadonlyArray<string>;
  readonly " $fragmentType": "CareHomesSitesByLadFragment";
}>;
export type CareHomesSitesByLadFragment$key = ReadonlyArray<{
  readonly " $data"?: CareHomesSitesByLadFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CareHomesSitesByLadFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CareHomesSitesByLadFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "c7515fd0e6e4cf8811b94593a4367aea";

export default node;
