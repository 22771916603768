import React, { createContext, useContext, useMemo } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { OperatorGroupsByIdsQuery } from '../graphql/queries/__generated__/OperatorGroupsByIdsQuery.graphql'
import { OperatorsByCaQuery } from '../graphql/queries/__generated__/OperatorsByCaQuery.graphql'
import { OperatorGroupsByIds } from '../graphql/queries/OperatorGroupsByIds'
import { OperatorsByCa } from '../graphql/queries/OperatorsByCa'
import { createOperatorGroupModel, createOperatorModel, OperatorGroup } from '../models/cqc'
import {
	OperatorGroupProvideOperatorFragment$data,
	OperatorGroupProvideOperatorFragment$key,
} from './__generated__/OperatorGroupProvideOperatorFragment.graphql'
import { OperatorGroupProvideOperatorFragment } from './OperatorGroupProvideOperatorFragment'

export interface DataContextType {
	operators: OperatorGroupProvideOperatorFragment$data
	operatorGroups: OperatorGroup[]
}

interface Props {
	oaCode: string
	miles: number
	region: string
	children: React.ReactNode
}

// Create the context with an explicit default value
const OperatorGroupContext = createContext<DataContextType | undefined>(undefined)

const OperatorGroupProvider: React.FC<Props> = ({ oaCode, miles, children }) => {
	const caResponse = useLazyLoadQuery<OperatorsByCaQuery>(OperatorsByCa, { oaCode, radius: miles })

	// Extract operators data
	const operatorsFromCaData = useMemo(() => caResponse.operatorsByCa || [], [caResponse])

	const operatorsFromCa = useFragment<OperatorGroupProvideOperatorFragment$key>(
		OperatorGroupProvideOperatorFragment,
		operatorsFromCaData.operators,
	)

	// Separate operators into those with and without a group ID
	const groupedOperators = operatorsFromCa.filter((o) => o.operatorGroupId !== undefined && o.operatorGroupId !== null)
	const ungroupedOperators = operatorsFromCa.filter(
		(o) => o.operatorGroupId === undefined || o.operatorGroupId === null,
	)

	// Extract group IDs for API fetch
	const operatorIDs: string[] = groupedOperators.map((o) => o.operatorGroupId as string)

	// Fetch actual operator groups from API
	const operatorGroupsData = useLazyLoadQuery<OperatorGroupsByIdsQuery>(OperatorGroupsByIds, { ids: [...operatorIDs] })

	const operatorGroupModels = useMemo(
		() => operatorGroupsData?.operatorGroupsByIds?.operatorGroups.map(createOperatorGroupModel) || [],
		[operatorGroupsData],
	)

	const ungroupedOperatorModels = ungroupedOperators.map(createOperatorModel)

	// Create a new "group" for each ungrouped operator
	const individualOperatorGroups: OperatorGroup[] = ungroupedOperatorModels.map((operator) => ({
		id: `pseudo-${operator.id}`, // Unique ID for each new group
		name: operator.name, // Group name matches the operator's name
		operators: [operator], // Group contains only the operator
	}))

	// Merge API-fetched groups with newly created single-operator groups
	const operatorGroups: OperatorGroup[] = [...operatorGroupModels, ...individualOperatorGroups]

	const data: DataContextType = { operatorGroups, operators: operatorsFromCa }

	return <OperatorGroupContext.Provider value={data}>{children}</OperatorGroupContext.Provider>
}

const useOperatorGroups = () => {
	const context = useContext(OperatorGroupContext)
	if (!context) {
		throw new Error('useOperatorGroups must be used within an OperatorGroupProvider')
	}
	return context
}

export { OperatorGroupProvider, useOperatorGroups }
