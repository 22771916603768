import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { IndustryDistributionsByLadCodeQuery } from '../../graphql/queries/__generated__/IndustryDistributionsByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { IndustryDistributionsByLadCode } from '../../graphql/queries/IndustryDistributionsByLad'
import { Model } from '../../shared/charts/types'
import { IndustryDistributionChart } from './IndustryDistributionChart'

interface LadIndustryDistributionChartProps {
	ladCode: string
	aspectRatio?: number
}

const LadIndustryDistributionChart: React.FC<LadIndustryDistributionChartProps> = ({ ladCode, aspectRatio }) => {
	const { industryDistributionsByLadCode: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<IndustryDistributionsByLadCodeQuery>(IndustryDistributionsByLadCode, {
			ladCode,
		})

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const industryDatasets = datasets.map((d) => {
		return d.values.map((v, i) => ({
			value: v,
			industry: labels[i],
		}))
	})

	// Sort the LAD dataset by Descending order
	const sortedLad = industryDatasets[0].sort((a, b) => b.value - a.value)

	// Get the labels from the sorted LAD dataset
	const sortedIndusties = sortedLad.map((item) => item.industry)

	// The use that to sort the remaing datasets
	const sortedDatasetValues = industryDatasets.map((dataset, index) => {
		if (index === 0) return dataset.map((item) => item.value)
		const sorted = dataset.sort((a, b) => {
			const industryAIndex = sortedLad.findIndex((item) => item.industry === a.industry)
			const industryBIndex = sortedLad.findIndex((item) => item.industry === b.industry)
			return industryAIndex - industryBIndex
		})
		return sorted.map((item) => item.value)
	})

	const model: Model = {
		labels: sortedIndusties,
		datasets: datasets.map((d, i) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: sortedDatasetValues[i],
		})),
	}

	return <IndustryDistributionChart model={model} aspectRatio={aspectRatio} />
}

export { LadIndustryDistributionChart }
