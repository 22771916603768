import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Select } from '../../shared/Select'

interface SearchSelectProps {
	pagePath: string
}

const SearchSelect: React.FC<SearchSelectProps> = ({ pagePath }) => {
	const navigate = useNavigate()
	return (
		<Select
			options={[
				{
					value: 'project',
					label: 'Project',
				},
				{
					value: 'lad',
					label: 'Local Authority',
				},
				{
					value: 'ca',
					label: 'Catchment Area',
				},
			]}
			onChange={(value) => navigate(`/${value}`)}
			value={pagePath as string}
			maxWidth={160}
		/>
	)
}

export { SearchSelect }
