function calculateCareHomeSizeScore(operatorBedsDeviation: number, projectsBeds: number): number {
	const d = Math.abs(operatorBedsDeviation) / projectsBeds // relative deviation
	const s = Math.sign(operatorBedsDeviation) // +1 if above, -1 if below

	let score: number

	if (d <= 0.1) {
		score = 5
	} else if (d < 0.2) {
		score = 4
	} else if (d <= 0.3) {
		score = 3
	} else {
		// For d > 0.15, differentiate negative and positive deviation:
		if (s >= 0) {
			// operator median > project beds (positive deviation)
			if (d < 0.5) {
				score = 2
			} else if (d < 1) {
				score = 1
			} else {
				score = 0
			}
		} else {
			// operator median < project beds (negative deviation)
			if (d < 0.4) {
				score = 2
			} else if (d < 0.7) {
				score = 1
			} else {
				score = 0
			}
		}
	}

	return score
}

export { calculateCareHomeSizeScore }
