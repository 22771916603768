import { useAuth0 } from '@auth0/auth0-react'
import type { MultiPolygon, Polygon } from 'geojson'
import React, { useEffect, useRef, useState } from 'react'
import { useRelayEnvironment } from 'react-relay'
import { fetchQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery } from '../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { PropertyByIdQuery } from '../../graphql/queries/__generated__/PropertyByIdQuery.graphql'
import { LocalAuthorityByLadCode } from '../../graphql/queries/LocalAuthorityByLad'
import { PropertyById } from '../../graphql/queries/PropertyById'
import { Page } from '../../layout/page/Page'
import { GeoPolygon, GoogleMap, GoogleMapHandle, Tile } from '../../shared/GoogleMap'
import { Chat } from './Chat'
import { PromptBar } from './PromptBar'
import { Answer, AnswerContent, AnswerMap, getThreadHistory, streamToThread } from './request'

const THREAD_ID = 'test3'
const TYPING_SPEED = 10 // Adjust this to make typing slower or faster

const Agent: React.FC = () => {
	const mapRef = useRef<GoogleMapHandle>(null)
	const { user, getAccessTokenSilently } = useAuth0()
	const [chatHistory, setChatHistory] = useState<Answer[]>([])
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [loadingState, setLoadingState] = useState<'idle' | 'requested' | 'loading'>('idle')
	const [mapState, setMapState] = useState<AnswerMap>({
		center: { lat: 0, long: 0 },
		zoom: 8,
		markers: [],
	})
	const [propertyId, setPropertyId] = useState<string | null>(null)
	const [polygons, setPolygons] = useState<GeoPolygon[]>([])
	const [ladCode, setLadCode] = useState<string | null>(null)

	const handleContent = (content: AnswerContent) => {
		const nextState = { ...mapState }

		if (content.map?.center) {
			nextState.center = content.map.center
		}
		if (typeof content.map?.zoom === 'number') {
			nextState.zoom = content.map.zoom
		}
		if (content.map?.markers && Array.isArray(content.map?.markers)) {
			nextState.markers = content.map.markers
		}

		setMapState(nextState)

		if (content.propertyId) {
			setPropertyId(content.propertyId)
		}

		if (content.chart?.ladCode) {
			setLadCode(content.chart.ladCode)
		}

		mapRef.current?.updateMap(nextState)
	}

	if (!user?.sub) {
		setErrorMessage('User not authenticated')
		setLoadingState('idle')
		return null
	}

	const userId = user.sub

	// Fetch history when the component mounts
	useEffect(() => {
		if (user?.sub) {
			setChatHistory([])
			const fetchHistory = async () => {
				const token = await getAccessTokenSilently()
				const messages = await getThreadHistory(token, userId, THREAD_ID) // Example threadId

				// for (const message of messages) {
				// 	await new Promise((resolve) => setTimeout(resolve, 1000))
				// 	setChatHistory((prevChatHistory) => [...prevChatHistory, message])
				// 	handleContent(message.content)
				// }

				setChatHistory(messages)
				const lastMessage = messages[messages.length - 1]
				handleContent(lastMessage.content)
			}
			fetchHistory()
		}
	}, [user?.sub, THREAD_ID])

	const handleSubmit = async (inputValue: string) => {
		const prompt = inputValue.trim()
		setErrorMessage(null)
		setLoadingState('requested')
		setChatHistory((prevChatHistory) => [...prevChatHistory, { content: { text: prompt }, isUser: true }])

		const token = await getAccessTokenSilently()

		let responseText = ''

		// Stream the AI response
		await streamToThread(token, userId, THREAD_ID, prompt, async (chunk) => {
			setLoadingState('loading')

			if (!chunk.content) {
				return
			}

			handleContent(chunk.content)

			const words = chunk.content.text.split(' ') // Split chunk into words

			setChatHistory((prevChatHistory) => [...prevChatHistory, { content: { text: '' }, isUser: false }])

			// Typing effect by word, instead of by character
			for (let i = 0; i < words.length; i++) {
				await new Promise((resolve) => setTimeout(resolve, TYPING_SPEED)) // Adjust speed here

				// Add the word to responseText
				responseText += i === 0 ? words[i] : ' ' + words[i]

				setChatHistory((prevChatHistory) => {
					const newChat = [...prevChatHistory]
					const chatMessage: Answer = {
						...chunk,
						content: {
							...chunk.content,
							text: responseText, // Show the accumulated response text (word by word)
						},
					}
					newChat[newChat.length - 1] = chatMessage // Replace the last message with the updated message
					return newChat
				})
			}
		})

		setLoadingState('idle')
	}

	const env = useRelayEnvironment()

	// Fetch property features only when lat/long change
	useEffect(() => {
		if (!propertyId) {
			return
		}
		return fetchQuery<PropertyByIdQuery>(env, PropertyById, { id: propertyId }).subscribe({
			next: ({ propertyById }) => {
				const polygon = toGeoJSONGeometry(propertyById.geometry)
				setPolygons([polygon])
			},
		}).unsubscribe
	}, [propertyId, env])

	// Fetch local authority features only when ladCode changes
	useEffect(() => {
		if (!ladCode) {
			return
		}
		return fetchQuery<LocalAuthorityByLadCodeQuery>(env, LocalAuthorityByLadCode, { ladCode }).subscribe({
			next: ({ localAuthorityByLadCode }) => {
				const geometry: RawGeometry = JSON.parse(localAuthorityByLadCode.geometry)
				const polygon = toGeoJSONGeometry(geometry)
				setPolygons([polygon])
			},
		}).unsubscribe
	}, [ladCode, env])

	const isLoading = loadingState !== 'idle'
	const isRequested = loadingState === 'requested'

	const tiles: Tile[] = [
		{
			name: 'properties',
			color: [210, 70, 240, 155], // Blue color
			urlTemplate: 'https://tiles.propmatch.co.uk/maps/properties/{z}/{x}/{y}.pbf',
		},
		// {
		// 	name: 'flood-risk-zones',
		// 	color: [50, 70, 240, 155], //
		// 	urlTemplate: 'http://tiles.propmatch.co.uk/maps/flood-risk-zones/{z}/{x}/{y}.pbf',
		// },
	]

	return (
		<Page title="SQL Agent">
			{/* Chat + Map panel */}
			<div style={{ display: 'flex', height: 'calc(100vh - 50px)' }}>
				{/* LEFT */}
				<div style={{ width: '50%', position: 'relative', borderRight: '1px solid #ddd' }}>
					<Chat messages={chatHistory} errorMessage={errorMessage} isLoading={isRequested} />
					<PromptBar onSubmit={handleSubmit} isLoading={isLoading} />
				</div>

				{/* RIGHT */}
				<div style={{ width: '50%', height: '100%' }}>
					<GoogleMap
						ref={mapRef}
						center={mapState.center}
						zoom={mapState.zoom}
						markers={mapState.markers}
						polygons={polygons}
						tiles={tiles}
					/>
				</div>
			</div>
		</Page>
	)
}

interface RawGeometry {
	readonly type: string
	readonly coordinates: any
}

function toGeoJSONGeometry(geo: RawGeometry): Polygon | MultiPolygon {
	switch (geo.type) {
		case 'Polygon':
			return geo as Polygon
		case 'MultiPolygon':
			return geo as MultiPolygon
		default:
			throw new Error(`Unsupported geometry type: ${geo.type}`)
	}
}

export { Agent }
