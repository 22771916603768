import React from 'react'
import { X } from 'react-feather'

interface BadgeProps {
	children: React.ReactNode
	type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
	margin?: string
}

const Badge: React.FC<BadgeProps> = ({ children, type = 'primary', margin }) => {
	return (
		<span className={`badge bg-${type}`} style={{ textTransform: 'capitalize', margin }}>
			{children}
		</span>
	)
}

interface DismissableBadgeProps extends BadgeProps {
	id: string
	onDismiss: (id: string) => void
}

const DismissableBadge: React.FC<DismissableBadgeProps> = ({ id, children, type = 'primary', margin, onDismiss }) => {
	return (
		<h5 style={{ display: 'inline-block' }}>
			<span className={`badge bg-${type} badge-lg`} style={{ textTransform: 'capitalize', margin }}>
				{children}
				<button
					onClick={() => onDismiss(id)}
					style={{ marginLeft: '8px', border: 'none', background: 'transparent', cursor: 'pointer' }}
				>
					<X size={16} />
				</button>
			</span>
		</h5>
	)
}

export { Badge, DismissableBadge }
