import React, { createContext, useContext } from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { CareFundingType, currentYear, Specialism } from '../Constants'
import { AAAwardTypesByCaQuery } from '../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { CareFeeRatesByCaQuery } from '../graphql/queries/__generated__/CareFeeRatesByCaQuery.graphql'
import { CareFundingByCaQuery, RadiusValues } from '../graphql/queries/__generated__/CareFundingByCaQuery.graphql'
import { CareStatsByCaQuery } from '../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { DementiaStatsByCaQuery } from '../graphql/queries/__generated__/DementiaStatsByCaQuery.graphql'
import { GrossDisposableHouseholdIncomesByCaQuery } from '../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByCaQuery.graphql'
import { HourlyWagesByCaQuery } from '../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { HousePricesByCaQuery } from '../graphql/queries/__generated__/HousePricesByCaQuery.graphql'
import { IndustryDistributionsByCaQuery } from '../graphql/queries/__generated__/IndustryDistributionsByCaQuery.graphql'
import { MigrationStatsByCaQuery } from '../graphql/queries/__generated__/MigrationStatsByCaQuery.graphql'
import { ParentsByCodeQuery } from '../graphql/queries/__generated__/ParentsByCodeQuery.graphql'
import { PopulationByCaQuery } from '../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { UnemploymentRatesByCaQuery } from '../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { AAAwardTypesByCa } from '../graphql/queries/AAAwardTypesByCa'
import { CareFeeRatesByCa } from '../graphql/queries/CareFeeRatesByCa'
import { CareFundingByCa } from '../graphql/queries/CareFundingByCa'
import { CareStatsByCa } from '../graphql/queries/CareStatsByCa'
import { DementiaStatsByCa } from '../graphql/queries/DementiaStatsByCa'
import { GrossDisposableHouseholdIncomesByCa } from '../graphql/queries/GrossDisposableHouseholdIncomesByCa'
import { HourlyWagesByCa } from '../graphql/queries/HourlyWagesByCa'
import { HousePricesByCa } from '../graphql/queries/HousePricesByCa'
import { IndustryDistributionsByCa } from '../graphql/queries/IndustryDistributionsByCa'
import { MigrationStatsByCa } from '../graphql/queries/MigrationStatsByCa'
import { ParentsByCode } from '../graphql/queries/ParentsByCode'
import { PopulationByCa } from '../graphql/queries/PopulationByCa'
import { UnemploymentRatesByCa } from '../graphql/queries/UnemploymentRatesByCa'
import { GraphQlAaAwardTypes } from '../models/aaAwardTypes'
import { GraphQlCareFunding } from '../models/careFunding'
import { GraphQlCareStats } from '../models/careStats'
import { GraphqlDementiaStats } from '../models/dementiaStats'
import { GraphQlDemographics } from '../models/demographics'
import { GraphQlGeography } from '../models/geography'
import { GraphQlPopulation } from '../models/population'
import { Project } from '../models/project'

export interface Population {
	workingAge: GraphQlPopulation
	over75: GraphQlPopulation
	over75In3: GraphQlPopulation
	over75In6: GraphQlPopulation
	total: GraphQlPopulation
}

export interface CareStats {
	elderly: GraphQlCareStats
	dementia: GraphQlCareStats
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	aaAwardTypes: GraphQlAaAwardTypes
	dementiaStats: GraphqlDementiaStats
}

export interface Demographics {
	industryDistribution: GraphQlDemographics
	hourlyWage: GraphQlDemographics
	gdhi: GraphQlDemographics
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	migration: GraphQlDemographics
}

// Define the shape of our context
export interface DataContextType {
	project: Project
	geographies: GraphQlGeography
	radius: RadiusValues
	population: Population
	careStats: CareStats
	demographics: Demographics
}

interface Props {
	project: Project
	radius: RadiusValues
	children: React.ReactNode
}

const MvuDataProvider: React.FC<Props> = ({ project, radius, children }) => {
	const { parentsByCode: geographies } = useLazyLoadQuery<ParentsByCodeQuery>(ParentsByCode, {
		code: project.ladCode,
	})

	const oaCode = project.oaCode

	const { populationByCa: totalPop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 0,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const { populationByCa: workingPop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 16,
		maxAge: 64,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const { populationByCa: over75Pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 0,
		includeIntermediates: false,
	})

	const { populationByCa: over75PopIn3Pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode: project.oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 3,
		includeIntermediates: false,
	})

	const { populationByCa: over75PopIn6Pop } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode: project.oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 100,
		futureOffset: 6,
		includeIntermediates: false,
	})

	const { aaAwardTypesByCa } = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, {
		oaCode: project.oaCode,
		radius,
	})

	// Migration: using a Migration query:
	const { migrationStatsByCa } = useLazyLoadQuery<MigrationStatsByCaQuery>(MigrationStatsByCa, {
		oaCode: project.oaCode,
		radius,
	})

	const { careStatsByCa: eldelyCareStats } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { careStatsByCa: dementiaCareStats } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.dementia,
		oaCode,
		radius,
	})

	const { industryDistributionsByCa } = useLazyLoadQuery<IndustryDistributionsByCaQuery>(IndustryDistributionsByCa, {
		oaCode,
		radius,
	})

	const { hourlyWagesByCa } = useLazyLoadQuery<HourlyWagesByCaQuery>(HourlyWagesByCa, {
		oaCode,
		radius,
	})

	const { careFundingByCa } = useLazyLoadQuery<CareFundingByCaQuery>(CareFundingByCa, {
		careType: CareFundingType.careHome,
		oaCode,
		radius,
	})
	const { careFeeRatesByCa } = useLazyLoadQuery<CareFeeRatesByCaQuery>(CareFeeRatesByCa, {
		oaCode,
		radius,
	})

	const { grossDisposableHouseholdIncomesByCa } = useLazyLoadQuery<GrossDisposableHouseholdIncomesByCaQuery>(
		GrossDisposableHouseholdIncomesByCa,
		{
			oaCode,
			radius,
		},
	)

	const { unemploymentRatesByCa } = useLazyLoadQuery<UnemploymentRatesByCaQuery>(UnemploymentRatesByCa, {
		oaCode,
		radius,
	})
	const { housePricesByCa } = useLazyLoadQuery<HousePricesByCaQuery>(HousePricesByCa, {
		oaCode,
		radius,
	})

	// Dementia Beds & Opportunities
	const { dementiaStatsByCa } = useLazyLoadQuery<DementiaStatsByCaQuery>(DementiaStatsByCa, {
		oaCode,
		radius,
	})

	const data: DataContextType = {
		geographies,
		project,
		radius,
		population: {
			workingAge: workingPop,
			over75: over75Pop,
			over75In3: over75PopIn3Pop,
			over75In6: over75PopIn6Pop,
			total: totalPop,
		},
		careStats: {
			elderly: eldelyCareStats,
			dementia: dementiaCareStats,
			fees: careFeeRatesByCa,
			funding: careFundingByCa,
			aaAwardTypes: aaAwardTypesByCa,
			dementiaStats: dementiaStatsByCa,
		},
		demographics: {
			industryDistribution: industryDistributionsByCa,
			hourlyWage: hourlyWagesByCa,
			housePrices: housePricesByCa,
			gdhi: grossDisposableHouseholdIncomesByCa,
			unemployment: unemploymentRatesByCa,
			migration: migrationStatsByCa,
		},
	}

	return <MvuDataContext.Provider value={data}>{children}</MvuDataContext.Provider>
}

// Create a context with a default value
const MvuDataContext = createContext<DataContextType | undefined>(undefined)

const useMvuData = () => {
	const context = useContext(MvuDataContext)
	if (!context) {
		throw new Error('useData must be used within a DataProvider')
	}
	return context
}

export { MvuDataProvider, useMvuData }
