import React from 'react'

import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphQlPopulation } from '../../../../../models/population'
import { Model } from '../../../../../shared/charts/types'
import { Col, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { SimpleBarChart } from '../../../shared/SimpleBarChart'
import { Label, Panel } from '../../../shared/SubComponents'

interface Props {
	over75In6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	aaAwardTypes: GraphQlAaAwardTypes
}

const TrendAnalysis: React.FC<Props> = ({ over75In6, elderlyCareStats, aaAwardTypes }) => {
	const labels = [over75In6.years[0].toString(), over75In6.years[over75In6.years.length - 1].toString()]
	const values = [...over75In6.geographies[0].ageRanges[0].values]
	const popModel: Model = {
		labels,
		datasets: [
			{
				values,
			},
		],
	}

	const geosIn6 = over75In6?.geographies || []
	const caPop75_Current = geosIn6[0].ageRanges[0].values[0]
	const caPop75_In6 = geosIn6[0].ageRanges[0].values[1]

	const caBeds = elderlyCareStats.stats[0].numberOfBeds
	const { ca: caH } = getGeoValuesFromDataset(aaAwardTypes.higherRate)

	const caHRPopRatio = caH / caPop75_Current

	const caHigherRateShortage_Current = Math.round(caBeds - caH)
	const caHigherRateShortage_In6 = Math.round(caBeds - caHRPopRatio * caPop75_In6)

	const bedModel: Model = {
		labels,
		datasets: [
			{
				values: [Math.abs(caHigherRateShortage_Current), Math.abs(caHigherRateShortage_In6)],
			},
		],
	}

	return (
		<Row gutterSize={2}>
			<Col count={6}>
				<BarChartPanel title="Elderly Population 75+" model={popModel} height={266} />
			</Col>
			<Col count={6}>
				<BarChartPanel title="Surplus Care Bed Demand" model={bedModel} height={266} />
			</Col>
		</Row>
	)
}

const BarChartPanel: React.FC<{ title: string; model: Model; height: number }> = ({ title, model, height }) => {
	return (
		<Panel height={height} padding={20}>
			<Label>{title}</Label>
			<VerticalSpace size="lg" />
			<SimpleBarChart model={model} />
		</Panel>
	)
}

export { TrendAnalysis }
