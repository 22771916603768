export function defineCareGroupType(registeredCareHomesCount: number, regionsActiveCount: number): string {
	if (registeredCareHomesCount <= 0 || regionsActiveCount <= 0) {
		return 'Undefined'
	}
	if (registeredCareHomesCount === 1 && regionsActiveCount === 1) {
		return 'Single Care Home'
	}
	if (registeredCareHomesCount >= 2 && registeredCareHomesCount <= 10 && regionsActiveCount === 1) {
		return 'Small Local'
	}
	if (registeredCareHomesCount >= 2 && registeredCareHomesCount <= 10 && regionsActiveCount > 1) {
		return 'Small Cross-Regional'
	}
	if (registeredCareHomesCount > 10 && registeredCareHomesCount <= 30 && regionsActiveCount === 1) {
		return 'Mid-Size Local'
	}
	if (
		registeredCareHomesCount > 10 &&
		registeredCareHomesCount <= 30 &&
		regionsActiveCount > 1 &&
		regionsActiveCount <= 3
	) {
		return 'Mid-Size Regional'
	}
	if (
		registeredCareHomesCount > 10 &&
		registeredCareHomesCount <= 30 &&
		regionsActiveCount > 3 &&
		regionsActiveCount < 8
	) {
		return 'Mid-Size Cross-Regional'
	}
	if (registeredCareHomesCount > 10 && registeredCareHomesCount <= 30 && regionsActiveCount >= 8) {
		return 'Mid-Size Nationwide'
	}
	if (registeredCareHomesCount > 30 && regionsActiveCount === 1) {
		return 'Large Local'
	}
	if (registeredCareHomesCount > 30 && regionsActiveCount > 1 && regionsActiveCount <= 3) {
		return 'Large Regional'
	}
	if (registeredCareHomesCount > 30 && regionsActiveCount > 3 && regionsActiveCount < 8) {
		return 'Large Cross-Regional'
	}
	if (registeredCareHomesCount > 30 && regionsActiveCount >= 8) {
		return 'Large Nationwide'
	}

	return 'Undefined'
}
