/**
 * @generated SignedSource<<04ebf7f6b0add01a743efae0c63ed2e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectStatus = "approved" | "pipeline" | "planning" | "%future added value";
export type ProjectByReferenceQuery$variables = {
  id: string;
};
export type ProjectByReferenceQuery$data = {
  readonly projectByReference: {
    readonly address: string;
    readonly area: number;
    readonly client: string;
    readonly date: any;
    readonly id: any;
    readonly ladCode: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly mvu: any;
    readonly name: string;
    readonly oaCode: string;
    readonly postalCode: string;
    readonly properties: ReadonlyArray<{
      readonly geometry: {
        readonly coordinates: any;
        readonly type: string;
      };
      readonly id: string;
      readonly properties: {
        readonly aonbIds: ReadonlyArray<string>;
        readonly area: number;
        readonly brownfieldIds: ReadonlyArray<string>;
        readonly floodRiskZoneIds: ReadonlyArray<string>;
        readonly greenBeltIds: ReadonlyArray<string>;
        readonly listedBuildingIds: ReadonlyArray<string>;
        readonly sitesOfSpecialScientificInterestIds: ReadonlyArray<string>;
        readonly specialProtectionAreaIds: ReadonlyArray<string>;
        readonly treePreservationZoneIds: ReadonlyArray<string>;
      };
      readonly type: string;
    }>;
    readonly reference: string;
    readonly regionCode: string;
    readonly settings: any | null | undefined;
    readonly status: ProjectStatus;
    readonly titleNumbers: ReadonlyArray<string>;
    readonly townCity: string;
    readonly userId: string;
  };
};
export type ProjectByReferenceQuery = {
  response: ProjectByReferenceQuery$data;
  variables: ProjectByReferenceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "projectByReference",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "client",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mvu",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oaCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyFeature",
        "kind": "LinkedField",
        "name": "properties",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GeoJsonGeometry",
            "kind": "LinkedField",
            "name": "geometry",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyProperties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aonbIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brownfieldIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "greenBeltIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floodRiskZoneIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "listedBuildingIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialProtectionAreaIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sitesOfSpecialScientificInterestIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "treePreservationZoneIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reference",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "settings",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "townCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectByReferenceQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectByReferenceQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "ffd2b298941f01bdf6d7b2551abeb92b",
    "id": null,
    "metadata": {},
    "name": "ProjectByReferenceQuery",
    "operationKind": "query",
    "text": "query ProjectByReferenceQuery(\n  $id: ID!\n) {\n  projectByReference(id: $id) {\n    address\n    area\n    client\n    date\n    id\n    ladCode\n    latitude\n    longitude\n    mvu\n    name\n    oaCode\n    postalCode\n    properties {\n      id\n      type\n      geometry {\n        type\n        coordinates\n      }\n      properties {\n        area\n        aonbIds\n        brownfieldIds\n        greenBeltIds\n        floodRiskZoneIds\n        listedBuildingIds\n        specialProtectionAreaIds\n        sitesOfSpecialScientificInterestIds\n        treePreservationZoneIds\n      }\n    }\n    reference\n    regionCode\n    settings\n    status\n    titleNumbers\n    townCity\n    userId\n  }\n}\n"
  }
};
})();

(node as any).hash = "5abd29fee796c451b3e0c74f7dabb4c3";

export default node;
