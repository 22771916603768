import React, { useEffect, useState } from 'react'
import * as i from 'react-feather'

import { ProjectStatus } from '../../graphql/queries/__generated__/CreateProjectMutation.graphql'
import { DismissableBadge } from '../../shared/Badge'
import { PrimaryButton } from '../../shared/Button'
import { LeftAligned, RightAligned, VerticalSpaceBetween } from '../../shared/layout/Alignment'
import { Col, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Panel } from '../../shared/Panel'
import { PageTitle } from '../../shared/Text'
import { squareMetersToAcres } from '../../utils/acres'
import { toggleArrayItem } from '../../utils/array'
import { t } from '../../utils/localisation'
import { getLatLongByPostCode, getPostCodeByLatLong } from '../../utils/postcodes'
import { FeatureProperties, PropertyPicker } from './PropertyPicker'

export type FormData = {
	name: string
	client: string
	date: string
	numberOfBeds: number
	numberOfExtraCareUnits: number
	postCode: string
	latitude: number
	longitude: number
	zoom: number
	properties: FeatureProperties[]
	status: ProjectStatus
}

interface ProjectFormProps {
	initialFormData: FormData
	onSubmit: (data: FormData) => void
	isMutationInFlight: boolean
	formLabel: string
	buttonLabel: string
}

const ProjectForm: React.FC<ProjectFormProps> = ({
	initialFormData,
	onSubmit,
	isMutationInFlight,
	formLabel,
	buttonLabel,
}) => {
	const [formData, setFormData] = useState<FormData>(initialFormData)
	const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({})

	// This function validates the form data
	const validate = (data: FormData): Record<string, boolean> => {
		const errors: Record<string, boolean> = {}

		// Example validation rules
		if (!data.name.trim()) {
			errors.name = true
		}
		if (!data.client.trim()) {
			errors.client = true
		}
		if (!data.date.trim()) {
			errors.date = true
		}
		if (!data.latitude) {
			errors.lat = true
		}
		if (!data.longitude) {
			errors.long = true
		}
		if (data.numberOfBeds < 0) {
			errors.numberOfBeds = true
		}
		if (data.numberOfExtraCareUnits < 0) {
			errors.numberOfExtraCareUnits = true
		}

		return errors
	}

	// Update form state when initialFormData changes
	useEffect(() => {
		if (!isMutationInFlight) {
			setFormData(initialFormData)
		}
	}, [initialFormData, isMutationInFlight])

	useEffect(() => {
		setFormData((prev) => ({
			...prev,
			...initialFormData,
			date: formatDate(initialFormData.date), // Ensure date formatting
		}))
	}, [initialFormData]) // ✅ Triggers only when `initialFormData` changes externally

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target

		setFormData((prev) => ({
			...prev,
			[name]: value,
		}))

		setValidationErrors((prev) => ({
			...prev,
			[name]: !value.trim(),
		}))
	}

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}))
	}

	const onPostCodeChanged = (postCode: string) => {
		setFormData((prev) => ({
			...prev,
			postCode,
		}))

		const fetchPostCodeData = async () => {
			if (!postCode) return
			postCode = postCode.trim()
			if (postCode.length < 6) return

			const latLong = await getLatLongByPostCode(postCode)
			if (latLong && latLong.length === 2) {
				const [latitude, longitude] = latLong
				setFormData((prev) => ({
					...prev,
					latitude,
					longitude,
					zoom: 16,
				}))

				setValidationErrors((prev) => ({
					...prev,
					latitude: false,
					longitude: false,
				}))
			}
		}

		fetchPostCodeData()
	}

	const onCenterChanged = async (latitude: number, longitude: number) => {
		setFormData((prev) => ({
			...prev,
			latitude,
			longitude,
		}))

		const postCode = await getPostCodeByLatLong(latitude, longitude)
		if (postCode) {
			setFormData((prev) => ({
				...prev,
				postCode,
			}))
		}
	}

	const onZoomChanged = (zoom: number) => {
		setFormData((prev) => ({
			...prev,
			zoom,
		}))
	}

	const toggleProperty = (item: FeatureProperties) => {
		setFormData((prev) => ({
			...prev,
			properties: toggleArrayItem(prev.properties, item),
		}))
	}

	const removePropertyById = (id: string) => {
		setFormData((prev) => ({
			...prev,
			properties: prev.properties.filter((p) => p.id !== id),
		}))
	}

	const handleSubmit = () => {
		// Only submit if there are no validation errors
		const errors = validate(formData)
		setValidationErrors(errors)

		if (Object.keys(errors).length === 0) {
			onSubmit(formData) // Submit the form data if no validation errors
		}
	}

	const formatDate = (dateString: string) => {
		if (!dateString) return '' // Handle empty date
		return dateString.split('T')[0] // Extracts only YYYY-MM-DD
	}

	return (
		<Panel padding={8}>
			<Row minHeight="80vh">
				<Col count={4}>
					<VerticalSpaceBetween>
						<div className="px-5 pt-5">
							<PageTitle>{formLabel}</PageTitle>
							<VerticalSpace size="md" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="name">{t('project_form.project_name')}</label>
										<input
											type="text"
											id="name"
											name="name"
											value={formData.name}
											onChange={handleChange}
											className={validationErrors.name ? 'form-control is-invalid' : 'form-control'}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="client">{t('project_form.client')}</label>
										<input
											type="text"
											id="client"
											name="client"
											value={formData.client}
											onChange={handleChange}
											className={validationErrors.client ? 'form-control is-invalid' : 'form-control'}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="postCode">{t('project_form.post_code')}</label>
										<input
											type="text"
											id="postCode"
											name="postCode"
											value={formData.postCode}
											onChange={(e) => onPostCodeChanged(e.target.value)}
											className={validationErrors.postCode ? 'form-control is-invalid' : 'form-control'}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={6}>
										<label htmlFor="latitude">{t('project_form.latitude')}</label>
										<input
											type="text"
											id="latitude"
											name="latitude"
											value={formData.latitude}
											onChange={handleChange}
											className={validationErrors.lat ? 'form-control is-invalid' : 'form-control'}
										/>
									</Col>
									<Col count={6}>
										<label htmlFor="longitude">{t('project_form.longitude')}</label>
										<input
											type="text"
											id="longitude"
											name="longitude"
											value={formData.longitude}
											onChange={handleChange}
											className={validationErrors.long ? 'form-control is-invalid' : 'form-control'}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="date">{t('project_form.project_date')}</label>
										<input
											type="date"
											id="date"
											name="date"
											value={formatDate(formData.date)}
											onChange={handleChange}
											className={validationErrors.date ? 'form-control is-invalid' : 'form-control'}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={6}>
										<label htmlFor="numberOfBeds">{t('project_form.number_of_beds')}</label>
										<input
											type="number"
											id="numberOfBeds"
											name="numberOfBeds"
											value={formData.numberOfBeds}
											onChange={handleChange}
											min="0"
											className="form-control"
										/>
									</Col>
									<Col count={6}>
										<label htmlFor="numberOfExtraCareUnits">{t('project_form.number_of_extra_care_units')}</label>
										<input
											type="number"
											id="numberOfExtraCareUnits"
											name="numberOfExtraCareUnits"
											value={formData.numberOfExtraCareUnits}
											onChange={handleChange}
											min="0"
											className="form-control"
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={6}>
										<label htmlFor="status">Status</label>
										<select
											id="status"
											name="status"
											value={formData.status}
											onChange={handleSelectChange}
											className="form-control"
										>
											<option value="pipeline">Pipeline</option>
											<option value="planning">Planning</option>
											<option value="approved">Approved</option>
										</select>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="properties">
											{t('project_form.selected_properties', formData.properties.length)}
										</label>
										<LeftAligned height="auto">
											<div style={{ minHeight: '80px' }}>
												{formData.properties.map(({ id, area }) => (
													<DismissableBadge id={id} key={id} onDismiss={removePropertyById} margin="0px 4px 0px 0px">
														{id} ({squareMetersToAcres(area)} acres)
													</DismissableBadge>
												))}
											</div>
										</LeftAligned>
									</Col>
								</Row>
							</div>
						</div>
						<div className="px-5 pb-5">
							<RightAligned height="auto">
								<PrimaryButton onClick={() => handleSubmit()} disabled={isMutationInFlight}>
									<i.PlusCircle size={14} />
									{buttonLabel}
								</PrimaryButton>
							</RightAligned>
						</div>
					</VerticalSpaceBetween>
				</Col>
				<Col count={8}>
					<PropertyPicker
						latitude={formData.latitude}
						longitude={formData.longitude}
						onCenterChanged={onCenterChanged}
						onZoomChanged={onZoomChanged}
						zoom={formData.zoom}
						selectedPropertyIds={formData.properties.map((p) => p.id)}
						onSelectedProperty={toggleProperty}
					/>
				</Col>
			</Row>
		</Panel>
	)
}

export { ProjectForm }
