import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface HousePricesChartProps {
	model: Model
	aspectRatio?: number
}

const HousePricesChart: React.FC<HousePricesChartProps> = ({ model, aspectRatio = 2 }) => {
	return (
		<>
			<Subtitle>Average House Prices</Subtitle>
			<Paragraph>In GBP, inflation-adjusted | HM Land Registry Data</Paragraph>
			<VerticalSpace size="md" />
			<LineChart model={model} dataLabelDisplayOption="showOnlyLast" aspectRatio={aspectRatio} />
		</>
	)
}

export { HousePricesChart }
