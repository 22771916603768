import React, { useMemo } from 'react'
import { useMutation } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { getUserId } from '../../Config'
import { CreateProject } from '../../graphql/queries/CreateProject'
import { Page } from '../../layout/page/Page'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { t } from '../../utils/localisation'
import { FormData, ProjectForm } from './ProjectForm' // Assuming the form logic is now a separate component.

const CreateProjectPage: React.FC = () => {
	const navigate = useNavigate()

	// ✅ Memoize initial form data to prevent unnecessary re-renders
	const initialFormData: FormData = useMemo(
		() => ({
			name: '',
			client: '',
			date: new Date().toISOString().split('T')[0],
			numberOfBeds: 60,
			numberOfExtraCareUnits: 0,
			postCode: '',
			latitude: 52.2903632,
			longitude: -1.5120773,
			zoom: 7,
			propertyIds: [],
			properties: [],
			status: 'pipeline',
		}),
		[],
	)

	const [commitMutation, isMutationInFlight] = useMutation(CreateProject)

	// Form validation and mutation logic
	const onSubmit = (formData: FormData) => {
		const parsedDate = new Date(formData.date)

		commitMutation({
			variables: {
				input: {
					latitude: formData.latitude,
					longitude: formData.longitude,
					name: formData.name,
					client: formData.client,
					date: parsedDate.toISOString(),
					userId: getUserId(),
					propertyIds: formData.properties.map((property) => property.id),
					status: formData.status,
					settings: JSON.stringify({
						mapZoom: formData.zoom,
						numberOfBeds: formData.numberOfBeds,
						numberOfExtraCareUnits: formData.numberOfExtraCareUnits,
					}),
				},
			},
			updater: (store) => {
				const root = store.getRoot()

				// Get the linked record for 'projectsByUserId'
				const userProjects = root.getLinkedRecord('projectsByUserId', { id: getUserId() })

				if (!userProjects) return

				// Get the list of existing projects
				const projects = userProjects.getLinkedRecords('projects') || []

				// Get the new project created by the mutation (root field 'createProject')
				const newProject = store.getRootField('createProject') // This should match the field name in your mutation

				// If the mutation returns a new project, append it to the existing list
				if (newProject) {
					userProjects.setLinkedRecords([...projects, newProject], 'projects')
				}
			},
			onCompleted: () => {
				navigate('/projects')
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	return (
		<Page title={t('create_project.page_title')}>
			<Container>
				<VerticalSpace size="md" />
				<ProjectForm
					initialFormData={initialFormData}
					isMutationInFlight={isMutationInFlight}
					formLabel={t('create_project.form_title')}
					buttonLabel={t('create_project.submit_button')}
					onSubmit={onSubmit}
				/>
			</Container>
		</Page>
	)
}

export { CreateProjectPage }
