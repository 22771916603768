import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { milesRadiusValueInInts } from '../../Constants'
import { OperatorsByCaQuery } from '../../graphql/queries/__generated__/OperatorsByCaQuery.graphql'
import { RadiusValues } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { OperatorsByCa } from '../../graphql/queries/OperatorsByCa'
import { OperatorsTableFragment$key } from './__generated__/OperatorsTableFragment.graphql'
import { OperatorsTable } from './OperatorsTable'
import { OperatorsTableFragment } from './OperatorsTableFragment'

interface CaOperatorsTableProps {
	oaCode: string
	radius: RadiusValues
}

const CaOperatorsTable: React.FC<CaOperatorsTableProps> = ({ oaCode, radius }) => {
	const miles = milesRadiusValueInInts[radius]

	const data = useLazyLoadQuery<OperatorsByCaQuery>(OperatorsByCa, { oaCode, radius: miles })

	const operatorData = data.operatorsByCa || []

	const operators = useFragment<OperatorsTableFragment$key>(OperatorsTableFragment, operatorData.operators)

	return <OperatorsTable operators={operators} code={oaCode} name="Catchment Area" />
}

export { CaOperatorsTable }
