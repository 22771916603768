import graphql from 'babel-plugin-relay/macro'

export const OperatorsByCode = graphql`
	query OperatorsByCodeQuery($code: String!) {
		operatorsByCode(code: $code) {
			operators {
				...OperatorsTableFragment
				...OperatorGroupProvideOperatorFragment
			}
		}
	}
`
