import graphql from 'babel-plugin-relay/macro'

export const ProjectByReference = graphql`
	query ProjectByReferenceQuery($id: ID!) {
		projectByReference(id: $id) {
			address
			area
			client
			date
			id
			ladCode
			latitude
			longitude
			mvu
			name
			oaCode
			postalCode
			properties {
				id
				type
				geometry {
					type
					coordinates
				}
				properties {
					area
					aonbIds
					brownfieldIds
					greenBeltIds
					floodRiskZoneIds
					listedBuildingIds
					specialProtectionAreaIds
					sitesOfSpecialScientificInterestIds
					treePreservationZoneIds
				}
			}
			reference
			regionCode
			settings
			status
			titleNumbers
			townCity
			userId
			userId
		}
	}
`
