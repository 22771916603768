import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { Specialism } from '../../../../Constants'
import { CareStatsByTypesQuery } from '../../../../graphql/queries/__generated__/CareStatsByTypesQuery.graphql'
import { LocalAuthorityFeaturesQuery } from '../../../../graphql/queries/__generated__/LocalAuthorityFeaturesQuery.graphql'
import { CareStatsByTypes } from '../../../../graphql/queries/CareStatsByTypes'
import { LocalAuthorityFeatures } from '../../../../graphql/queries/LocalAuthorityFeatures'
import { Loading } from '../../../../shared/Loading'
import { MapSelector } from '../../../../shared/map/MapSelector'
import { Metric } from '../../../../shared/map/utils'
import { themeColors } from '../../../../Theme'
import { formatter } from '../../../../utils/formatNumber'
import { getBracketIndex } from '../../../../utils/getBracketIndex'
import {
	ElderlyCareStatsFragment$data,
	ElderlyCareStatsFragment$key,
} from './__generated__/ElderlyCareStatsFragment.graphql'
import { ElderlyCareStatsFragment } from './ElderlyCareStatsFragment'

interface MapProps {
	ladCode: string
	showMarkers: boolean
	activeFilter: string
	height: string
}

const Map: React.FC<MapProps> = ({ ladCode, showMarkers, activeFilter, height }) => {
	const navigate = useNavigate()
	// Using useLazyLoadQuery to fetch data
	const { careStatsBySpecialism } = useLazyLoadQuery<CareStatsByTypesQuery>(CareStatsByTypes, {
		specialism: Specialism.elderly,
	})
	const { localAuthorityFeatures } = useLazyLoadQuery<LocalAuthorityFeaturesQuery>(LocalAuthorityFeatures, {
		version: 'bsc',
	})

	const { features } = localAuthorityFeatures

	const allFeatures: Feature<Polygon | MultiPolygon, LaFeatureProperties>[] =
		(features as Feature<Polygon | MultiPolygon, LaFeatureProperties>[]) || []

	const ecs = React.useMemo(() => {
		return careStatsBySpecialism || []
	}, [careStatsBySpecialism])

	const { stats } = useFragment<ElderlyCareStatsFragment$key>(ElderlyCareStatsFragment, ecs)

	const { metrics: metricData, metricFeatures } = bedSupplyRate(allFeatures, stats)
	let metrics: Metric[] = []
	if (!showMarkers) {
		metrics = []
	} else {
		metrics = metricData
	}

	const featureCollection = activeFilter ? turf.featureCollection(metricFeatures) : turf.featureCollection(allFeatures)
	const featureCollections = [featureCollection]

	// Update URL and state with startTransition
	const handleSelectChange = (selectedValue: string) => {
		navigate(`/lad/${selectedValue}`)
	}

	return (
		<MapSelector
			localAuthorityFeatures={featureCollections}
			selectedLadCode={ladCode}
			selectLad={handleSelectChange}
			metrics={metrics}
			height={height}
		/>
	)
}

type LaFeatureProperties = {
	id: string
	latitude: number
	longitude: number
	name: string
	color?: string
	value?: number
}

function bedSupplyRate(
	features: Feature<Polygon | MultiPolygon, LaFeatureProperties>[],
	ecs: ElderlyCareStatsFragment$data['stats'],
): { metricFeatures: Feature<Polygon | MultiPolygon, LaFeatureProperties>[]; metrics: Metric[] } {
	const ladBedSupplyMap: { [key: string]: number } = {}
	let highestBedSupplyRateCount = 0

	// Map the number of beds per LAD
	ecs.forEach((stat) => {
		const ladCode = stat.code
		const bedSupply = (stat.numberOfBeds / stat.populationOver65) * 100

		ladBedSupplyMap[ladCode] = bedSupply

		if (ladBedSupplyMap[ladCode] > highestBedSupplyRateCount) {
			highestBedSupplyRateCount = ladBedSupplyMap[ladCode]
		}
	})

	const colors = [...themeColors.severity].reverse()
	const colorFunc = getBracketIndex(0, highestBedSupplyRateCount, colors.length)

	const metrics: Metric[] = []

	// Map over features and create new features with updated properties
	const metricFeatures = features.map((feature) => {
		const { properties } = feature
		const { id, latitude, longitude } = properties
		const value = ladBedSupplyMap[id]
		const color = colors[colorFunc(value)]

		// Clone the feature and its properties, then add the color
		const metricFeature: Feature<Polygon | MultiPolygon, LaFeatureProperties> = {
			...feature, // Spread the original feature
			properties: {
				...properties, // Clone the properties object
				color, // Add the color property
				value, // Add the value property
			},
		}

		// Only add to metrics if value > 0
		if (value > 0) {
			metrics.push({
				value,
				lat: latitude,
				long: longitude,
				color,
				formatter: (v) => formatter('', '%')(v.toFixed(1)),
			})
		}

		return metricFeature
	})

	// Return both the updated features and the metrics array
	return {
		metricFeatures,
		metrics,
	}
}

const Suspended: React.FC<MapProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Map {...props} />
		</React.Suspense>
	)
}

export { Suspended as Map }
