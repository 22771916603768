/**
 * @generated SignedSource<<473c036a1bbf4419aaf638e3a396b6b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OutputAreaByLatLongQuery$variables = {
  latitude: number;
  longitude: number;
};
export type OutputAreaByLatLongQuery$data = {
  readonly outputAreaByLatLong: {
    readonly geometry: string;
    readonly ladCode: string;
    readonly oaCode: string;
  };
};
export type OutputAreaByLatLongQuery = {
  response: OutputAreaByLatLongQuery$data;
  variables: OutputAreaByLatLongQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "latitude"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "longitude"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "latitude",
        "variableName": "latitude"
      },
      {
        "kind": "Variable",
        "name": "longitude",
        "variableName": "longitude"
      }
    ],
    "concreteType": "OutputArea",
    "kind": "LinkedField",
    "name": "outputAreaByLatLong",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oaCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "geometry",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutputAreaByLatLongQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutputAreaByLatLongQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72234aa1bc3f61d51ee6c63d1231e170",
    "id": null,
    "metadata": {},
    "name": "OutputAreaByLatLongQuery",
    "operationKind": "query",
    "text": "query OutputAreaByLatLongQuery(\n  $latitude: Float!\n  $longitude: Float!\n) {\n  outputAreaByLatLong(latitude: $latitude, longitude: $longitude) {\n    ladCode\n    oaCode\n    geometry\n  }\n}\n"
  }
};
})();

(node as any).hash = "1476da814c9a00725228e77430b8ec16";

export default node;
