/**
 * @generated SignedSource<<ea5e639d70ebf6b80d312721c659ad1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesTableFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly operatorName: string;
  readonly overallRating: number | null | undefined;
  readonly postalAddressTownCity: string;
  readonly registrationDate: any | null | undefined;
  readonly serviceTypes: ReadonlyArray<string>;
  readonly specialisms: ReadonlyArray<string>;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "SitesTableFragment";
}>;
export type SitesTableFragment$key = ReadonlyArray<{
  readonly " $data"?: SitesTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SitesTableFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SitesTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalAddressTownCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "8e74222847fca2958228e0702d0b862c";

export default node;
