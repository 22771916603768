import React from 'react'

type PanelPadding = 0 | 4 | 8 | 16 | 24 | 32 | 40

interface PanelProps {
	children: React.ReactNode
	padding?: string | PanelPadding
	height?: string | number
}

const Panel: React.FC<PanelProps> = ({ children, padding = 40, height = '100%' }) => {
	const styles = {
		padding,
		borderRadius: '10px',
		boxShadow: '0px 0px 25px rgba(100, 100, 100, 0.1)',
		height,
	}

	return (
		<div style={styles} className="bg-white">
			{children}
		</div>
	)
}

export { Panel }
