import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { getGoogleMapsParams } from '../../../../../Config'
import { milesRadiusValueInInts, Specialism } from '../../../../../Constants'
import {
	CareStatsByCaQuery,
	RadiusValues,
} from '../../../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { SitesByCaQuery } from '../../../../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { CareStatsByCa } from '../../../../../graphql/queries/CareStatsByCa'
import { SitesByCa } from '../../../../../graphql/queries/SitesByCa'
import { useMvuData } from '../../../../../providers/MvuDataProvider'
import { Col, Container, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { Loading } from '../../../../../shared/Loading'
import { createStaticMap } from '../../../../../utils/staticMap'
import { CompetitionTableFragment$key } from '../../../shared/__generated__/CompetitionTableFragment.graphql'
import { CompetitionTableFragment } from '../../../shared/CompetitionTableFragment'
import { ScorePanel } from '../../../shared/ScorePanel'
import { BackgroundImagePanel } from '../../../shared/SubComponents'
import { Template } from '../../../shared/Template'
import { CompetitionPanel } from './CompetitionPanel'
import { InfoPanel } from './InfoPanel'

const Competition: React.FC = () => {
	const { project, geographies, radius, population: pop, careStats: cs, demographics: dem } = useMvuData()
	const radiusInt = milesRadiusValueInInts[radius]

	const data = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, { oaCode: project.oaCode, radius })

	const sitesData = React.useMemo(() => {
		return data.sitesByCa || []
	}, [data])

	const sites = useFragment<CompetitionTableFragment$key>(CompetitionTableFragment, sitesData.sites)

	const pins = sites.map((site) => ({
		lat: site.latitude!,
		long: site.longitude!,
	}))

	return (
		<Template
			pageTitle="3. Competition"
			pageSubtitle={`${radiusInt} Mile Radius`}
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row gutterSize={2}>
					<Col count={9}>
						<CompetitionPanel sites={sites} latitude={project.latitude} longitude={project.longitude} />
					</Col>
					<Col count={3}>
						<RatingScore oaCode={project.oaCode} radius={radius} regionName={geographies[1].name} />
						<VerticalSpace size="xs" />
						<InfoPanel
							height={200}
							project={project}
							geographies={geographies}
							radius={radius}
							popIn3={pop.over75In3}
							popIn6={pop.over75In6}
							elderlyCareStats={cs.elderly}
							unemployment={dem.unemployment}
							gdhi={dem.gdhi}
							housePrices={dem.housePrices}
							hourlyWages={dem.hourlyWage}
							fees={cs.fees}
							funding={cs.funding}
							migration={dem.migration}
							allowanceAwards={cs.aaAwardTypes}
							dementiaStats={cs.dementiaStats}
						/>
						<VerticalSpace size="xs" />
						<BackgroundImagePanel
							src={createStaticMap({
								lat: project.latitude,
								long: project.longitude,
								pins,
								apiKey: getGoogleMapsParams().googleMapsApiKey,
							})}
							height={288}
						/>
					</Col>
				</Row>
			</Container>
		</Template>
	)
}

interface RatingScoreProps {
	oaCode: string
	radius: RadiusValues
	regionName: string
}

const RatingScore: React.FC<RatingScoreProps> = ({ oaCode, radius, regionName }) => {
	const { careStatsByCa: ecsData } = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const caRating = ecsData.stats[0].rating * 100
	const regionRating = ecsData.stats[ecsData.stats.length - 2].rating * 100
	const labels = ['Catchment Area', regionName]

	return <ScorePanel title="Average CQC Rating" values={[caRating, regionRating]} labels={labels} />
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="100%" />}>
			<Competition />
		</React.Suspense>
	)
}

export { Suspended as Competition }
