import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { getUserId } from '../../Config'
import { ProjectsByUserIdQuery } from '../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { ProjectsByUserId } from '../../graphql/queries/ProjectsByUserId'
import { GraphQlProject } from '../../models/project'
import { Select } from '../Select'

interface SelectProps {
	onChange: (value: string) => void
	value?: string
}

const ProjectSelect: React.FC<SelectProps> = ({ onChange, value }) => {
	const data = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { id: getUserId() })
	const graphQlProjects = data.projectsByUserId?.projects || []

	// Use useFragment on each item in the array
	const options = graphQlProjects
		.filter((p) => !!p)
		.map((project: GraphQlProject) => {
			return {
				value: project.reference,
				label: project.name,
			}
		})

	options.unshift({ value: '', label: 'Select Project' })

	return <Select options={options} value={value} onChange={onChange} />
}

export { ProjectSelect }
