import graphql from 'babel-plugin-relay/macro'

export const OperatorFragment = graphql`
	fragment OperatorFragment on Operator {
		id
		alias
		companiesHouseNumber
		contacts
		deregistrationDate
		emails
		employees
		mainPhoneNumber
		name
		operatorGroupId
		operatorGroupName
		linkedinId
		postalAddressCounty
		postalAddressLine1
		postalAddressLine2
		postalAddressTownCity
		postalCode
		registrationDate
		type
		uprn
		website

		sites {
			isCareHome
			id
			emails
			employees
			ladCode
			mainPhoneNumber
			name
			latitude
			longitude
			linkedinId
			location
			numberOfBeds
			organisationType
			operatorId
			operatorName
			phoneNumber
			postalAddressCounty
			postalAddressLine1
			postalAddressLine2
			postalAddressTownCity
			postalCode
			regionCode
			registrationDate
			hasActiveRegistration
			serviceTypes
			type
			uprn
			website
		}
	}
`
