/**
 * @generated SignedSource<<d679d8a8da8a5bc1077908230fa71f00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PromptQuery$variables = {
  content: string;
  key: string;
};
export type PromptQuery$data = {
  readonly prompt: {
    readonly response: string;
    readonly version: string;
  };
};
export type PromptQuery = {
  response: PromptQuery$data;
  variables: PromptQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "content"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "OpenAiChatResponse",
    "kind": "LinkedField",
    "name": "prompt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "response",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromptQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromptQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea038736b4bb2fb29c56ae23aaff7a56",
    "id": null,
    "metadata": {},
    "name": "PromptQuery",
    "operationKind": "query",
    "text": "query PromptQuery(\n  $content: String!\n  $key: String!\n) {\n  prompt(content: $content, key: $key) {\n    response\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "c52163162bcbd79c69a5f5b224097117";

export default node;
