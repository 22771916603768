import graphql from 'babel-plugin-relay/macro'

export const OutputAreasByMileRadius = graphql`
	query OutputAreasByMileRadiusQuery($latitude: Float!, $longitude: Float!, $radius: RadiusValues!) {
		outputAreasByMileRadius(latitude: $latitude, longitude: $longitude, radius: $radius) {
			...MapCaptureAreaFragment
			...CareHomesCatchmentAreaFragment
		}
	}
`
