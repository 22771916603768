/**
 * @generated SignedSource<<15cb2227485d26b214a3ab630a25f2d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OperatorGroupsByIdsQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type OperatorGroupsByIdsQuery$data = {
  readonly operatorGroupsByIds: {
    readonly operatorGroups: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly operators: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly sites: ReadonlyArray<{
          readonly hasActiveRegistration: boolean;
          readonly id: string;
          readonly isCareHome: boolean;
          readonly ladCode: string;
          readonly latitude: number | null | undefined;
          readonly longitude: number | null | undefined;
          readonly name: string;
          readonly numberOfBeds: number | null | undefined;
          readonly overallRating: number | null | undefined;
          readonly regionCode: string;
          readonly registrationDate: any | null | undefined;
          readonly serviceTypes: ReadonlyArray<string>;
          readonly specialisms: ReadonlyArray<string>;
        }>;
      }>;
    }>;
  };
};
export type OperatorGroupsByIdsQuery = {
  response: OperatorGroupsByIdsQuery$data;
  variables: OperatorGroupsByIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "OperatorGroups",
    "kind": "LinkedField",
    "name": "operatorGroupsByIds",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OperatorGroup",
        "kind": "LinkedField",
        "name": "operatorGroups",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Operator",
            "kind": "LinkedField",
            "name": "operators",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "sites",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCareHome",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overallRating",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registrationDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasActiveRegistration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ladCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfBeds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceTypes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "specialisms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regionCode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorGroupsByIdsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OperatorGroupsByIdsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4ed5924b18a2b696f0d1b6937575dfc0",
    "id": null,
    "metadata": {},
    "name": "OperatorGroupsByIdsQuery",
    "operationKind": "query",
    "text": "query OperatorGroupsByIdsQuery(\n  $ids: [ID!]!\n) {\n  operatorGroupsByIds(ids: $ids) {\n    operatorGroups {\n      id\n      name\n      operators {\n        id\n        name\n        sites {\n          id\n          name\n          isCareHome\n          latitude\n          longitude\n          overallRating\n          registrationDate\n          hasActiveRegistration\n          ladCode\n          numberOfBeds\n          serviceTypes\n          specialisms\n          regionCode\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "78ab02ed65d8997636d39efea0f24b7e";

export default node;
