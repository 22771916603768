/**
 * @generated SignedSource<<0485c92d0d6eda1dcfb2a459800ac49c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OperatorGroupFilterOpts = {
  search?: string | null | undefined;
};
export type OperatorGroupsQuery$variables = {
  filter?: OperatorGroupFilterOpts | null | undefined;
  limit: number;
  offset: number;
};
export type OperatorGroupsQuery$data = {
  readonly operatorGroups: {
    readonly hasNextPage: boolean;
    readonly limit: number;
    readonly offset: number;
    readonly operatorGroups: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly operators: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly sites: ReadonlyArray<{
          readonly id: string;
          readonly name: string;
          readonly numberOfBeds: number | null | undefined;
        }>;
      }>;
    }>;
  };
};
export type OperatorGroupsQuery = {
  response: OperatorGroupsQuery$data;
  variables: OperatorGroupsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset"
      }
    ],
    "concreteType": "PaginatedOperatorGroups",
    "kind": "LinkedField",
    "name": "operatorGroups",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "limit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "offset",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OperatorGroup",
        "kind": "LinkedField",
        "name": "operatorGroups",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Operator",
            "kind": "LinkedField",
            "name": "operators",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "sites",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numberOfBeds",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorGroupsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OperatorGroupsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "f5d7b6c4dbe2f120617bbabc66a50f62",
    "id": null,
    "metadata": {},
    "name": "OperatorGroupsQuery",
    "operationKind": "query",
    "text": "query OperatorGroupsQuery(\n  $limit: Int!\n  $offset: Int!\n  $filter: OperatorGroupFilterOpts\n) {\n  operatorGroups(limit: $limit, offset: $offset, filter: $filter) {\n    limit\n    offset\n    hasNextPage\n    operatorGroups {\n      id\n      name\n      operators {\n        id\n        name\n        sites {\n          id\n          name\n          numberOfBeds\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ce884d94c36fce9165f7ad97dbef77a";

export default node;
