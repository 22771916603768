import graphql from 'babel-plugin-relay/macro'

export const OperatorsOperatorFragment = graphql`
	fragment OperatorsOperatorFragment on Operator {
		id
		name
		operatorGroupId
		operatorGroupName
		sites {
			id
			isCareHome
			ladCode
			name
			latitude
			longitude
			registrationDate
			serviceTypes
			website
			overallRating
			numberOfBeds
			regionCode
			specialisms
		}
	}
`
