import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { UnemploymentRatesByLadCodeQuery } from '../../graphql/queries/__generated__/UnemploymentRatesByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { UnemploymentRatesByLadCode } from '../../graphql/queries/UnemploymentRatesByLad'
import { Model } from '../../shared/charts/types'
import { UnemploymentRateChart } from './UnemploymentRateChart'

interface LadUnemploymentRateChartProps {
	ladCode: string
	aspectRatio?: number
}

const LadUnemploymentRateChart: React.FC<LadUnemploymentRateChartProps> = ({ ladCode, aspectRatio }) => {
	const { unemploymentRatesByLadCode: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<UnemploymentRatesByLadCodeQuery>(UnemploymentRatesByLadCode, { ladCode })

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || d.code,
			values: [...d.values],
		})),
	}

	return <UnemploymentRateChart model={model} aspectRatio={aspectRatio} />
}

export { LadUnemploymentRateChart }
