import graphql from 'babel-plugin-relay/macro'

export const UpdateProject = graphql`
	mutation UpdateProjectMutation($id: UUID!, $input: UpdateProjectInput!) {
		updateProject(id: $id, input: $input) {
			address
			area
			client
			date
			id
			ladCode
			latitude
			longitude
			mvu
			name
			oaCode
			postalCode
			properties {
				id
				type
				geometry {
					type
					coordinates
				}
				properties {
					area
					aonbIds
					brownfieldIds
					greenBeltIds
					floodRiskZoneIds
					listedBuildingIds
					specialProtectionAreaIds
					sitesOfSpecialScientificInterestIds
					treePreservationZoneIds
				}
			}
			reference
			settings
			status
			titleNumbers
			townCity
			userId
		}
	}
`
