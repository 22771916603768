import * as turf from '@turf/turf'
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from 'geojson'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { milesRadiusValueInMeters } from '../../../../Constants'
import { LocalAuthoritiesByLadsQuery } from '../../../../graphql/queries/__generated__/LocalAuthoritiesByLadsQuery.graphql'
import {
	OutputAreasByMileRadiusQuery,
	RadiusValues,
} from '../../../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { localAuthoritiesByLads } from '../../../../graphql/queries/LocalAuthoritiesByLads'
import { OutputAreasByMileRadius } from '../../../../graphql/queries/OutputAreasByMileRadius'
import { MapFeatures, Marker } from '../../../../shared/map/MapFeatures'
import { themeColors } from '../../../../Theme'
import { combineCollection, createCollection } from '../../../../utils/featureCollections'
import { MapCaptureAreaFragment$key } from './__generated__/MapCaptureAreaFragment.graphql'
import { MapCaptureAreaFragment } from './MapCaptureAreaFragment'

export type ViewTypes = 'circle' | 'oas' | 'ca'

interface MapProps {
	lat: number
	long: number
	radius: RadiusValues
	viewType: ViewTypes
	showingLads: boolean
	selectingLatLong: boolean
	height: string
	onMapClick: (lat: number, lng: number) => void
}

const Map: React.FC<MapProps> = ({
	lat,
	long,
	radius,
	viewType,
	showingLads,
	selectingLatLong,
	height,
	onMapClick,
}) => {
	const { outputAreasByMileRadius } = useLazyLoadQuery<OutputAreasByMileRadiusQuery>(OutputAreasByMileRadius, {
		latitude: lat,
		longitude: long,
		radius,
	})
	const ca = useFragment<MapCaptureAreaFragment$key>(MapCaptureAreaFragment, outputAreasByMileRadius)

	const { localAuthoritiesByLadCodes: las } = useLazyLoadQuery<LocalAuthoritiesByLadsQuery>(localAuthoritiesByLads, {
		ladCodes: ca.ladCodes,
	})

	const oaFeatures: Feature<Polygon | MultiPolygon>[] = ca.outputAreas.map((o) => {
		const geometry = JSON.parse(o.geometry!)
		return {
			type: 'Feature',
			geometry,
			properties: {
				// color: colors.primary,
				alpha: 0,
			},
		}
	})

	const collections = createCollection()

	// A collection of all the output areas
	const oaCollection: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties> = turf.featureCollection(oaFeatures)

	// If we are making a 'ca'
	if (viewType === 'ca') {
		const unionCollection = combineCollection(oaCollection)
		unionCollection.map((c) => collections.push(c))
	}
	// if we are making a 'ca' and showing the output areas
	else if (viewType === 'oas') {
		collections.push(oaCollection)
	}
	// if we are making a 'circle'
	else {
		const metres = milesRadiusValueInMeters[radius]

		const circle = turf.circle([long, lat], metres, {
			steps: 64,
			units: 'metres',
			properties: {
				// color: colors.primary,
				alpha: 0.5,
			},
		})

		const circleFeature: Feature<Polygon> = {
			type: 'Feature',
			geometry: circle.geometry,
			properties: circle.properties,
		}
		const circleFeatureCollection: FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties> =
			turf.featureCollection([circleFeature])
		collections.push(circleFeatureCollection)
	}
	if (!selectingLatLong) {
		// Add local authorities to the feature collection if showing
		if (showingLads) {
			const ladFeatures: Feature<Polygon | MultiPolygon>[] = las.map((la, i) => {
				const geometry = JSON.parse(la.geometry!)
				return {
					type: 'Feature',
					geometry,
					properties: {
						color: themeColors.colorPalette[i + 1],
						alpha: 0.2,
					},
				}
			})
			const ladCollection = turf.featureCollection(ladFeatures)
			collections.push(ladCollection)
		}
	}

	const markers: Marker[] = [
		{
			location: {
				coordinates: [long, lat],
				type: 'Point',
			},
			name: 'Output Area',
			id: '1',
		},
	]

	return <MapFeatures featureCollections={collections} locations={markers} onMapClick={onMapClick} height={height} />
}

export { Map }
