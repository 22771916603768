import { OperatorGroupsByIdsQuery$data } from '../graphql/queries/__generated__/OperatorGroupsByIdsQuery.graphql'
import { calculateRating } from '../utils/sites'

export type GraphQlOperatorGroup = OperatorGroupsByIdsQuery$data['operatorGroupsByIds']['operatorGroups'][0]
export type GraphQlOperator = GraphQlOperatorGroup['operators'][0]
export type GraphQlSite = GraphQlOperator['sites'][0]

export interface Site {
	readonly id: any
	readonly name: string
	readonly ladCode?: string
	readonly latitude?: number
	readonly longitude?: number
	readonly isCareHome?: boolean
	readonly currentRating?: string
	readonly ratingValue?: number
	readonly numberOfBeds?: number
	readonly regionCode?: string
	readonly registrationDate?: any
	readonly hasActiveRegistration: boolean
	readonly serviceTypes: string[]
	readonly specialisms: string[]
}

export interface Operator {
	readonly id: any
	readonly name: string
	readonly sites: ReadonlyArray<Site>
	readonly registeredCareHomes: ReadonlyArray<Site>
}

export interface OperatorGroup {
	readonly id: string
	readonly name: string
	readonly operators: ReadonlyArray<Operator>
}

function createSiteModel(gs: GraphQlSite): Site {
	return {
		id: gs.id,
		name: gs.name,
		ladCode: gs.ladCode ?? undefined,
		latitude: gs.latitude ?? undefined,
		longitude: gs.longitude ?? undefined,
		isCareHome: gs.isCareHome,
		ratingValue: calculateRating(gs.overallRating as any),
		numberOfBeds: gs.numberOfBeds ?? undefined,
		regionCode: gs.regionCode ?? undefined,
		registrationDate: gs.registrationDate ?? undefined,
		hasActiveRegistration: gs.hasActiveRegistration,
		serviceTypes: [...(gs.serviceTypes ?? [])],
		specialisms: [...(gs.specialisms ?? [])],
	}
}

function createOperatorModel(go: GraphQlOperator): Operator {
	const allSites = go.sites.map(createSiteModel)
	return {
		id: go.id,
		name: go.name,
		sites: allSites,
		registeredCareHomes: allSites.filter((site) => site.hasActiveRegistration && site.isCareHome),
	}
}

function createOperatorGroupModel(gog: GraphQlOperatorGroup): OperatorGroup {
	return {
		id: gog.id,
		name: gog.name,
		operators: gog.operators.map(createOperatorModel),
	}
}

export { createOperatorGroupModel, createOperatorModel, createSiteModel }
