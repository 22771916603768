import graphql from 'babel-plugin-relay/macro'

export const LocalAuthorityByLadCode = graphql`
	query LocalAuthorityByLadCodeQuery($ladCode: String!) {
		localAuthorityByLadCode(ladCode: $ladCode) {
			ladCode
			latitude
			longitude
			name
			geometry
			regionName
			regionCode
			countryName
			countryCode
		}
	}
`
