import graphql from 'babel-plugin-relay/macro'

export const OutputAreaByLatLong = graphql`
	query OutputAreaByLatLongQuery($latitude: Float!, $longitude: Float!) {
		outputAreaByLatLong(latitude: $latitude, longitude: $longitude) {
			ladCode
			oaCode
			geometry
		}
	}
`
