/**
 * @generated SignedSource<<464e0a13f867362f791405058831587c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesSiteFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly ladCode: string;
  readonly latitude: number | null | undefined;
  readonly longitude: number | null | undefined;
  readonly mainPhoneNumber: string | null | undefined;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly " $fragmentType": "SitesSiteFragment";
}>;
export type SitesSiteFragment$key = ReadonlyArray<{
  readonly " $data"?: SitesSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SitesSiteFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SitesSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ladCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "b66421ec9529c44b1d678193d0796899";

export default node;
