import { omaMatchingWeights } from '../Constants'

export interface OmaMatchingScores {
	cqcRatingScore: number
	growthScore: number
	geoScore: number
	careHomeSizeScore: number
	careTypeScore: number
}

export function calculateOmaMatchingScore(scores: OmaMatchingScores): number {
	const { cqcRatingScore, growthScore, geoScore, careHomeSizeScore, careTypeScore } = scores
	const w = omaMatchingWeights
	// Multiply each score by its weight and sum the results.
	return (
		cqcRatingScore * w.cqcRatingScore +
		growthScore * w.growthScore +
		geoScore * w.geoScore +
		careHomeSizeScore * w.careHomeSizeScore +
		careTypeScore * w.careTypeScore
	)
}
