import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	IndustryDistributionsByCaQuery,
	RadiusValues,
} from '../../graphql/queries/__generated__/IndustryDistributionsByCaQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { IndustryDistributionsByCa } from '../../graphql/queries/IndustryDistributionsByCa'
import { Model } from '../../shared/charts/types'
import { IndustryDistributionChart } from './IndustryDistributionChart'

interface CaIndustryDistributionChartProps {
	oaCode: string
	radius: RadiusValues
	aspectRatio?: number
}

const CaIndustryDistributionChart: React.FC<CaIndustryDistributionChartProps> = ({ oaCode, radius, aspectRatio }) => {
	const { industryDistributionsByCa: { labels = [], datasets = [] } = {} } =
		useLazyLoadQuery<IndustryDistributionsByCaQuery>(IndustryDistributionsByCa, {
			oaCode,
			radius,
		})

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const industryDatasets = datasets.map((d) => {
		return d.values.map((v, i) => ({
			value: v,
			industry: labels[i],
		}))
	})

	// Sort the CA dataset by Descending order
	const sortedCa = industryDatasets[0].sort((a, b) => b.value - a.value)

	// Get the labels from the sorted LAD dataset
	const sortedIndusties = sortedCa.map((item) => item.industry)

	// The use that to sort the remaing datasets
	const sortedDatasetValues = industryDatasets.map((dataset, index) => {
		if (index === 0) return dataset.map((item) => item.value)
		const sorted = dataset.sort((a, b) => {
			const industryAIndex = sortedCa.findIndex((item) => item.industry === a.industry)
			const industryBIndex = sortedCa.findIndex((item) => item.industry === b.industry)
			return industryAIndex - industryBIndex
		})
		return sorted.map((item) => item.value)
	})

	const model: Model = {
		labels: sortedIndusties,
		datasets: datasets.map((d, i) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: sortedDatasetValues[i],
		})),
	}

	return <IndustryDistributionChart model={model} aspectRatio={aspectRatio} />
}

export { CaIndustryDistributionChart }
