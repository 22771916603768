/**
 * @generated SignedSource<<bea576dd67f3ffda0340ca31e63b7679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RadiusValues = "eight" | "five" | "four" | "nine" | "one" | "seven" | "six" | "ten" | "three" | "two" | "%future added value";
export type OutputAreasByMileRadiusQuery$variables = {
  latitude: number;
  longitude: number;
  radius: RadiusValues;
};
export type OutputAreasByMileRadiusQuery$data = {
  readonly outputAreasByMileRadius: {
    readonly " $fragmentSpreads": FragmentRefs<"CareHomesCatchmentAreaFragment" | "MapCaptureAreaFragment">;
  };
};
export type OutputAreasByMileRadiusQuery = {
  response: OutputAreasByMileRadiusQuery$data;
  variables: OutputAreasByMileRadiusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "latitude"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "longitude"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "latitude",
    "variableName": "latitude"
  },
  {
    "kind": "Variable",
    "name": "longitude",
    "variableName": "longitude"
  },
  {
    "kind": "Variable",
    "name": "radius",
    "variableName": "radius"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutputAreasByMileRadiusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CatchmentArea",
        "kind": "LinkedField",
        "name": "outputAreasByMileRadius",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MapCaptureAreaFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CareHomesCatchmentAreaFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutputAreasByMileRadiusQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CatchmentArea",
        "kind": "LinkedField",
        "name": "outputAreasByMileRadius",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ladCodes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OutputArea",
            "kind": "LinkedField",
            "name": "outputAreas",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "geometry",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24c156d020e0e968c17e81edac57e2b5",
    "id": null,
    "metadata": {},
    "name": "OutputAreasByMileRadiusQuery",
    "operationKind": "query",
    "text": "query OutputAreasByMileRadiusQuery(\n  $latitude: Float!\n  $longitude: Float!\n  $radius: RadiusValues!\n) {\n  outputAreasByMileRadius(latitude: $latitude, longitude: $longitude, radius: $radius) {\n    ...MapCaptureAreaFragment\n    ...CareHomesCatchmentAreaFragment\n  }\n}\n\nfragment CareHomesCatchmentAreaFragment on CatchmentArea {\n  ladCodes\n  outputAreas {\n    geometry\n  }\n}\n\nfragment MapCaptureAreaFragment on CatchmentArea {\n  ladCodes\n  outputAreas {\n    geometry\n  }\n}\n"
  }
};
})();

(node as any).hash = "41b2f7ad6194689a9f4f1b461723f1d3";

export default node;
