export interface CareHomesRadius {
	careHomesIn5MileRadius: number
	careHomesIn10MileRadius: number
	careHomesIn20MileRadius: number
	careHomesIn30MileRadius: number
}

function calculateGeoScore({
	careHomesIn5MileRadius,
	careHomesIn10MileRadius,
	careHomesIn20MileRadius,
	careHomesIn30MileRadius,
}: CareHomesRadius): number {
	let geoScore: number

	if (careHomesIn5MileRadius > 0) {
		geoScore = 5
	} else if (careHomesIn10MileRadius > 0) {
		geoScore = 4
	} else if (careHomesIn20MileRadius > 0) {
		geoScore = 3
	} else if (careHomesIn30MileRadius > 0) {
		geoScore = 2
	} else {
		geoScore = 1
	}

	return geoScore
}

export { calculateGeoScore }
