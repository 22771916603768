import React from 'react'

import { LineChart } from '../../shared/charts/LineChart'
import { Model } from '../../shared/charts/types'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface UnemploymentRateChartProps {
	model: Model
	aspectRatio?: number
}

const UnemploymentRateChart: React.FC<UnemploymentRateChartProps> = ({ model, aspectRatio = 1 }) => (
	<>
		<Subtitle>Unemployment Rate</Subtitle>
		<Paragraph>ILO unemployment rate in the catchment area | ONS Annual Population Survey</Paragraph>
		<VerticalSpace size="md" />
		<LineChart model={model} suffix="%" dataLabelDisplayOption="showOnlyLast" aspectRatio={aspectRatio} />
	</>
)

export { UnemploymentRateChart }
