// theme.ts

function getBootstrapVar(key: string): string {
	return getComputedStyle(document.documentElement).getPropertyValue(`--bs-${key}`).trim()
}

const primary = getBootstrapVar('primary')
const secondary = getBootstrapVar('secondary')
const success = getBootstrapVar('success')
const info = getBootstrapVar('info')
const warning = getBootstrapVar('warning')
const danger = getBootstrapVar('danger')
const lightText = getBootstrapVar('light')
const darkText = getBootstrapVar('dark')
const purple = getBootstrapVar('purple')
const yellow = getBootstrapVar('yellow')
const orange = getBootstrapVar('orange')
const black = getBootstrapVar('black')

const colorPalette = [primary, info, yellow, purple, danger, orange, black]
const severity = [primary, info, yellow, orange, danger]

const themeColors = {
	primary,
	secondary,
	darkText,
	lightText,
	success,
	info,
	warning,
	danger,
	greyBG: '#f5f5f5',
	green: '#009669',
	canvas: 'rgb(248, 249, 250)',
	greyLight: '#d8d8d8',
	grey: '#8F8F8F',
	black,
	purple,
	yellow,
	colorPalette,
	colorPaletteAlpha: colorPalette.map((color) => (a: number) => convertRgbToRgba(color, a)),
	severity,
	severityAlpha: severity.map((color) => (a: number) => convertRgbToRgba(color, a)),
	primaryAlpha: (a: number) => convertRgbToRgba(primary, a),
	infoAlpha: (a: number) => convertRgbToRgba(info, a),
	warningAlpha: (a: number) => convertRgbToRgba(warning, a),
	yellowAlpha: (a: number) => convertRgbToRgba(yellow, a),
	grayAlpha: (a: number) => convertRgbToRgba('#8F8F8F', a),
}

export { themeColors }

function convertRgbToRgba(rgb: string, alpha: number): string {
	return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba')
}
