function calculateCareTypeScore(
	nursingHomesShare: number,
	residentialHomesShare: number,
	dementiaHomesShare: number,
	projectUseCaseNursing: boolean,
	projectUseCaseResidential: boolean,
	projectUseCaseDementia: boolean,
): number {
	const opN = nursingHomesShare > 0
	const opR = residentialHomesShare > 0
	const opD = dementiaHomesShare > 0

	if (projectUseCaseNursing && !opN && projectUseCaseResidential && !opR && projectUseCaseDementia && !opD) {
		return 0
	}

	if (projectUseCaseNursing && projectUseCaseResidential) {
		if (opN && opR) {
			return projectUseCaseDementia ? (opD ? 5 : 3) : 5
		} else if (opN || opR) {
			return projectUseCaseDementia ? (opD ? 4 : 3) : 4
		} else {
			return opD ? 1 : 0
		}
	}

	if (projectUseCaseResidential && !projectUseCaseNursing) {
		if (opR) {
			return 5
		} else if (opN) {
			return projectUseCaseDementia ? 3 : 2
		} else {
			return opD ? 1 : 0
		}
	}

	if (projectUseCaseNursing && !projectUseCaseResidential) {
		if (opN) {
			return 5
		} else if (opR) {
			return projectUseCaseDementia ? 2 : 1
		} else {
			return opD ? 1 : 0
		}
	}

	return 0
}

export { calculateCareTypeScore }
