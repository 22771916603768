/**
 * @generated SignedSource<<d2a25f3dbc9a150fe5d2c241da60311a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionTableFragment$data = ReadonlyArray<{
  readonly id: string;
  readonly latitude: number | null | undefined;
  readonly location: string | null | undefined;
  readonly longitude: number | null | undefined;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly operatorId: string;
  readonly operatorName: string;
  readonly overallRating: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly serviceTypes: ReadonlyArray<string>;
  readonly specialisms: ReadonlyArray<string>;
  readonly " $fragmentType": "CompetitionTableFragment";
}>;
export type CompetitionTableFragment$key = ReadonlyArray<{
  readonly " $data"?: CompetitionTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionTableFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CompetitionTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "c87e1b7a9ef219d8c7cb6938604296a7";

export default node;
