import { ColumnDef } from '@tanstack/react-table'
import React, { useEffect, useMemo, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useLazyLoadQuery } from 'react-relay'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
	OperatorGroupsQuery,
	OperatorGroupsQuery$data,
} from '../../graphql/queries/__generated__/OperatorGroupsQuery.graphql'
import { OperatorGroups as OperatorGroupsNode } from '../../graphql/queries/OperatorGroups'
import { Page } from '../../layout/page/Page'
import { DataTable } from '../../shared/DataTable'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { SegmentedButton } from '../../shared/SegmentedButton'
import { Heading, PageTitle, Paragraph } from '../../shared/Text'

const OperatorGroups: React.FC = () => {
	const navigate = useNavigate()

	const limit = 10
	const [offset, setOffset] = useState(0)

	// Read pagination parameters from URL
	const [searchParams] = useSearchParams()
	const pageParam = searchParams.get('page')

	useEffect(() => {
		const page = pageParam ? parseInt(pageParam, 10) : 1
		setOffset((page - 1) * limit)
	}, [pageParam])

	// Fetch data using Relay
	const data = useLazyLoadQuery<OperatorGroupsQuery>(OperatorGroupsNode, { limit, offset })

	// Ensure we have data
	const tableData: OperatorGroupsQuery$data['operatorGroups'] = useMemo(() => {
		return data.operatorGroups || []
	}, [data])

	// Define TableData Type
	type TableData = {
		id: string
		name: string
		operators: { name: string; sites: number; beds: number }[]
	}

	// Format Data
	const formattedData: TableData[] = useMemo(() => {
		return tableData.operatorGroups.map((group) => ({
			id: group.id,
			name: group.name,
			operators: group.operators.map((operator) => ({
				name: operator.name,
				sites: operator.sites.length,
				beds: operator.sites.reduce((totalBeds, site) => totalBeds + (site.numberOfBeds || 0), 0),
			})),
		}))
	}, [tableData])

	// ✅ Correct Column Definitions
	const columns = useMemo<ColumnDef<TableData>[]>(
		() => [
			{
				header: 'Group',
				accessorFn: (row) => row.name, // Accessor for the name (you can add this in the cell directly)
				cell: ({ row }) => {
					// Extract the data and create a combined display for the first column
					const operatorCount = row.original.operators.length
					const siteCount = row.original.operators.reduce((sum, operator) => sum + operator.sites, 0)
					const bedCount = row.original.operators.reduce((sum, operator) => sum + operator.beds, 0)

					return (
						<div>
							<Heading level={6}>{row.original.name}</Heading>
							<Paragraph>Operators: {operatorCount}</Paragraph>
							<Paragraph>Sites: {siteCount}</Paragraph>
							<Paragraph>Beds: {bedCount}</Paragraph>
						</div>
					)
				},
			},

			{
				header: 'Operators',
				accessorFn: (row) => row.operators, // Return the whole operators array for custom rendering
				cell: ({ getValue }) => {
					const operators = getValue() as { name: string }[] // Get operators array

					return (
						<div>
							{operators.map((operator, index) => (
								<Paragraph key={index}>{operator.name}</Paragraph> // Wrap each operator name in a Paragraph
							))}
						</div>
					)
				},
			},

			{
				header: 'Number of Sites',
				accessorFn: (row) => row.operators, // Return the operators array to calculate the number of sites
				cell: ({ getValue }) => {
					const operators = getValue() as { sites: number }[] // Get operators array
					return (
						<div>
							{operators.map((operator, index) => (
								<Paragraph key={index}>{operator.sites}</Paragraph> // Wrap each site count in a Paragraph
							))}
						</div>
					)
				},
			},

			{
				header: 'Number of Beds',
				accessorFn: (row) => row.operators, // Return the operators array to calculate the number of beds
				cell: ({ getValue }) => {
					const operators = getValue() as { beds: number }[] // Get operators array
					return (
						<div>
							{operators.map((operator, index) => (
								<Paragraph key={index}>{operator.beds}</Paragraph> // Wrap each bed count in a Paragraph
							))}
						</div>
					)
				},
			},
		],
		[],
	)

	const onRowClick = (id: string) => {
		navigate(`/group/${id}`)
	}

	const handleNextPage = () => {
		setOffset((prevOffset) => prevOffset + limit)
		navigate(`?page=${offset / limit + 2}`)
	}

	const handlePrevPage = () => {
		if (offset > 0) {
			setOffset((prevOffset) => Math.max(prevOffset - limit, 0))
			navigate(`?page=${offset / limit}`)
		}
	}

	const buttonOpts = [
		{
			children: <ChevronLeft />,
			value: 'prev',
			disabled: offset === 0,
		},
		{
			children: <ChevronRight />,
			value: 'next',
			disabled: tableData.operatorGroups.length < limit,
		},
	]

	return (
		<Page title="Operator Groups">
			<Container>
				<VerticalSpace size="md" />
				<Row>
					<div className="col-12">
						<PageTitle>Operator Groups</PageTitle>
					</div>
				</Row>
				<VerticalSpace size="lg" />
				<Row>
					<div className="col-12">
						<Panel>
							{formattedData.length > 0 ? (
								<>
									<SpaceBetween className="pagination">
										<Paragraph>
											Showing {offset + 1} to {offset + tableData.operatorGroups.length}
										</Paragraph>
										<SegmentedButton
											options={buttonOpts}
											onClick={(value) => {
												if (value === 'prev') handlePrevPage()
												else if (value === 'next') handleNextPage()
											}}
										/>
									</SpaceBetween>
									<DataTable columns={columns} data={formattedData} onRowClick={onRowClick} />
									<SpaceBetween className="pagination">
										<Paragraph>
											Showing {offset + 1} to {offset + tableData.operatorGroups.length}
										</Paragraph>
										<SegmentedButton
											options={buttonOpts}
											onClick={(value) => {
												if (value === 'prev') handlePrevPage()
												else if (value === 'next') handleNextPage()
											}}
										/>
									</SpaceBetween>
								</>
							) : (
								<p>No Operator Groups found.</p>
							)}
						</Panel>
					</div>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<OperatorGroups />
		</React.Suspense>
	)
}

export { Suspended as OperatorGroups }
