import graphql from 'babel-plugin-relay/macro'

export const OperatorBySiteId = graphql`
	query OperatorBySiteIdQuery($id: ID!) {
		operatorBySiteId(id: $id) {
			id
			alias
			companiesHouseNumber
			contacts
			deregistrationDate
			emails
			employees
			mainPhoneNumber
			name
			operatorGroupId
			operatorGroupName
			linkedinId
			postalAddressCounty
			postalAddressLine1
			postalAddressLine2
			postalAddressTownCity
			postalCode
			registrationDate
			type
			uprn
			website
		}
	}
`
