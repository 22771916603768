import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { OperatorsByCodeQuery } from '../../graphql/queries/__generated__/OperatorsByCodeQuery.graphql'
import { LocalAuthorityByLadCode } from '../../graphql/queries/LocalAuthorityByLad'
import { OperatorsByCode } from '../../graphql/queries/OperatorsByCode'
import { OperatorsTableFragment$key } from './__generated__/OperatorsTableFragment.graphql'
import { OperatorsTable } from './OperatorsTable'
import { OperatorsTableFragment } from './OperatorsTableFragment'

interface LaOperatorsTableProps {
	ladCode: string
}

const LaOperatorsTable: React.FC<LaOperatorsTableProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<OperatorsByCodeQuery>(OperatorsByCode, { code: ladCode })
	const { localAuthorityByLadCode: LaName } = useLazyLoadQuery<LaQueryResponseType>(LocalAuthorityByLadCode, {
		ladCode,
	})
	const { name } = LaName

	const operatorData = data.operatorsByCode || []

	const operators = useFragment<OperatorsTableFragment$key>(OperatorsTableFragment, operatorData.operators)

	return <OperatorsTable operators={operators} code={ladCode} name={name} />
}

export { LaOperatorsTable }
