import React from 'react'

import { useMvuData } from '../../../../providers/MvuDataProvider'
import { VerticallyAligned } from '../../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { HeavyText } from '../../../../shared/Text'
import { themeColors } from '../../../../Theme'
import { Label, Panel } from '../../shared/SubComponents'
import { Template } from '../../shared/Template'

const TitlePage: React.FC = () => {
	const { project, geographies } = useMvuData()

	return (
		<Template
			pageTitle={project.fullAddress}
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row gutterSize={2}>
					<Col count={12}>
						<Panel height={664} padding={100} transparent={true}>
							<VerticallyAligned>
								<div>
									<Label point={66} align="center">
										<HeavyText>{project.name}</HeavyText>
									</Label>
									<Row gutterSize={2}>
										<Col count={12}>
											<VerticalSpace size="xs" />
											<Label point={10} align="center">
												Most Viable Use Case Analysis
											</Label>
											<Label point={10} color={themeColors.primary} align="center">
												{project.mvuLabel}
											</Label>
										</Col>
									</Row>

									<VerticalSpace size="xs" />
								</div>
							</VerticallyAligned>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Template>
	)
}

export { TitlePage }
