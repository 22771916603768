/**
 * @generated SignedSource<<dc07035153aa20c5ed970728ed9760ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PropertyByIdQuery$variables = {
  id: string;
};
export type PropertyByIdQuery$data = {
  readonly propertyById: {
    readonly geometry: {
      readonly coordinates: any;
      readonly type: string;
    };
    readonly id: string;
    readonly properties: {
      readonly area: number;
    };
    readonly type: string;
  };
};
export type PropertyByIdQuery = {
  response: PropertyByIdQuery$data;
  variables: PropertyByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "PropertyFeature",
    "kind": "LinkedField",
    "name": "propertyById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "GeoJsonGeometry",
        "kind": "LinkedField",
        "name": "geometry",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coordinates",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyProperties",
        "kind": "LinkedField",
        "name": "properties",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "area",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyByIdQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyByIdQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cfdd192fa1d07b963e93179a45466045",
    "id": null,
    "metadata": {},
    "name": "PropertyByIdQuery",
    "operationKind": "query",
    "text": "query PropertyByIdQuery(\n  $id: ID!\n) {\n  propertyById(id: $id) {\n    id\n    type\n    geometry {\n      type\n      coordinates\n    }\n    properties {\n      area\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a1d22e40077b09d82995310c9459968";

export default node;
