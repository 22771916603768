import React from 'react'
import { Star } from 'react-feather'

interface StarsProps {
	rating: number // Rating from 0 to 5
	size?: number // Icon size (default 20)
	color?: string // Star color (default gold)
}

const Stars: React.FC<StarsProps> = ({ rating, size = 13, color = 'gold' }) => {
	const fullStars = Math.round(rating) // Round to nearest whole number
	const emptyStars = 5 - fullStars

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
			{[...Array(fullStars)].map((_, i) => (
				<Star key={`full-${i}`} size={size} color={color} fill={color} />
			))}
			{[...Array(emptyStars)].map((_, i) => (
				<Star key={`empty-${i}`} size={size} color={color} />
			))}
		</div>
	)
}

export { Stars }
