/**
 * @generated SignedSource<<d49bbf836057a42efafb4ef5bf05deba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GeographyVersions = "bgc" | "bsc" | "buc" | "%future added value";
export type LocalAuthorityFeaturesQuery$variables = {
  version: GeographyVersions;
};
export type LocalAuthorityFeaturesQuery$data = {
  readonly localAuthorityFeatures: {
    readonly features: ReadonlyArray<{
      readonly geometry: {
        readonly coordinates: any;
        readonly type: string;
      };
      readonly properties: {
        readonly id: string;
        readonly latitude: number | null | undefined;
        readonly longitude: number | null | undefined;
        readonly name: string | null | undefined;
      };
      readonly type: string;
    }>;
  };
};
export type LocalAuthorityFeaturesQuery = {
  response: LocalAuthorityFeaturesQuery$data;
  variables: LocalAuthorityFeaturesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "GeoJsonFeatures",
    "kind": "LinkedField",
    "name": "localAuthorityFeatures",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeoJsonFeature",
        "kind": "LinkedField",
        "name": "features",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GeoJsonGeometry",
            "kind": "LinkedField",
            "name": "geometry",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GeoJsonProperties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "latitude",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longitude",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthorityFeaturesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocalAuthorityFeaturesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a821f894bc8e479f1d1e8c5d6cc56be8",
    "id": null,
    "metadata": {},
    "name": "LocalAuthorityFeaturesQuery",
    "operationKind": "query",
    "text": "query LocalAuthorityFeaturesQuery(\n  $version: GeographyVersions!\n) {\n  localAuthorityFeatures(version: $version) {\n    features {\n      type\n      geometry {\n        type\n        coordinates\n      }\n      properties {\n        id\n        name\n        latitude\n        longitude\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e763acc72ab97df75c806f9e318ade57";

export default node;
