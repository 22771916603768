import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import React, { useRef } from 'react'
import { Download } from 'react-feather'
import { useParams } from 'react-router-dom'

import { radiusValues } from '../../Constants'
import { Page } from '../../layout/page/Page'
import { SecondaryButton } from '../../shared/Button'
import { Fixed } from '../../shared/layout/Fixed'
import { Mvu } from './mvu/Mvu'

type RouteParams = Record<string, string | undefined>

const Report: React.FC = () => {
	const { reference, type } = useParams<RouteParams>() // Get lad from the route

	if (!reference) {
		return <Page title="Report">No reference found</Page>
	}

	const contentRef = useRef<HTMLDivElement>(null) // Reference for the div container

	const generatePDF = async () => {
		const pdf = new jsPDF('l', 'mm', 'a4') // Portrait mode, mm units, A4 size
		const elements = document.querySelectorAll('.pdf-page') // Select all divs with class "page"

		for (let i = 0; i < elements.length; i++) {
			const element = elements[i] as HTMLElement
			const originalTransform = element.style.transform // Store original scale
			element.style.transform = 'none' // Remove scaling

			const canvas = await html2canvas(elements[i] as HTMLElement, {
				scale: 2,
				useCORS: true, // For external resources like fonts
				allowTaint: true,
			}) // High-quality image capture
			const imgData = canvas.toDataURL('image/jpeg', 1)

			const imgWidth = 297 // A4 width in mm
			const imgHeight = (canvas.height * imgWidth) / canvas.width // Maintain aspect ratio

			if (i !== 0) pdf.addPage() // Add new page except for the first
			pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST')

			// Restore original scaling
			element.style.transform = originalTransform
		}

		pdf.save(`${reference}.pdf`) // Download the PDF
	}

	return (
		<Page title={`Report - ${reference}`}>
			<Fixed bottom={10} right={10}>
				<SecondaryButton onClick={generatePDF}>
					<Download />
					&nbsp;&nbsp;Download PDF
				</SecondaryButton>
			</Fixed>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: '20px', // Space between PDFs
					paddingTop: '20px', // Space below navbar (adjust if needed)
					paddingBottom: '20px', // Bottom spacing
				}}
			>
				<div ref={contentRef} id="contentToPrint">
					{type == 'mvu' && <Mvu reference={reference} radius={radiusValues.five} />}
				</div>
			</div>
		</Page>
	)
}

export { Report }
