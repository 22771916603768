import { getAiUrl } from '../../Config'

async function streamToThread(
	token: string,
	userId: string,
	threadId: string,
	message: string,
	onChunkReceived: (chunk: Answer) => void,
) {
	const url = `${getAiUrl()}/thread/${userId}-${threadId}`

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ message }),
	})

	if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)

	const reader = response.body?.getReader()
	if (!reader) throw new Error('No readable stream found')

	const decoder = new TextDecoder()
	let result = ''

	// eslint-disable-next-line no-constant-condition
	while (true) {
		const { value, done } = await reader.read()
		if (done) break

		const chunk = decoder.decode(value, { stream: true })
		const lines = chunk.split('\n')

		for (const line of lines) {
			if (line.startsWith('data: ')) {
				const data = line.replace('data: ', '').trim()
				if (data === '[DONE]') return
				try {
					const parsed = JSON.parse(data)
					if (!parsed) continue
					const answer = processRawAnswer(parsed)
					onChunkReceived(answer)
					result += parsed.text
				} catch (err) {
					console.error('Error parsing stream data', err)
				}
			}
		}
	}

	return result
}

export type MarkerType = 'property' | 'site' | 'other'

export type ChartType =
	| 'HourlyWages'
	| 'HousePrices'
	| 'HousingYearsToBuy'
	| 'IndustryDistribution'
	| 'PopulationGrowthByAgeGroup'
	| 'SocialGrade'
	| 'TenureTypes'
	| 'UnemploymentRate'
	| 'none'

export type RadiusValues = 'one' | 'two' | 'three' | 'four' | 'five' | 'six' | 'seven' | 'eight' | 'nine' | 'ten'

export type AnswerChart = {
	type: ChartType
	ca: {
		lat: number
		long: number
		radius: RadiusValues
	}
	ladCode: string
}

export type AnswerMapMarker = {
	lat: number
	long: number
	title: string
	type: MarkerType
}

export type AnswerMap = {
	center: {
		lat: number
		long: number
	}
	zoom: number
	markers: AnswerMapMarker[]
}

export type AnswerContent = {
	text: string
	map?: AnswerMap
	propertyId?: string
	chart?: AnswerChart
}

export type Answer = {
	content: AnswerContent
	isUser: boolean
	id?: string
}

export type RawAnswer = {
	content: string | AnswerContent
	isUser: boolean
	id?: string
}

function processRawAnswer(message: RawAnswer): Answer {
	const { content, isUser, id } = message

	// Case 1: Content is a string (could be markdown or JSON stringified AnswerContent)
	if (typeof content === 'string') {
		try {
			const parsed = JSON.parse(content)
			return {
				content: parsed as AnswerContent,
				isUser,
				id,
			}
		} catch {
			// Not JSON, treat as raw markdown text
			return {
				content: { text: content },
				isUser,
				id,
			}
		}
	}

	// Case 2: Already structured
	if (typeof content === 'object' && content !== null) {
		return message as Answer
	}

	// Default fallback
	return {
		content: { text: String(content) },
		isUser,
		id,
	}
}

async function getThreadHistory(token: string, userId: string, threadId: string) {
	const url = `${getAiUrl()}/thread/${userId}-${threadId}`
	const empty: Answer[] = []

	try {
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})

		if (!response.ok) {
			return empty
		}

		const res = await response.json()
		return res.messages.map(processRawAnswer)
	} catch (error) {
		console.error('Error calling HTTP endpoint:', error)
		return empty
	}
}

export { getThreadHistory, processRawAnswer, streamToThread }
