import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

import { dementiaSpecialismKey, nursingHomesKey, residentialHomesKey } from '../../Constants'
import { Page } from '../../layout/page/Page'
import { defineCareGroupType } from '../../metrics/careGroupType'
import { calculateCareHomeSizeScore } from '../../metrics/careHomeSizeScore'
import { defineCareType } from '../../metrics/careType'
import { calculateCareTypeScore } from '../../metrics/careTypeScore'
import { calculateCqcRatingScore } from '../../metrics/cqcRatingScore'
import { calculateGeoScore } from '../../metrics/geoScore'
import { Operator, OperatorGroup } from '../../models/cqc'
import { Project } from '../../models/project'
import { useOperatorGroups } from '../../providers/OperatorGroupProvider'
import { CSVDownloadButton } from '../../shared/CSVDownloadButton'
import { DataTable, RowData } from '../../shared/DataTable'
import { FloatingWrapper } from '../../shared/FloatingWrapper'
import { Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Panel } from '../../shared/Panel'
import { Stars } from '../../shared/Stars'
import { PageTitle } from '../../shared/Text'
import { themeColors } from '../../Theme'
import { calculateCAGR } from '../../utils/CAGR'
import { calculateDistance, Point } from '../../utils/calculateDistance'
import { calculateMedian } from '../../utils/calculateMedian'
import { calculateOmaMatchingScore } from '../../utils/calculateOmaMatchingScore'
import { calculateOperatorGrowthScoreDynamic } from '../../utils/calculateOperatorGrowthScores'
import { formatNumber } from '../../utils/formatNumber'
import { isPseudoId } from '../../utils/strings'

type TableData = RowData & {
	aggregatedGrowthScore: number
	bedsPerHomeMedian: number
	careHomeSizeScore: number
	careType: string
	careTypeScore: number
	cqcRatingScore: number
	geoScore: number
	groupType: string
	id: string
	matchingScore: number
	operatorGroupName: string
	ratingOperatorMean: number
	registeredCareHomesCount: number
	totalBeds: number
}

interface Props {
	project: Project
	projectUseCaseNursing: boolean
	projectUseCaseResidential: boolean
	projectUseCaseDementia: boolean
}

const OmaShortlist: React.FC<Props> = ({ project }) => {
	const { operatorGroups } = useOperatorGroups()

	const columns = React.useMemo<ColumnDef<TableData, any>[]>(
		() => [
			{
				header: 'Name',
				accessorKey: 'operatorGroupName',
				cell: ({ getValue, row }) => {
					const { id, isPseudo } = isPseudoId(row.original.id)
					const path = isPseudo ? '/operator' : '/group'

					return (
						<a className="link-dark" href={`${path}/${id}`} style={{ fontSize: '1.1em' }}>
							<strong>{getValue()}</strong>
						</a>
					)
				},
			},
			{
				header: 'Provider Type',
				accessorKey: 'groupType',
			},
			{
				header: 'Care Home Profile',
				accessorKey: 'careType',
			},
			{
				header: 'Total Beds',
				accessorKey: 'totalBeds',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Total Sites',
				accessorKey: 'registeredCareHomesCount',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Beds per site',
				accessorKey: 'bedsPerHomeMedian',
				cell: ({ getValue }) => formatNumber(getValue()),
			},
			{
				header: 'Matching Score',
				accessorKey: 'matchingScore',
				cell: ({ getValue }) => (
					<div style={{ display: 'flex' }}>
						<Stars rating={getValue()} size={16} color={themeColors.primary} />
						<span style={{ marginRight: '16px', marginLeft: '8px', color: 'black' }}>
							{formatNumber(getValue(), 0, 1)}
						</span>
					</div>
				),
			},
			{
				header: 'CQC Score',
				accessorKey: 'cqcRatingScore',
				cell: ({ getValue }) => <Stars rating={getValue()} color={themeColors.grey} />,
			},
			{
				header: 'Geo Score',
				accessorKey: 'geoScore',
				cell: ({ getValue }) => <Stars rating={getValue()} color={themeColors.grey} />,
			},
			{
				header: 'Care Size Score',
				accessorKey: 'careHomeSizeScore',
				cell: ({ getValue }) => <Stars rating={getValue()} color={themeColors.grey} />,
			},
			{
				header: 'Care Type Score',
				accessorKey: 'careTypeScore',
				cell: ({ getValue }) => <Stars rating={getValue()} color={themeColors.grey} />,
			},
			{
				header: 'Growth Score',
				accessorKey: 'aggregatedGrowthScore',
				cell: ({ getValue }) => <Stars rating={getValue()} color={themeColors.grey} />,
			},
		],
		[],
	)

	const csvHeaders = columns.reduce(
		(acc, col) => {
			if ('accessorKey' in col && typeof col.accessorKey === 'string') {
				acc.push({
					label: typeof col.header === 'string' ? col.header : '',
					key: col.accessorKey,
				})
			}
			return acc
		},
		[] as { label: string; key: string }[],
	)

	const single = operatorGroups

	const rows: TableData[] = mergedOperatorGroups(single, project)

	return (
		<>
			<Page title="Operator Matching Analysis">
				<Container>
					<VerticalSpace size="md" />
					<Row>
						<div className="col-12">
							<PageTitle>Operator Matching Analysis</PageTitle>
						</div>
					</Row>
					<VerticalSpace size="lg" />
					<Row>
						<div className="col-12">
							<Panel>
								<DataTable
									columns={columns}
									data={rows}
									wrapCells={true}
									wrapHeaders={false}
									stickyColumn={true}
									stickyHeader={true}
								/>
							</Panel>
						</div>
					</Row>
				</Container>
				<FloatingWrapper corner="bottomRight">
					<CSVDownloadButton data={rows} headers={csvHeaders} />
				</FloatingWrapper>
			</Page>
		</>
	)
}

function mergedOperatorGroups(operatorGroups: OperatorGroup[], project: Project): TableData[] {
	const projectUseCaseResidential = project.mvu.types.includes('residential')
	const projectUseCaseNursing = project.mvu.types.includes('nursing')
	const projectUseCaseDementia = project.mvu.conditions.includes('dementia')
	const mergedData = operatorGroups.map((og) => {
		const totalBeds = og.operators.reduce(
			(acc, o) => acc + o.registeredCareHomes.reduce((siteAcc, s) => siteAcc + (s.numberOfBeds ?? 0), 0),
			0,
		)
		const registeredCareHomesCount = og.operators.reduce((acc, o) => acc + o.registeredCareHomes.length, 0)
		const ratings = og.operators.reduce<number[]>(
			(acc, o) =>
				acc.concat(o.registeredCareHomes.map((s) => s.ratingValue).filter((rating): rating is number => rating !== -1)),
			[],
		)
		const ratingOperatorMean = ratings.length > 0 ? ratings.reduce((acc, r) => acc + r, 0) / ratings.length : 0
		const cqcRatingScore = calculateCqcRatingScore(ratingOperatorMean)

		// Beds per home stats
		const bedNumbers = og.operators.flatMap((o) => o.registeredCareHomes.map((s) => s.numberOfBeds ?? 0))
		const bedsPerHomeMedian = calculateMedian(bedNumbers)

		// Care home type stats
		const nursingHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(nursingHomesKey)),
		).length

		const nursingHomesShare = nursingHomesCount / registeredCareHomesCount
		const residentialHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.serviceTypes?.includes(residentialHomesKey)),
		).length

		const residentialHomesShare = residentialHomesCount / registeredCareHomesCount

		const dementiaHomesCount = og.operators.flatMap((o) =>
			o.registeredCareHomes.filter((s) => s.specialisms?.includes(dementiaSpecialismKey)),
		).length

		const dementiaHomesShare = dementiaHomesCount / registeredCareHomesCount

		// GEO SCORING
		const regionsOfCareHomes = og.operators.flatMap((o) => o.registeredCareHomes.map((s) => s.regionCode))
		const regionsActive = Array.from(new Set(regionsOfCareHomes))
		const regionsActiveCount = regionsActive.length

		// GROWTH SCORING
		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()
		const sevenYearsAgo = new Date(currentYear - 7, currentMonth)
		const fiveYearsAgo = new Date(currentYear - 5, currentMonth)
		const threeYearsAgo = new Date(currentYear - 3, currentMonth)
		const currentDate = new Date()

		let bedsCount5YearsAgo = 0
		let bedsCount3YearsAgo = 0
		let bedsCountCurrent = 0
		let careHomeCount7YearsAgo = 0
		let careHomeCount5YearsAgo = 0
		let careHomeCount3YearsAgo = 0
		let careHomeCountCurrent = 0

		og.operators.forEach((o) => {
			o.registeredCareHomes.forEach((s) => {
				const registrationDate = s.registrationDate ? new Date(s.registrationDate) : null
				const numberOfBeds = s.numberOfBeds ?? 0

				if (registrationDate) {
					if (registrationDate <= sevenYearsAgo) careHomeCount7YearsAgo++
					if (registrationDate <= fiveYearsAgo) {
						bedsCount5YearsAgo += numberOfBeds
						careHomeCount5YearsAgo++
					}
					if (registrationDate <= threeYearsAgo) {
						bedsCount3YearsAgo += numberOfBeds
						careHomeCount3YearsAgo++
					}
					if (registrationDate <= currentDate) {
						bedsCountCurrent += numberOfBeds
						careHomeCountCurrent++
					}
				}
			})
		})
		// CAGR
		const bedsCAGR3Years = calculateCAGR(bedsCount3YearsAgo, bedsCountCurrent, 3)
		const bedsCAGR5Years = calculateCAGR(bedsCount5YearsAgo, bedsCountCurrent, 5)

		// New Beds and Care Home
		const careHomesNew3YearsCount = careHomeCountCurrent - careHomeCount3YearsAgo
		const careHomesNew5YearsCount = careHomeCountCurrent - careHomeCount5YearsAgo
		const careHomesNew7YearsCount = careHomeCountCurrent - careHomeCount7YearsAgo

		// GROWTH SCORING
		const aggregatedGrowthScore = calculateOperatorGrowthScoreDynamic({
			registeredCareHomesCount: registeredCareHomesCount,
			careHomesNew3Years: careHomesNew3YearsCount,
			careHomesNew5Years: careHomesNew5YearsCount,
			careHomesNew7Years: careHomesNew7YearsCount,
			bedsCAGR3Years: bedsCAGR3Years,
			bedsCAGR5Years: bedsCAGR5Years,
		})

		// GEO SCORING
		const projectCoordinates: Point = { latitude: project.latitude, longitude: project.longitude }

		const distanceReducer = (miles: number) => (acc: number, operator: Operator) => {
			return (
				acc +
				operator.registeredCareHomes.filter((s) => {
					const siteCoordinates: Point = { latitude: s.latitude!, longitude: s.longitude! }
					const dist = calculateDistance(siteCoordinates, projectCoordinates)
					return dist < miles
				}).length
			)
		}
		const careHomesIn5MileRadius = og.operators.reduce(distanceReducer(5), 0)
		const careHomesIn10MileRadius = og.operators.reduce(distanceReducer(10), 0)
		const careHomesIn20MileRadius = og.operators.reduce(distanceReducer(20), 0)
		const careHomesIn30MileRadius = og.operators.reduce(distanceReducer(30), 0)

		const geoScore = calculateGeoScore({
			careHomesIn5MileRadius,
			careHomesIn10MileRadius,
			careHomesIn20MileRadius,
			careHomesIn30MileRadius,
		})

		// PROFILE SCORING
		const projectsBeds = project.settings.numberOfBeds ?? 60
		const operatorMedianBedsDeviationCount = bedsPerHomeMedian - projectsBeds
		const careHomeSizeScore = calculateCareHomeSizeScore(operatorMedianBedsDeviationCount, projectsBeds)

		const careTypeScore = calculateCareTypeScore(
			nursingHomesShare,
			residentialHomesShare,
			dementiaHomesShare,
			projectUseCaseNursing,
			projectUseCaseResidential,
			projectUseCaseDementia,
		)

		const matchingScore = calculateOmaMatchingScore({
			cqcRatingScore: cqcRatingScore,
			growthScore: aggregatedGrowthScore,
			geoScore: geoScore,
			careHomeSizeScore: careHomeSizeScore,
			careTypeScore: careTypeScore,
		})

		const groupType = defineCareGroupType(registeredCareHomesCount, regionsActiveCount)
		const careType = defineCareType(bedsPerHomeMedian, dementiaHomesShare, nursingHomesShare, residentialHomesShare)

		const td: TableData = {
			aggregatedGrowthScore,
			bedsPerHomeMedian,
			careHomeSizeScore,
			careType,
			careTypeScore,
			cqcRatingScore,
			geoScore,
			groupType,
			id: og.id,
			matchingScore,
			operatorGroupName: og.name,
			ratingOperatorMean,
			registeredCareHomesCount,
			totalBeds,
		}

		return td
	})

	return mergedData.sort((a: TableData, b: TableData) => b.matchingScore - a.matchingScore)
}

export { OmaShortlist }
