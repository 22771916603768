/**
 * @generated SignedSource<<60440f9600ce219c38a47d180a236a4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SiteByIdQuery$variables = {
  id: string;
};
export type SiteByIdQuery$data = {
  readonly siteById: {
    readonly emails: string | null | undefined;
    readonly employees: string | null | undefined;
    readonly hasActiveRegistration: boolean;
    readonly id: string;
    readonly isCareHome: boolean;
    readonly ladCode: string;
    readonly latitude: number | null | undefined;
    readonly linkedinId: string | null | undefined;
    readonly location: string | null | undefined;
    readonly locationTypes: string | null | undefined;
    readonly longitude: number | null | undefined;
    readonly mainPhoneNumber: string | null | undefined;
    readonly name: string;
    readonly numberOfBeds: number | null | undefined;
    readonly organisationType: string | null | undefined;
    readonly overallRating: number | null | undefined;
    readonly phoneNumber: string | null | undefined;
    readonly postalAddressLine1: string | null | undefined;
    readonly postalAddressTownCity: string;
    readonly postalCode: string;
    readonly regionCode: string;
    readonly registrationDate: any | null | undefined;
    readonly serviceTypes: ReadonlyArray<string>;
    readonly specialisms: ReadonlyArray<string>;
    readonly type: string;
    readonly uprn: string | null | undefined;
    readonly website: string | null | undefined;
  };
};
export type SiteByIdQuery = {
  response: SiteByIdQuery$data;
  variables: SiteByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Site",
    "kind": "LinkedField",
    "name": "siteById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCareHome",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "overallRating",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organisationType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emails",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employees",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "linkedinId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mainPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numberOfBeds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressLine1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressTownCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasActiveRegistration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "specialisms",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uprn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "website",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "40c7f218746df88966be3fcd00868673",
    "id": null,
    "metadata": {},
    "name": "SiteByIdQuery",
    "operationKind": "query",
    "text": "query SiteByIdQuery(\n  $id: ID!\n) {\n  siteById(id: $id) {\n    id\n    isCareHome\n    overallRating\n    organisationType\n    emails\n    employees\n    linkedinId\n    location\n    ladCode\n    locationTypes\n    mainPhoneNumber\n    name\n    numberOfBeds\n    latitude\n    longitude\n    phoneNumber\n    postalAddressLine1\n    postalAddressTownCity\n    postalCode\n    regionCode\n    registrationDate\n    hasActiveRegistration\n    serviceTypes\n    specialisms\n    type\n    uprn\n    website\n  }\n}\n"
  }
};
})();

(node as any).hash = "142d737c44de94831f0823e0212cf6da";

export default node;
