import React from 'react'

import { RadiusValues } from '../../../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlGeography } from '../../../../../models/geography'
import { GraphQlPopulation } from '../../../../../models/population'
import { Project } from '../../../../../models/project'
import { calculateMvuSummary } from '../../../../../utils/calculateMvuSummary'
import { SummaryPanel } from '../../../shared/SummaryPanel'

interface InfoPanelProps {
	project: Project
	geographies: GraphQlGeography
	radius: RadiusValues
	height: number
	popIn3: GraphQlPopulation
	popIn6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	gdhi: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	allowanceAwards: GraphQlAaAwardTypes
	migration: GraphQlDemographics
	dementiaStats: GraphqlDementiaStats
}

const InfoPanel: React.FC<InfoPanelProps> = ({
	project,
	height,
	popIn3,
	popIn6,
	elderlyCareStats,
	unemployment,
	housePrices,
	hourlyWages,
	gdhi,
	fees,
	funding,
	allowanceAwards,
	migration,
	dementiaStats,
}) => {
	const numberOfBeds = project.settings.numberOfBeds || 0

	const promptData = calculateMvuSummary({
		numberOfBeds,
		popIn3,
		popIn6,
		elderlyCareStats,
		unemployment,
		housePrices,
		hourlyWages,
		gdhi,
		fees,
		funding,
		allowanceAwards,
		migration,
		dementiaStats,
	})

	return (
		<SummaryPanel
			title="Summary"
			prompt={`
				Write one short statement (max 300 characters total, line breaks allowed) on our care home’s competitive edge, referencing:
				
				• Local average rating: ${promptData.caRating}
				• CQC scale: 0=Inadequate, 0.33=Needs Improvement, 0.66=Good, 1=Outstanding
				• We assume our new care home will achive a rating of: ${promptData.projectRating} (above local avg)
				• Higher rating ⇒ premium fees
				`}
			cacheKey={`${project.id}_comp_4`}
			height={height}
		/>
	)
}

export { InfoPanel }
