/**
 * @generated SignedSource<<2e9e104fe5c1919c970dff5b5c7fb27c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectStatus = "approved" | "pipeline" | "planning" | "%future added value";
export type UpdateProjectInput = {
  client?: string | null | undefined;
  date?: any | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  name?: string | null | undefined;
  propertyIds?: ReadonlyArray<string> | null | undefined;
  reference?: string | null | undefined;
  settings?: any | null | undefined;
  status?: ProjectStatus | null | undefined;
  titleNumbers?: ReadonlyArray<string> | null | undefined;
};
export type UpdateProjectMutation$variables = {
  id: any;
  input: UpdateProjectInput;
};
export type UpdateProjectMutation$data = {
  readonly updateProject: {
    readonly address: string;
    readonly area: number;
    readonly client: string;
    readonly date: any;
    readonly id: any;
    readonly ladCode: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly mvu: any;
    readonly name: string;
    readonly oaCode: string;
    readonly postalCode: string;
    readonly properties: ReadonlyArray<{
      readonly geometry: {
        readonly coordinates: any;
        readonly type: string;
      };
      readonly id: string;
      readonly properties: {
        readonly aonbIds: ReadonlyArray<string>;
        readonly area: number;
        readonly brownfieldIds: ReadonlyArray<string>;
        readonly floodRiskZoneIds: ReadonlyArray<string>;
        readonly greenBeltIds: ReadonlyArray<string>;
        readonly listedBuildingIds: ReadonlyArray<string>;
        readonly sitesOfSpecialScientificInterestIds: ReadonlyArray<string>;
        readonly specialProtectionAreaIds: ReadonlyArray<string>;
        readonly treePreservationZoneIds: ReadonlyArray<string>;
      };
      readonly type: string;
    }>;
    readonly reference: string;
    readonly settings: any | null | undefined;
    readonly status: ProjectStatus;
    readonly titleNumbers: ReadonlyArray<string>;
    readonly townCity: string;
    readonly userId: string;
  };
};
export type UpdateProjectMutation = {
  response: UpdateProjectMutation$data;
  variables: UpdateProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "updateProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "client",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mvu",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oaCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PropertyFeature",
        "kind": "LinkedField",
        "name": "properties",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GeoJsonGeometry",
            "kind": "LinkedField",
            "name": "geometry",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyProperties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aonbIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "brownfieldIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "greenBeltIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "floodRiskZoneIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "listedBuildingIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialProtectionAreaIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sitesOfSpecialScientificInterestIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "treePreservationZoneIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reference",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "settings",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "townCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "064ec4aa559908cb86573808c43462c8",
    "id": null,
    "metadata": {},
    "name": "UpdateProjectMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProjectMutation(\n  $id: UUID!\n  $input: UpdateProjectInput!\n) {\n  updateProject(id: $id, input: $input) {\n    address\n    area\n    client\n    date\n    id\n    ladCode\n    latitude\n    longitude\n    mvu\n    name\n    oaCode\n    postalCode\n    properties {\n      id\n      type\n      geometry {\n        type\n        coordinates\n      }\n      properties {\n        area\n        aonbIds\n        brownfieldIds\n        greenBeltIds\n        floodRiskZoneIds\n        listedBuildingIds\n        specialProtectionAreaIds\n        sitesOfSpecialScientificInterestIds\n        treePreservationZoneIds\n      }\n    }\n    reference\n    settings\n    status\n    titleNumbers\n    townCity\n    userId\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b8e086c134344cb886cf07cd6edc925";

export default node;
