import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Paragraph } from '../../shared/Text'
import { Charts } from './Charts'
import { Answer } from './request'

interface MessageProps {
	message: Answer
}

const Message: React.FC<MessageProps> = ({ message }) => {
	const { isUser, content } = message
	const { chart, text } = content

	const bubbleStyle: React.CSSProperties = {
		maxWidth: isUser ? '66%' : '100%',
		padding: '8px 12px',
		borderRadius: isUser ? 10 : 0,
		backgroundColor: isUser ? '#fff' : 'transparent',
		alignSelf: isUser ? 'flex-end' : 'flex-start',
		border: isUser ? '1px solid #eee' : 'none',
		marginBottom: 10,
		fontSize: 14,
	}

	return (
		<div style={bubbleStyle}>
			{!isUser && <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>}
			{isUser && <Paragraph>{text}</Paragraph>}
			{chart && <Charts {...chart} />}
		</div>
	)
}

export { Message }
