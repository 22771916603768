import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { filterEldery } from '../../utils/sites'
import { DataTable, RowData } from '../DataTable'
import { SitesTableFragment$data } from './__generated__/SitesTableFragment.graphql'

interface SitesTableProps {
	sites: SitesTableFragment$data
}

const SitesTable: React.FC<SitesTableProps> = ({ sites }) => {
	const navigate = useNavigate()

	const columns = React.useMemo<ColumnDef<RowData, any>[]>(
		() => [
			{
				header: 'Name',
				accessorKey: 'name',
				enableSorting: true,
			},
			{
				header: 'City',
				accessorKey: 'postalAddressTownCity',
			},
			{
				header: 'Operator',
				accessorKey: 'operatorName',
			},
			{
				header: 'Registration',
				accessorKey: 'registrationDate',
			},
			{
				header: 'Type',
				accessorKey: 'serviceTypes',
			},
			{
				header: 'Beds',
				accessorKey: 'numberOfBeds',
			},
			{
				header: 'Ratings',
				accessorKey: 'currentRatings',
			},
			{
				header: 'Specialisms',
				accessorKey: 'specialisms',
			},
		],
		[], // Empty dependency array means this is only run on mount
	)

	const formattedData = filterEldery(sites).map((site) => {
		const { overallRating, specialisms, serviceTypes, registrationDate } = site

		return {
			...site,
			currentRatings: overallRating,
			specialisms: specialisms.join(', '),
			serviceTypes: serviceTypes.join(', '),
			registrationDate: registrationDate ? new Date(registrationDate).getFullYear().toString() : null,
		}
	})

	const handleRowClick = (id: string) => {
		navigate(`/site/${id}`)
	}

	return <DataTable columns={columns} data={formattedData} onRowClick={handleRowClick} />
}

export { SitesTable }
