import { elderlySpecialismKey, ServiceType } from '../Constants'

function filterEldery<T extends { specialisms: readonly string[] }>(sites: ReadonlyArray<T>): ReadonlyArray<T> {
	return sites.filter((site) => site.specialisms.includes(elderlySpecialismKey))
}

function filterServiceType<T extends { serviceTypes: readonly string[] }>(
	sites: ReadonlyArray<T>,
	key: ServiceType,
): ReadonlyArray<T> {
	return sites.filter((site) => {
		return site.serviceTypes.includes(key)
	})
}

function calculateRating(siteRating?: number | null): number {
	if (!siteRating || siteRating < 0 || siteRating > 3) {
		return -1
	}
	if (siteRating === 0) {
		return 0
	}
	return siteRating / 3
}

function getServiceTypeLabel(key: string): string {
	return key
		.replaceAll('_homes', '')
		.replaceAll('_', ' ')
		.replace(/^\w/, (c) => c.toUpperCase())
}

function getRatingsLabel(rating?: number | null): string {
	if (rating == undefined || rating == null) {
		return '-'
	}
	switch (rating) {
		case 0:
			return 'Inadequate'
		case 1:
			return 'Requires improvement'
		case 2:
			return 'Good'
		case 3:
			return 'Outstanding'
		default:
			return '-'
	}
}

export { calculateRating, filterEldery, filterServiceType, getRatingsLabel, getServiceTypeLabel }
