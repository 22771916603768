import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	RadiusValues,
	UnemploymentRatesByCaQuery,
} from '../../graphql/queries/__generated__/UnemploymentRatesByCaQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { UnemploymentRatesByCa } from '../../graphql/queries/UnemploymentRatesByCa'
import { Model } from '../../shared/charts/types'
import { UnemploymentRateChart } from './UnemploymentRateChart'

interface CaUnemploymentRateChartProps {
	oaCode: string
	radius: RadiusValues
	aspectRatio?: number
}

const CaUnemploymentRateChart: React.FC<CaUnemploymentRateChartProps> = ({ oaCode, radius, aspectRatio }) => {
	const { unemploymentRatesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<UnemploymentRatesByCaQuery>(
		UnemploymentRatesByCa,
		{ oaCode, radius },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: [...d.values],
		})),
	}

	return <UnemploymentRateChart model={model} aspectRatio={aspectRatio} />
}

export { CaUnemploymentRateChart }
