import React from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { RemoveOperatorFromGroupButton } from '../../actions/RemoveOperatorFromGroup'
import { OperatorGroupByIdQuery } from '../../graphql/queries/__generated__/OperatorGroupByIdQuery.graphql'
import { OperatorGroupById } from '../../graphql/queries/OperatorGroupById'
import { Page } from '../../layout/page/Page'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle } from '../../shared/Text'

type RouteParams = { id: string }

const OperatorGroup: React.FC = () => {
	const { id = '' } = useParams<RouteParams>() // Get the id from the URL

	const { operatorGroupById: og } = useLazyLoadQuery<OperatorGroupByIdQuery>(OperatorGroupById, {
		id,
	})

	return (
		<Page title={`Operator Group - ${og.name}`}>
			<Container>
				<VerticalSpace size="md" />
				<Row>
					<Col count={3}></Col>
					<Col count={6}>
						<PageTitle>{og.name}</PageTitle>
					</Col>
				</Row>
				<VerticalSpace size="md" />
				<Row>
					<Col count={3}></Col>
					<Col count={6}>
						<Panel padding={16}>
							<div className="list-group">
								{og.operators.map((o) => (
									<div key={o.id} className="list-group-item">
										<SpaceBetween>
											<a href={`/operator/${o.id}`}>{o.name}</a>
											<RemoveOperatorFromGroupButton operatorId={o.id} groupId={id} />
										</SpaceBetween>
									</div>
								))}
							</div>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<OperatorGroup />
		</React.Suspense>
	)
}

export { Suspended as OperatorGroup }
