import React from 'react'

import { getGoogleMapsParams } from '../../../../../Config'
import { useMvuData } from '../../../../../providers/MvuDataProvider'
import { Col, Container, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { formatAcres } from '../../../../../utils/formatNumber'
import { createStaticMap } from '../../../../../utils/staticMap'
import { BackgroundImagePanel, TextPanel } from '../../../shared/SubComponents'
import { Template } from '../../../shared/Template'

const SiteInfo: React.FC = () => {
	const { project, geographies } = useMvuData()
	const address = `${project.address}, ${project.townCity}, ${project.postalCode}`
	const mapZoom = project.settings.mapZoom
	const localAuthority = geographies[0].name
	const region = geographies[1].name

	return (
		<Template
			pageTitle="5. Site Information"
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row gutterSize={2}>
					<Col count={4}>
						<TextPanel title="Site Size" subtitle={formatAcres(project.area)} />
					</Col>
					<Col count={4}>
						<TextPanel title="Address" subtitle={address} />
					</Col>
					<Col count={4}>
						<TextPanel title={localAuthority} subtitle={region} />
					</Col>
				</Row>
				<VerticalSpace size="xs" />
				<Row gutterSize={2}>
					<Col count={8}>
						<BackgroundImagePanel
							src={createStaticMap({
								lat: project.latitude,
								long: project.longitude,
								zoom: mapZoom,
								mapType: 'satellite',
								centerPin: false,
								geoJsonArray: project.properties.map((feature) => feature.geometry),
								apiKey: getGoogleMapsParams().googleMapsApiKey,
							})}
							height={606}
						/>
					</Col>
					<Col count={4}>
						<BackgroundImagePanel
							src={createStaticMap({
								lat: project.latitude,
								long: project.longitude,
								zoom: 7,
								apiKey: getGoogleMapsParams().googleMapsApiKey,
							})}
							height={606}
						/>
					</Col>
				</Row>
			</Container>
		</Template>
	)
}

export { SiteInfo }
