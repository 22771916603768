import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { GrossDisposableHouseholdIncomesByLadCodeQuery } from '../../graphql/queries/__generated__/GrossDisposableHouseholdIncomesByLadCodeQuery.graphql'
import { HousePricesByLadCodeQuery } from '../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { GrossDisposableHouseholdIncomesByLadCode } from '../../graphql/queries/GrossDisposableHouseholdIncomesByLadCode'
import { HousePricesByLadCode } from '../../graphql/queries/HousePricesByLad'
import { calculateYearsToBuy } from '../../utils/calculateYearsToBuy'
import { HousingYearsToBuyChart } from './HousingYearsToBuyChart'

interface LadHousingYearsToBuyChartProps {
	ladCode: string
	aspectRatio?: number
}

const LadHousingYearsToBuyChart: React.FC<LadHousingYearsToBuyChartProps> = ({ ladCode, aspectRatio }) => {
	const { housePricesByLadCode: hp } = useLazyLoadQuery<HousePricesByLadCodeQuery>(HousePricesByLadCode, { ladCode })

	const { grossDisposableHouseholdIncomesByLadCode: gdhi } =
		useLazyLoadQuery<GrossDisposableHouseholdIncomesByLadCodeQuery>(GrossDisposableHouseholdIncomesByLadCode, {
			ladCode,
		})

	const codes = hp.datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	const model = calculateYearsToBuy(hp, gdhi, geos)

	return <HousingYearsToBuyChart model={model} aspectRatio={aspectRatio} />
}

export { LadHousingYearsToBuyChart }
