import React from 'react'
import { CSVLink } from 'react-csv'

import { SecondaryButton } from './Button'

interface CSVDownloadButtonProps {
	data: any[]
	headers: { label: string; key: string }[]
	filename?: string
}

const CSVDownloadButton: React.FC<CSVDownloadButtonProps> = ({ data, headers, filename }) => {
	const computedFilename = filename || window.location.pathname.slice(1).replace(/\//g, '-') + '.csv'

	return (
		<CSVLink
			data={data}
			headers={headers}
			filename={computedFilename}
			target="_blank"
			style={{ textDecoration: 'none' }}
		>
			<SecondaryButton>Download CSV</SecondaryButton>
		</CSVLink>
	)
}

export { CSVDownloadButton }
