import graphql from 'babel-plugin-relay/macro'

export const OperatorGroupProvideOperatorFragment = graphql`
	fragment OperatorGroupProvideOperatorFragment on Operator @relay(plural: true) {
		id
		name
		operatorGroupId
		website
		sites {
			id
			name
			isCareHome
			latitude
			longitude
			overallRating
			registrationDate
			hasActiveRegistration
			ladCode
			numberOfBeds
			serviceTypes
			specialisms
			regionCode
		}
	}
`
